// Core
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useReducer
} from "react";

// Utils
import http, { buildQuery, ResponseWithData } from "utils/fetchWrapper";

type Action = { type: string; payload: unknown };
type ApiContextProps = { children: ReactNode };
type Dispatch = (action: Action) => void;
type State = {
  apiUrl: string;
  error: { message: string; timestamp: string };
  token: string;
  user: Record<string, string> | null;
};
type ApiCallWithQuery = <T = unknown>(query?: {
  [key: string]: string;
}) => Promise<ResponseWithData<T>>;

const ApiStateContext = createContext<State | undefined>(undefined);
const ApiDispatchContext = createContext<Dispatch | undefined>(undefined);

interface UseApi {
  bankItem: (key: string) => Promise<unknown>;
  createProject: (body?: unknown) => Promise<Response>;
  createSignedUploadUrl: <T = unknown>(
    id: string,
    payload: {
      name: string;
    }
  ) => Promise<ResponseWithData<T>>;
  createUser: (
    role: "admin" | "approver" | "subjectExpert"
  ) => Promise<unknown>;
  deleteProject: (id: string) => Promise<unknown>;
  downloadQtiArchives: (
    id: string
  ) => Promise<ResponseWithData<{ key: string; url: string }[]>>;
  error: { message: string; timestamp: string };
  getItem: <T = unknown>(
    key: string,
    query?: {
      [key: string]: string;
    }
  ) => Promise<ResponseWithData<T>>;
  getItems: <T = unknown>(
    projectKey: string,
    query?: { [key: string]: string }
  ) => Promise<ResponseWithData<T>>;
  getJobs: ApiCallWithQuery;
  getJobsByProject: <T = unknown>(
    key: string,
    query?: { [key: string]: string }
  ) => Promise<ResponseWithData<T>>;
  getProject: <T = unknown>(key: string) => Promise<ResponseWithData<T>>;
  getProjects: ApiCallWithQuery;
  getRights: <T = unknown>() => Promise<ResponseWithData<T>>;
  getSharedStimulus: <T = unknown>(key: string) => Promise<ResponseWithData<T>>;
  getSkills: <T = unknown>(
    projectKey: string,
    query?: {
      [key: string]: string;
    }
  ) => Promise<ResponseWithData<T>>;
  getSubjectInfo: <T = unknown>() => Promise<ResponseWithData<T>>;
  getTask: <T = unknown>(key: string) => Promise<ResponseWithData<T>>;
  getTasks: <T = unknown>() => Promise<ResponseWithData<T>>;
  getTasksByProject: <T = unknown>(key: string) => Promise<ResponseWithData<T>>;
  getTopics: <T = unknown>(
    projectKey: string,
    query?: {
      [key: string]: string;
    }
  ) => Promise<ResponseWithData<T>>;
  getUserOnboardStatus: <T = unknown>(
    role: "admin" | "approver" | "subjectExpert"
  ) => Promise<ResponseWithData<T>>;
  getUsers: <T = unknown>(query?: {
    [key: string]: string | string[];
  }) => Promise<ResponseWithData<T>>;
  getWholeQuestion: <T = unknown>(
    jobKey: string,
    questionNumber: string,
    query?: {
      [key: string]: string;
    }
  ) => Promise<ResponseWithData<T>>;
  login: (body: { name: string; role: string }) => Promise<ResponseWithData>;
  logout: () => Promise<unknown>;
  patchItem: (key: string, body?: unknown) => Promise<unknown>;
  rejectItem: (key: string, body: unknown) => Promise<unknown>;
  setApiUrl: (apiUrl: string) => void;
  setToken: (token: string) => void;
  setUser: (user: Record<string, string> | null) => void;
  startProject: (id: string) => Promise<unknown>;
  token: string;
  uploadFile: (url: string, file: FormData | File) => Promise<unknown>;
  uploadItems: () => Promise<unknown>;
  updateItem: (key: string, body?: unknown) => Promise<unknown>;
  updateItemStatus: (
    status:
      | "content-checked"
      | "discarded"
      | "initial"
      | "initialized"
      | "mark-scheme-checked"
      | "metadata-added"
      | "shared-stimuli-checked",
    key: string,
    body?: unknown
  ) => Promise<unknown>;
  updateProject: (id: string, body?: unknown) => Promise<unknown>;
  updateSharedStimulus: (key: string, body?: unknown) => Promise<unknown>;
  updateUser: (
    role: "admin" | "approver" | "subjectExpert",
    onboarded: boolean
  ) => Promise<unknown>;
  user: Record<string, string> | null;
}

const apiReducer = (state: State, action: Action): State => {
  if (action.type === "API_URL_CONFIGURED") {
    return {
      ...state,
      apiUrl: action.payload as string
    };
  }

  if (action.type === "ERROR_SET") {
    return {
      ...state,
      error: {
        message: action.payload as string,
        timestamp: Date.now().toString()
      }
    };
  }

  if (action.type === "TOKEN_CONFIGURED") {
    return {
      ...state,
      token: action.payload as string
    };
  }

  if (action.type === "USER_FETCHED") {
    return {
      ...state,
      user: action.payload as Record<string, string>
    };
  }

  return state;
};

const ApiContext = ({ children }: ApiContextProps): ReactElement => {
  const [state, dispatch] = useReducer(apiReducer, {
    apiUrl: "",
    error: { message: "", timestamp: "" },
    token: "",
    user: null
  });

  return (
    <ApiStateContext.Provider value={state}>
      <ApiDispatchContext.Provider value={dispatch}>
        {children}
      </ApiDispatchContext.Provider>
    </ApiStateContext.Provider>
  );
};

const useApi = (): UseApi => {
  const dispatch = useApiDispatch();
  const { apiUrl, error, token, user } = useApiState();

  const bankItem = useCallback<UseApi["bankItem"]>(
    function bankItem(key) {
      const request = http.put(`${apiUrl}/items/${key}/prepare`, null, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error banking the item.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createProject = useCallback<UseApi["createProject"]>(
    function createProject(body) {
      const request = http.post(`${apiUrl}/projects`, body, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error creating the project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createSignedUploadUrl = useCallback<UseApi["createSignedUploadUrl"]>(
    function createSignedUploadUrl<T = unknown>(id: string, payload: unknown) {
      const request = http.post(`${apiUrl}/projects/${id}/uploads`, payload, {
        headers: { Authorization: token }
      }) as Promise<ResponseWithData<T>>;

      request.catch((e) => {
        dispatch({
          payload: "There was an error creating the signed upload URL.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createUser = useCallback<UseApi["createUser"]>(
    function createUser<T = unknown>(
      role: "admin" | "approver" | "subjectExpert"
    ) {
      const request = http.post(
        `${apiUrl}/user`,
        { projectRole: role },
        {
          headers: { Authorization: token }
        }
      ) as Promise<ResponseWithData<T>>;

      request.catch((e) => {
        dispatch({
          payload: "There was an error creating the user.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const deleteProject = useCallback<UseApi["deleteProject"]>(
    function deleteProject(id: string) {
      const request = http.delete(
        `${apiUrl}/projects/${id}`,
        {},
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error deleting the project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getItem = useCallback<UseApi["getItem"]>(
    function getItem<T = unknown>(
      key: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/items/${key}${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the item.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getItems = useCallback<UseApi["getItems"]>(
    function getItems<T = unknown>(
      projectKey: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/projects/${projectKey}/items${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the items.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getJobs = useCallback<UseApi["getJobs"]>(
    function getJobs<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/jobs${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the jobs.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getJobsByProject = useCallback<UseApi["getJobsByProject"]>(
    function getJobsByProject<T = unknown>(
      key: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/projects/${key}/jobs${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the jobs by project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getProject = useCallback<UseApi["getProject"]>(
    function getProject<T = unknown>(id: string) {
      const request = http.get<T>(`${apiUrl}/projects/${id}`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getProjects = useCallback<UseApi["getProjects"]>(
    function getProjects<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/projects${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the projects.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getRights = useCallback<UseApi["getRights"]>(
    function getRights<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/session`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the session.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getSharedStimulus = useCallback<UseApi["getSharedStimulus"]>(
    function getSharedStimulus<T = unknown>(key: string) {
      const request = http.get<T>(`${apiUrl}/shared-stimuli/${key}`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the shared stimulus.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getSkills = useCallback<UseApi["getSkills"]>(
    function getSkills<T = unknown>(
      projectKey: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/keywords/${projectKey}${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the skills.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getSubjectInfo = useCallback<UseApi["getSubjectInfo"]>(
    function getSubjectInfo<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/subjectInfo`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the subjectInfo.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTask = useCallback<UseApi["getTask"]>(
    function getTask<T = unknown>(key: string) {
      const request = http.get<T>(`${apiUrl}/tasks/${key}`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the task.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTasks = useCallback<UseApi["getTasks"]>(
    function getTasks<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/tasks`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the tasks.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTasksByProject = useCallback<UseApi["getTasksByProject"]>(
    function getTasksByProject<T = unknown>(key: string) {
      const request = http.get<T>(`${apiUrl}/projects/${key}/tasks`, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the tasks by project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTopics = useCallback<UseApi["getTopics"]>(
    function getTopics<T = unknown>(
      projectKey: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/keywords/${projectKey}${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the topics.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getUserOnboardStatus = useCallback<UseApi["getUserOnboardStatus"]>(
    function getUserOnboardStatus<T = unknown>(
      role: "admin" | "approver" | "subjectExpert"
    ) {
      return http.get<T>(`${apiUrl}/userOnboardStatus/${role}`, {
        headers: { Authorization: token }
      });
    },
    [apiUrl, token]
  );

  const getUsers = useCallback<UseApi["getUsers"]>(
    function getUsers<T = unknown>(query?: {
      [key: string]: string | string[];
    }) {
      if (!query || !query.role) {
        query = { ...query, role: "EXAMS_OFFICER" };
      }

      const request = http.get<T>(
        `${apiUrl}/users${buildQuery(query, ["role"])}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the users.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getWholeQuestion = useCallback<UseApi["getWholeQuestion"]>(
    function getWholeQuestion<T = unknown>(
      jobKey: string,
      questionNumber: string,
      query?: {
        [key: string]: string;
      }
    ) {
      const request = http.get<T>(
        `${apiUrl}/questions/${jobKey}_${questionNumber}${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch((e) => {
        dispatch({
          payload: "There was an error getting the whole question.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const login = useCallback<UseApi["login"]>(
    function login(body) {
      return http.post(`${apiUrl}/session`, body);
    },
    [apiUrl]
  );

  const logout = useCallback<UseApi["logout"]>(
    function logout() {
      return http.delete(`${apiUrl}/session`);
    },
    [apiUrl]
  );

  const patchItem = useCallback<UseApi["patchItem"]>(
    function patchItem(key, body) {
      return http
        .patch(`${apiUrl}/items/${key}`, body, {
          headers: { Authorization: token }
        })
        .catch((e) => {
          dispatch({
            payload: "There was an error patching the item.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const rejectItem = useCallback<UseApi["rejectItem"]>(
    function rejectItem(key, body) {
      const request = http.put(`${apiUrl}/items/${key}/rejected`, body, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error rejecting the item.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const setApiUrl = useCallback<UseApi["setApiUrl"]>(
    function setApiUrl(url) {
      dispatch({ payload: url, type: "API_URL_CONFIGURED" });
    },
    [dispatch]
  );

  const setToken = useCallback<UseApi["setToken"]>(
    function setToken(value) {
      dispatch({ type: "TOKEN_CONFIGURED", payload: value });
    },
    [dispatch]
  );

  const setUser = useCallback<UseApi["setUser"]>(
    function setUser(value) {
      dispatch({ payload: value, type: "USER_FETCHED" });
    },
    [dispatch]
  );

  const startProject = useCallback<UseApi["startProject"]>(
    function startProject(id) {
      const request = http.put(`${apiUrl}/projects/${id}/started`, null, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error starting the project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const updateProject = useCallback<UseApi["updateProject"]>(
    (id, body) => {
      const request = http.put(`${apiUrl}/projects/${id}`, body, {
        headers: { Authorization: token }
      });

      request.catch((e) => {
        dispatch({
          payload: "There was an error updating the project.",
          type: "ERROR_SET"
        });

        throw e;
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const updateItem = useCallback<UseApi["updateItem"]>(
    function updateItem(key, body) {
      return http
        .put(`${apiUrl}/items/${key}`, body, {
          headers: { Authorization: token }
        })
        .catch((e) => {
          dispatch({
            payload: "There was an error updating the item.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const updateItemStatus = useCallback<UseApi["updateItemStatus"]>(
    function updateItemStatus(status, key, body) {
      return http
        .put(`${apiUrl}/items/${key}/${status}`, body, {
          headers: { Authorization: token }
        })
        .catch((e) => {
          dispatch({
            payload: "There was an error updating the item status.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const updateSharedStimulus = useCallback<UseApi["updateSharedStimulus"]>(
    function updateSharedStimulus(key, body) {
      return http
        .put(`${apiUrl}/shared-stimuli/${key}`, body, {
          headers: { Authorization: token }
        })
        .catch((e) => {
          dispatch({
            payload: "There was an error updating the shared stimulus.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const updateUser = useCallback<UseApi["updateUser"]>(
    function updateUser(
      role: "admin" | "approver" | "subjectExpert",
      onboarded
    ) {
      return http
        .put(
          `${apiUrl}/user/${role}`,
          { onboarded },
          {
            headers: { Authorization: token }
          }
        )
        .catch((e) => {
          dispatch({
            payload: "There was an error updating the user.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const uploadFile = useCallback<UseApi["uploadFile"]>(
    function uploadFile(url, file) {
      return http.put(url, null, { file }).catch((e) => {
        dispatch({
          payload: "There was an error uploading the file.",
          type: "ERROR_SET"
        });

        throw e;
      });
    },
    [dispatch]
  );

  const uploadItems = useCallback<UseApi["uploadItems"]>(
    function uploadItems() {
      return http
        .post(
          `${apiUrl}/items`,
          { key: "0610_s20_11.zip" },
          { headers: { Authorization: token } }
        )
        .catch((e) => {
          dispatch({
            payload: "There was an error uploading items.",
            type: "ERROR_SET"
          });

          throw e;
        });
    },
    [apiUrl, dispatch, token]
  );

  const downloadQtiArchives = useCallback<UseApi["downloadQtiArchives"]>(
    function downloadQtiArchives(id: string) {
      return http.get(`${apiUrl}/projects/${id}/archives`, {
        headers: { Authorization: token }
      });
    },
    [apiUrl, token]
  );

  return {
    bankItem,
    createProject,
    createSignedUploadUrl,
    createUser,
    deleteProject,
    error,
    getJobs,
    getJobsByProject,
    getProject,
    getProjects,
    getItem,
    getItems,
    getRights,
    getSkills,
    getSharedStimulus,
    getSubjectInfo,
    getTask,
    getTasks,
    getTasksByProject,
    getTopics,
    getUserOnboardStatus,
    getUsers,
    getWholeQuestion,
    login,
    logout,
    patchItem,
    rejectItem,
    setApiUrl,
    setToken,
    setUser,
    startProject,
    token,
    updateProject,
    updateItem,
    updateItemStatus,
    updateUser,
    updateSharedStimulus,
    uploadFile,
    uploadItems,
    user,
    downloadQtiArchives
  };
};

const useApiDispatch = (): Dispatch => {
  const context = useContext(ApiDispatchContext);

  if (context === undefined) {
    throw new Error("useApiDispatch must be used within an ApiContext");
  }

  return context;
};

const useApiState = (): State => {
  const context = useContext(ApiStateContext);

  if (context === undefined) {
    throw new Error("useApiState must be used within an ApiContext");
  }

  return context;
};

export { ApiContext, useApi };
