// Core
import React, { FC, ReactElement } from "react";

// Assets
import accreditation from "assets/images/home/accreditation.svg";
import caCupLogos from "assets/images/home/ca-cup-logos.svg";
import check from "assets/images/home/check.svg";
import heroPdf from "assets/images/home/hero-pdf.svg";
import heroArrows from "assets/images/home/hero-arrows.svg";
import heroRender from "assets/images/home/hero-render.svg";
import icp from "assets/images/home/icp.svg";
import processEditing from "assets/images/home/process-editing.svg";
import processPdf from "assets/images/home/process-pdf.svg";
import processTopics from "assets/images/home/process-topics.svg";
import value from "assets/images/home/value.svg";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Chip,
  darken,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  withStyles
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { useAuth } from "oidc-react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";

interface LocationState {
  redirectSlug?: string;
}

const HeroButton = withStyles(() => ({
  root: {
    backgroundColor: colours.white,
    "&:hover": {
      backgroundColor: darken(colours.white, 0.05)
    },
    color: colours.corporateBlue
  }
}))(Button);

const HeroChip = withStyles(() => ({
  root: {
    borderColor: colours.white,
    color: colours.white
  }
}))(Chip);

const ValueChip = withStyles(() => ({
  root: {
    borderColor: colours.monochromeDark
  }
}))(Chip);

const Home: FC<RouteComponentProps<undefined, StaticContext, LocationState>> =
  (): ReactElement => {
    const auth = useAuth();
    const isSmScreen = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );
    const sharedClasses = sharedStyles();

    const useStyles = makeStyles({
      footer: {
        backgroundColor: colours.white,
        "& a": {
          color: colours.monochromeDark,
          textDecoration: "none"
        },
        "& li": {
          marginBottom: "8px"
        },
        "& section": {
          "&:not(:first-child)": {
            paddingTop: isSmScreen ? "25px" : 0,
            paddingLeft: isSmScreen ? 0 : "25px"
          },
          "&:not(:last-child)": {
            borderBottom: isSmScreen
              ? `1px solid ${colours.monochromeLight}`
              : "0 none",
            borderRight: isSmScreen
              ? "0 none"
              : `1px solid ${colours.monochromeLight}`,
            paddingBottom: isSmScreen ? "25px" : 0,
            paddingRight: isSmScreen ? 0 : "25px"
          }
        }
      },
      hero: {
        background:
          "linear-gradient(60.63deg, #6ca866 calc(3.21% + 500px), #37829d 47.41%, #1b6fa2 76.04%, #0060a2 95.57%)",
        marginTop: "-48px",
        paddingTop: "80px",
        paddingBottom: "80px",
        textAlign: "center",
        "& h1, & p": {
          color: colours.white
        }
      },
      primaryBanner: {
        backgroundColor: "#f4f4f4",
        paddingTop: "80px",
        paddingBottom: "80px",
        textAlign: "center"
      },
      process: {
        backgroundColor: colours.white,
        borderBottom: `1px solid ${colours.monochromeLight}`,
        paddingTop: "80px",
        paddingBottom: "80px"
      },
      secondaryBanner: {
        background:
          "linear-gradient(60.63deg, #6ca866 calc(3.21% + 500px), #37829d 47.41%, #1b6fa2 76.04%, #0060a2 95.57%)",
        paddingTop: "80px",
        paddingBottom: "80px",
        textAlign: "center",
        "& h2, & p": {
          color: colours.white
        }
      },
      value: {
        backgroundColor: colours.white,
        borderBottom: `1px solid ${colours.monochromeLight}`,
        paddingTop: "80px",
        paddingBottom: "80px",
        textAlign: "center"
      }
    });
    const classes = useStyles();

    const handleClickLogIn = () => {
      auth?.signIn({
        data: { targetUrl: window.location.pathname + window.location.search }
      });
    };

    return (
      <div data-testid="home-page">
        <Box className={`${classes.hero} ${sharedClasses.bleed}`}>
          <Box clone marginBottom={4}>
            <HeroChip label="Content Harvesting" variant="outlined" />
          </Box>
          <Box marginBottom={6}>
            <Box marginBottom={2}>
              <Typography variant="h1" data-testid="page-heading">
                Unlocking the value of our content
              </Typography>
            </Box>
            <Box marginBottom={1}>
              <Typography component="p" variant="h5">
                Cambridge Assessment holds highly valuable content in its
                archives.
              </Typography>
            </Box>
            <Typography component="p" variant="h5">
              Now it&rsquo;s time to prepare that content for the future.
            </Typography>
          </Box>
          <Box clone marginBottom={4}>
            <HeroButton
              disableElevation
              onClick={handleClickLogIn}
              data-testid="hero-log-in-button"
            >
              Log in now
            </HeroButton>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box clone marginBottom={isSmScreen ? 4 : 0}>
                <img alt="Question paper PDF" src={heroPdf} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Box clone marginBottom={isSmScreen ? 4 : 0}>
                <img alt="Arrows" src={heroArrows} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <img alt="QTI rendering in HTML" src={heroRender} />
            </Grid>
          </Grid>
        </Box>
        <Box className={`${classes.primaryBanner} ${sharedClasses.bleed}`}>
          <Box marginBottom={2}>
            <Typography variant="h2">
              Recycling the assessment content
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box marginBottom={1}>
                <Typography component="p" variant="h5">
                  This is a step-by-step digital recycling journey to build an
                  item bank.
                </Typography>
              </Box>
              <Typography component="p" variant="h5">
                Paper-based exam material will be ready to use again for
                on-screen reproduction.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={`${classes.process} ${sharedClasses.bleed}`}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={isSmScreen ? "column" : "row"}
            paddingX={isSmScreen ? 0 : 12}
          >
            <Box
              marginBottom={isSmScreen ? 4 : 0}
              marginRight={isSmScreen ? 0 : 12}
            >
              <Box marginBottom={2}>
                <Typography variant="h2">From paper to digital</Typography>
              </Box>
              <Typography component="p" variant="h5">
                Starting with thousands of pages scanning effort, past exam
                items will be available to use in digital.
              </Typography>
            </Box>
            <img alt="Question paper PDF" src={processPdf} />
          </Box>
        </Box>
        <Box className={`${classes.process} ${sharedClasses.bleed}`}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={isSmScreen ? "column" : "row"}
            paddingX={isSmScreen ? 0 : 12}
          >
            <Box
              clone
              marginRight={isSmScreen ? 0 : 12}
              order={isSmScreen ? 2 : 1}
            >
              <img
                alt="QTI rendering in HTML being edited"
                src={processEditing}
              />
            </Box>
            <Box marginBottom={isSmScreen ? 4 : 0} order={isSmScreen ? 1 : 2}>
              <Box marginBottom={2}>
                <Typography variant="h2">Assisted automation</Typography>
              </Box>
              <Typography component="p" variant="h5">
                State-of-the-art character recognition technology meets with
                subject expert knowledge to gain the best harvest possible.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={`${classes.process} ${sharedClasses.bleed}`}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={isSmScreen ? "column" : "row"}
            paddingLeft={isSmScreen ? 0 : 12}
          >
            <Box
              marginBottom={isSmScreen ? 4 : 0}
              marginRight={isSmScreen ? 0 : 12}
            >
              <Box marginBottom={2}>
                <Typography variant="h2">Human expertise</Typography>
              </Box>
              <Typography component="p" variant="h5">
                Items will be reviewed, categorised and banked at their best by
                utilising the expertise of the subject experts and approvers.
              </Typography>
            </Box>
            <img alt="Metadata being added to an item" src={processTopics} />
          </Box>
        </Box>
        <Box className={`${classes.secondaryBanner} ${sharedClasses.bleed}`}>
          <Box clone marginBottom={2}>
            <img alt="Check" src={check} />
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h2">Banked for the future</Typography>
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography component="p" variant="h5">
                Content Harvesting Tool will enable a whole new ‘on-screen
                experience’ in assessment.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          className={`${classes.value} ${sharedClasses.bleed}`}
          component="section"
        >
          <Box clone marginBottom={4}>
            <ValueChip label="Content Harvesting" variant="outlined" />
          </Box>
          <Box marginBottom={8}>
            <Typography variant="h1">
              Unlocking the value of our content
            </Typography>
          </Box>
          <Box clone marginBottom={8}>
            <img alt="A folder of content" src={value} />
          </Box>
          <Box clone marginBottom={5}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box marginBottom={1}>
                  <Typography component="p" variant="h5">
                    Cambridge Assessment holds highly valuable content in its
                    archives.
                  </Typography>
                </Box>
                <Typography component="p" variant="h5">
                  Now it&rsquo;s time to prepare that content for the future.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={handleClickLogIn}
            data-testid="go-to-log-in-button"
          >
            Go to log in
          </Button>
        </Box>
        <Box
          className={`${classes.footer} ${sharedClasses.bleed}`}
          component="footer"
          display="flex"
          flexDirection={isSmScreen ? "column" : "row"}
          paddingY={4}
          role="contentinfo"
        >
          <Box component="section">
            <Box marginBottom={1}>
              <Typography variant="subtitle1">The Cambridge family</Typography>
            </Box>
            <ul>
              <li>
                <a href="https://www.cambridge.org">
                  Cambridge University Press
                </a>
              </li>
              <li>
                <a href="https://www.cambridgeassessment.org.uk">
                  Cambridge Assessment
                </a>
              </li>
              <li>
                <a
                  href="https://mycambridge.prep.internal.sso.cambridge.org/contact-us"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Box alignItems="center" component="span" display="flex">
                    <Box marginRight={1}>Contact us</Box>{" "}
                    <Launch fontSize="small" />
                  </Box>
                </a>
              </li>
            </ul>
          </Box>
          <Box component="section">
            <Box marginBottom={1}>
              <Typography variant="subtitle1">Our brands</Typography>
            </Box>
            <ul>
              <li>
                <a href="https://www.admissionstesting.org">
                  Cambridge Assessment Admissions Testing
                </a>
              </li>
              <li>
                <a href="https://www.cambridgeenglish.org">
                  Cambridge Assessment English
                </a>
              </li>
              <li>
                <a href="https://www.cambridgeinternational.org">
                  Cambridge Assessment International Education
                </a>
              </li>
              <li>
                <a href="https://www.cambridgeassessment.org.uk/the-network">
                  Cambridge Assessment Network
                </a>
              </li>
              <li>
                <a href="https://www.cem.org">CEM</a>
              </li>
              <li>
                <a href="https://www.ocr.org.uk">OCR</a>
              </li>
            </ul>
          </Box>
          <Box component="section">
            <Box clone marginBottom={1} marginLeft={-3}>
              <img
                alt="Cambridge University Press and Cambridge Assessment Logos"
                src={caCupLogos}
              />
            </Box>
            <Box display="flex">
              <Box clone marginRight={4}>
                <img alt="Accreditation" src={accreditation} />
              </Box>
              <img alt="沪ICP备15055955号" src={icp} />
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

export default Home;
