// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

// Components
import Dropzone from "components/dropzone/Dropzone";
import LocalFile from "components/localFile/LocalFile";
import Message from "components/message/Message";
import UploadHeader from "pages/projects/upload/components/uploadHeader/UploadHeader";

// Interface
import { IJob, ISubjectInfo } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import {
  getFileGroup,
  validCIFilenameRegEx,
  validOCRFilenameRegEx
} from "utils/file";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  LinearProgress,
  Typography
} from "@material-ui/core";
import { Folder, InsertDriveFile } from "@material-ui/icons";
import { FileRejection } from "react-dropzone";
import { useHistory } from "react-router-dom";

interface FilePairs {
  [key: string]: {
    ms?: {
      file: File;
      originalFilename: string;
    };
    qp?: {
      file: File;
      originalFilename: string;
    };
  };
}

interface Props {
  businessUnit: ISubjectInfo["businessUnit"];
  invalidFiles: {
    existing: File[];
    invalid: File[];
    rejected: File[];
  };
  setActiveStep: React.Dispatch<React.SetStateAction<"initial" | "summary">>;
  setInvalidFiles: React.Dispatch<
    React.SetStateAction<{
      existing: File[];
      invalid: File[];
      rejected: File[];
    }>
  >;
  setValidFiles: React.Dispatch<React.SetStateAction<File[]>>;
  validFiles: File[];
}

const Summary: FC<Props> = (props): ReactElement => {
  const { setInvalidFiles, setValidFiles } = props;
  const { createSignedUploadUrl, getJobs, getJobsByProject, uploadFile } =
    useApi();
  const { project, updateProjectSuccess } = useProject();
  const [addFilesClickedTimestamp, setAddFilesClickedTimestamp] = useState("");
  const [completedUploadsCount, setCompletedUploadsCount] = useState(0);
  const [filePairs, setFilePairs] = useState({} as FilePairs);
  const [isConfirmUploadDialogueOpen, setIsConfirmUploadDialogueOpen] =
    useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [jobs, setJobs] = useState([] as IJob[]);
  const history = useHistory();

  useEffect(() => {
    if (!completedUploadsCount) {
      return;
    }

    if (
      completedUploadsCount === jobs.length &&
      jobs.every((job) => job.markScheme && job.questionPaper)
    ) {
      setIsUploading(false);

      history.push({
        pathname: `/projects/${project.key}/edit/uploads`,
        state: {
          businessUnit: props.businessUnit,
          jobs
        }
      });

      return;
    }

    getJobsByProject<IJob[]>(project.key).then((response) => {
      setJobs(response.data || ([] as IJob[]));
    });
  }, [
    completedUploadsCount,
    getJobsByProject,
    history,
    jobs,
    project.key,
    props.businessUnit
  ]);

  useEffect(() => {
    const object = {} as FilePairs;

    props.validFiles.forEach((file) => {
      const group = getFileGroup(file);
      const paperType = file.name.toLowerCase().includes("qp") ? "qp" : "ms";

      if (!object[group]) {
        object[group] = {};
      }

      object[group][paperType] = {
        file: new File(
          [file],
          `${group.replace(
            "*",
            props.businessUnit === "CI" ? paperType : paperType.toUpperCase()
          )}.pdf`,
          {
            type: "application/pdf"
          }
        ),
        originalFilename: file.name
      };
    });

    setFilePairs(
      Object.keys(object)
        .sort()
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue]: object[currentValue]
          }),
          {}
        )
    );
  }, [props.businessUnit, props.invalidFiles, props.validFiles]);

  useEffect(() => {
    if (
      !isInitialLoad ||
      (!Object.keys(filePairs).length &&
        !props.invalidFiles.existing.length &&
        !props.invalidFiles.invalid.length &&
        !props.invalidFiles.rejected.length)
    ) {
      return;
    }

    setIsInitialLoad(false);
  }, [filePairs, isInitialLoad, props.invalidFiles, props.validFiles]);

  const handleClickAddFiles = (): void => {
    setAddFilesClickedTimestamp(Date.now().toString());
  };

  const handleClickCloseConfirmUploadDialogue = (): void => {
    setIsConfirmUploadDialogueOpen(false);
  };

  const handleClickConfirmUpload = (): void => {
    setIsConfirmUploadDialogueOpen(false);
    setIsUploading(true);

    const files = Object.values(filePairs)
      .map((filePair) => [filePair.ms?.file, filePair.qp?.file])
      .flat()
      .filter((filePair) => filePair) as File[];

    Promise.all(files.map((file) => createUrlAndUploadSingleFile(file))).then(
      () => {
        setCompletedUploadsCount(files.length / 2);

        updateProjectSuccess({
          ...project,
          uploadedJobs: files.length / 2
        });

        getJobsByProject<IJob[]>(project.key).then((response) => {
          setJobs(response.data || ([] as IJob[]));
        });
      }
    );
  };

  const handleClickDelete = (fileName: string): void => {
    props.setValidFiles(
      props.validFiles.filter((file) => file.name !== fileName)
    );

    if (
      Object.keys(filePairs).length === 1 &&
      (!Object.values(filePairs)[0].ms || !Object.values(filePairs)[0].qp)
    ) {
      setIsInitialLoad(true);
      props.setActiveStep("initial");
    }
  };

  const handleClickUpload = (): void => {
    setIsConfirmUploadDialogueOpen(true);
  };

  const createUrlAndUploadSingleFile = async (file: File) => {
    const data = new FormData();
    const res = await createSignedUploadUrl(project.key, {
      name: file.name
    });
    const url = res.headers.get("location") as string;

    data.append(file.name, file);

    await uploadFile(url, data);
  };

  const getMissingFilesCount = (pairs: FilePairs, type: string): number => {
    return Object.keys(pairs).filter((key) => !pairs[key][type as "ms" | "qp"])
      .length;
  };

  const processReceivedFiles = useCallback(
    function processReceivedFiles(
      acceptedFiles: File[],
      fileRejections: FileRejection[]
    ) {
      if (acceptedFiles.length) {
        Promise.all(
          acceptedFiles.map((file) =>
            getJobs<IJob>({
              key: getFileGroup(file).replace("_*_", "_")
            })
          )
        ).then((response) => {
          const existing = [
            ...new Set(
              response
                .map((res) => res.data || [])
                .flat()
                .flatMap((res) => [res.markScheme, res.questionPaper])
            )
          ];

          setInvalidFiles({
            existing: acceptedFiles.filter((file) =>
              existing.includes(file.name)
            ),
            invalid: acceptedFiles.filter(
              (file) =>
                !file.name.match(
                  props.businessUnit === "CI"
                    ? validCIFilenameRegEx
                    : validOCRFilenameRegEx
                ) || !file.name.includes(project.syllabusCode)
            ),
            rejected: fileRejections.map((rejection) => rejection.file)
          });

          setValidFiles((previousValue) =>
            previousValue.concat(
              acceptedFiles.filter(
                (file) =>
                  !existing.includes(file.name) &&
                  file.name.match(
                    props.businessUnit === "CI"
                      ? validCIFilenameRegEx
                      : validOCRFilenameRegEx
                  ) &&
                  file.name.includes(project.syllabusCode)
              )
            )
          );
        });
      } else {
        setInvalidFiles({
          existing: [],
          invalid: [],
          rejected: fileRejections.map((rejection) => rejection.file)
        });
      }
    },
    [
      getJobs,
      project.syllabusCode,
      props.businessUnit,
      setInvalidFiles,
      setValidFiles
    ]
  );

  const processReceivedFilesMemoised = useMemo(() => {
    return (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
      processReceivedFiles(acceptedFiles, rejectedFiles);
    };
  }, [processReceivedFiles]);

  const missingMarkSchemesCount = getMissingFilesCount(filePairs, "ms");
  const missingQuestionPapersCount = getMissingFilesCount(filePairs, "qp");

  return (
    <Box marginBottom="250px" data-testid="upload-summary">
      <Dialog
        onClose={handleClickCloseConfirmUploadDialogue}
        aria-labelledby="confirm-upload-dialogue-heading"
        open={isConfirmUploadDialogueOpen}
        data-testid="confirm-upload-dialogue"
      >
        <Box padding={4}>
          <Box marginBottom={2}>
            <Typography variant="h4">Before you send your files...</Typography>
          </Box>
          <Grid container>
            <Grid item md={10}>
              <Typography paragraph>
                Paper upload is a one-off operation for each project. If you
                want to add more papers to this project, please hit Cancel and
                return the upload page.
              </Typography>
              <Typography>
                Once the upload starts you cannot change or upload new files to
                this project. You can always create a new project.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box display="flex" padding={3}>
          <Box marginLeft="auto">
            <Box clone marginRight={2}>
              <Button
                color="primary"
                onClick={handleClickCloseConfirmUploadDialogue}
                variant="text"
                data-testid="cancel-upload-button"
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickConfirmUpload}
              variant="contained"
              data-testid="confirm-upload-button"
            >
              Send for harvesting
            </Button>
          </Box>
        </Box>
      </Dialog>
      {!isUploading && (
        <UploadHeader
          actions={
            <>
              <Box clone marginRight={2}>
                <Button
                  color="primary"
                  disableElevation
                  onClick={handleClickAddFiles}
                  startIcon={<Folder />}
                  variant="outlined"
                  data-testid="add-files-button"
                >
                  Add files
                </Button>
              </Box>
              <Button
                color="primary"
                disableElevation
                disabled={
                  isUploading ||
                  missingMarkSchemesCount > 0 ||
                  missingQuestionPapersCount > 0 ||
                  !props.validFiles.length
                }
                onClick={handleClickUpload}
                data-testid="upload-button"
              >
                Send for harvesting
              </Button>
            </>
          }
          syllabusCode={project.syllabusCode}
        />
      )}
      {isUploading && (
        <Box marginBottom={4}>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Uploading documents...
              </Typography>
            </Box>
            <LinearProgress />
          </Box>
          <Divider />
        </Box>
      )}
      <>
        {!isUploading && (
          <Box marginBottom={6}>
            <Divider />
            <Box>
              {isInitialLoad && (
                <Message
                  body={
                    <Box alignContent="center" display="flex">
                      <Box marginRight={2}>Processing files...</Box>
                      <CircularProgress size={18} />
                    </Box>
                  }
                  level="information"
                />
              )}
              {props.invalidFiles.existing.length > 0 && (
                <Message
                  body={props.invalidFiles.existing
                    .map((file) => file.name)
                    .join(", ")}
                  dismissable
                  heading={`${props.invalidFiles.existing.length} ${
                    props.invalidFiles.existing.length === 1 ? "file" : "files"
                  } failed due to belonging to another project:`}
                  level="error"
                  testId="existing-files-message"
                />
              )}
              {props.invalidFiles.invalid.length > 0 && (
                <Message
                  body={props.invalidFiles.invalid
                    .map((file) => file.name)
                    .join(", ")}
                  dismissable
                  heading={`${props.invalidFiles.invalid.length} ${
                    props.invalidFiles.invalid.length === 1 ? "file" : "files"
                  } failed due to an incorrect name or syllabus code:`}
                  level="error"
                  testId="invalid-files-message"
                />
              )}
              {props.invalidFiles.rejected.length > 0 && (
                <Message
                  body={props.invalidFiles.rejected
                    .map((file) => file.name)
                    .join(", ")}
                  dismissable
                  heading={`${props.invalidFiles.rejected.length} ${
                    props.invalidFiles.rejected.length === 1 ? "file" : "files"
                  } failed due to an incorrect file format:`}
                  level="error"
                  testId="rejected-files-message"
                />
              )}
              {!isInitialLoad && Object.keys(filePairs).length === 0 && (
                <Message
                  body=""
                  heading="No files selected for upload"
                  level="warning"
                  testId="empty-files-message"
                />
              )}
              {Object.keys(filePairs).length > 0 &&
                missingMarkSchemesCount === 0 &&
                missingQuestionPapersCount === 0 && (
                  <Message
                    body=""
                    heading={`${
                      Object.keys(filePairs).length * 2
                    } files are ready to upload`}
                    level="success"
                    testId="files-ready-message"
                  />
                )}
              {missingMarkSchemesCount > 0 && (
                <Message
                  body=""
                  level="warning"
                  heading={`${missingMarkSchemesCount} mark scheme ${
                    missingMarkSchemesCount === 1 ? "document" : "documents"
                  } missing`}
                  testId="missing-mark-schemes-message"
                />
              )}
              {missingQuestionPapersCount > 0 && (
                <Message
                  body=""
                  level="warning"
                  heading={`${missingQuestionPapersCount} question paper ${
                    missingQuestionPapersCount === 1 ? "document" : "documents"
                  } missing`}
                  testId="missing-question-papers-message"
                />
              )}
            </Box>
          </Box>
        )}
        <Box display="none">
          <Dropzone
            accept="application/pdf"
            addFilesClickedTimestamp={addFilesClickedTimestamp}
            isUploading={false}
            onReceivedFiles={processReceivedFilesMemoised}
            testId="add-files-dropzone"
          ></Dropzone>
        </Box>
        <Box
          style={{
            opacity: isUploading ? 0.5 : 1,
            pointerEvents: isUploading ? "none" : "auto"
          }}
        >
          {!isInitialLoad && (
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography component="h3" variant="h5">
                  Question papers (
                  {
                    Object.keys(filePairs).filter((key) => filePairs[key].qp)
                      .length
                  }
                  )
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Typography component="h3" variant="h5">
                  Mark schemes (
                  {
                    Object.keys(filePairs).filter((key) => filePairs[key].ms)
                      .length
                  }
                  )
                </Typography>
              </Grid>
            </Grid>
          )}
          {Object.keys(filePairs).map((key) => {
            const markScheme = filePairs[key].ms;
            const questionPaper = filePairs[key].qp;

            return (
              <Box key={key} marginBottom={2}>
                <Grid container spacing={4}>
                  {questionPaper && (
                    <Grid item xs={4}>
                      <LocalFile
                        displayName={questionPaper.originalFilename}
                        file={questionPaper.file}
                        onClickDelete={() =>
                          handleClickDelete(questionPaper.originalFilename)
                        }
                      />
                    </Grid>
                  )}
                  {!questionPaper && markScheme && (
                    <Box
                      clone
                      display="flex"
                      data-testid="question-paper-dropzone-container"
                    >
                      <Grid item xs={4}>
                        <Box
                          alignItems="center"
                          className="dropzone"
                          display="flex"
                          onClick={handleClickAddFiles}
                        >
                          <Box clone marginRight={1}>
                            <InsertDriveFile fontSize="large" />
                          </Box>
                          <Typography
                            component="p"
                            display="inline"
                            variant="subtitle1"
                          >
                            {markScheme.originalFilename.replace(
                              props.businessUnit === "CI" ? "ms" : "MS",
                              props.businessUnit === "CI" ? "qp" : "QP"
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                  <Box clone alignSelf="center">
                    <Grid item xs={1}>
                      <Divider />
                    </Grid>
                  </Box>
                  {markScheme && (
                    <Grid item xs={4}>
                      <LocalFile
                        displayName={markScheme.originalFilename}
                        file={markScheme.file}
                        onClickDelete={() => {
                          handleClickDelete(markScheme.originalFilename);
                        }}
                      />
                    </Grid>
                  )}
                  {!markScheme && questionPaper && (
                    <Box
                      clone
                      display="flex"
                      data-testid="mark-scheme-dropzone-container"
                    >
                      <Grid item xs={4}>
                        <Box
                          alignItems="center"
                          className="dropzone"
                          display="flex"
                          onClick={handleClickAddFiles}
                        >
                          <Box clone marginRight={1}>
                            <InsertDriveFile fontSize="large" />
                          </Box>
                          <Typography
                            component="p"
                            display="inline"
                            variant="subtitle1"
                          >
                            {questionPaper.originalFilename.replace(
                              props.businessUnit === "CI" ? "qp" : "QP",
                              props.businessUnit === "CI" ? "ms" : "MS"
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              </Box>
            );
          })}
        </Box>
      </>
    </Box>
  );
};

export default Summary;
