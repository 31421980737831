// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, makeStyles } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";

interface Props {
  activeStep: number;
  isSmScreen: boolean;
  onClickDot: (index: number) => void;
  onClickComplete: () => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
  stepCount: number;
  userRole: "admin" | "examiner";
}

const OnboardingStepNavigation: FC<Props> = (props): ReactElement => {
  const useStyles = makeStyles({
    dotNavigation: {
      backgroundColor: colours.backgroundGrey,
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      "& .dot": {
        backgroundColor: colours.monochromeLight,
        borderRadius: "50%",
        cursor: "pointer",
        display: "inline-block",
        height: "11px",
        width: "11px",
        "&.active": {
          backgroundColor: colours.corporateBlue
        },
        "&:not(:last-child)": {
          marginRight: "24px"
        }
      }
    }
  });
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      className={classes.dotNavigation}
      display="flex"
      flexDirection={props.isSmScreen ? "column" : "row"}
      justifyContent="center"
      paddingY={3}
      data-testid="onboarding-step-navigation"
    >
      <Box marginBottom={props.isSmScreen ? 4 : 0}>
        {[...new Array(props.stepCount)].map((item, index) => (
          <div
            className={`dot ${index + 1 === props.activeStep ? "active" : ""}`}
            key={index}
            onClick={() => props.onClickDot(index + 1)}
            data-testid="dot"
          ></div>
        ))}
      </Box>
      <Box
        position={props.isSmScreen ? "static" : "absolute"}
        right={props.isSmScreen ? 0 : 16}
      >
        {props.activeStep > 1 && (
          <Box clone marginRight={2}>
            <Button
              color="primary"
              disableElevation
              onClick={props.onClickPrevious}
              variant="outlined"
              data-testid="previous-button"
            >
              Previous
            </Button>
          </Box>
        )}
        {}
        {props.activeStep === props.stepCount && (
          <Box clone marginRight={2}>
            <Button
              color="primary"
              disableElevation
              onClick={props.onClickComplete}
              startIcon={props.userRole === "admin" ? null : <PlayArrow />}
              data-testid="complete-button"
            >
              {props.userRole === "admin"
                ? "Go to dashboard"
                : "Start your task"}
            </Button>
          </Box>
        )}
        {props.activeStep < props.stepCount && (
          <Box clone marginRight={2}>
            <Button
              color="primary"
              disableElevation
              onClick={props.onClickNext}
              data-testid="next-button"
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingStepNavigation;
