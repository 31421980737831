// Core
import React, { FC, ReactElement } from "react";

// Enums
import { ProjectType } from "enums";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface Props {
  hasFailed: boolean;
  onClickDelete: (projectKey: string) => void;
  project: IProject;
}

const NotStartedProject: FC<Props> = (props): ReactElement => {
  const history = useHistory();
  const sharedClasses = sharedStyles();

  const useStyles = makeStyles({
    faded: {
      opacity: 0.5
    }
  });
  const classes = useStyles();

  const handleClickGoToProject = (): void => {
    history.push(`/projects/${props.project.key}/edit`);
  };

  return (
    <Box clone marginBottom={4} data-testid="not-started-project">
      <Card>
        <CardContent>
          <Box alignItems="center" display="flex" marginBottom={4}>
            <Box>
              <Box marginBottom={1}>
                <Typography
                  className={props.hasFailed ? classes.faded : ""}
                  component="h2"
                  variant="h4"
                  data-testid="project-name"
                >
                  {props.project.name}
                </Typography>
              </Box>
              <Typography
                className={props.hasFailed ? classes.faded : ""}
                data-testid="project-type"
              >
                {ProjectType[props.project.type]}
              </Typography>
            </Box>
            {props.hasFailed && (
              <Box marginLeft={4}>
                <Chip
                  color="secondary"
                  label="Harvesting failed"
                  variant="outlined"
                  data-testid="harvesting-failed-chip"
                />
              </Box>
            )}
          </Box>
          <Box display="flex">
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={`${props.hasFailed ? classes.faded : ""} ${
                    sharedClasses.fadedText
                  }`}
                  component="h3"
                  variant="subtitle1"
                >
                  Syllabus code
                </Typography>
              </Box>
              <Typography
                className={props.hasFailed ? classes.faded : ""}
                component="p"
                variant="h5"
                data-testid="syllabus-code"
              >
                {props.project.syllabusCode}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={`${props.hasFailed ? classes.faded : ""} ${
                    sharedClasses.fadedText
                  }`}
                  component="h3"
                  variant="subtitle1"
                >
                  Uploaded test papers
                </Typography>
              </Box>
              <Typography
                className={props.hasFailed ? classes.faded : ""}
                component="p"
                variant="h5"
                data-testid="uploaded-papers"
              >
                {props.project.uploadedJobs}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={`${props.hasFailed ? classes.faded : ""} ${
                    sharedClasses.fadedText
                  }`}
                  component="h3"
                  variant="subtitle1"
                >
                  Extracted items
                </Typography>
              </Box>
              <Typography
                className={props.hasFailed ? classes.faded : ""}
                component="p"
                variant="h5"
                data-testid="items"
              >
                {props.project.items}
              </Typography>
            </Box>
            {!props.hasFailed && (
              <>
                <Box marginRight={8}>
                  <Box marginBottom={1}>
                    <Typography
                      className={sharedClasses.fadedText}
                      component="h3"
                      variant="subtitle1"
                    >
                      Subject experts
                    </Typography>
                  </Box>
                  <Typography
                    component="p"
                    variant="h5"
                    data-testid="subject-experts"
                  >
                    {props.project.subjectExperts.length === 0
                      ? "Not assigned yet"
                      : props.project.subjectExperts.length}
                  </Typography>
                </Box>
                <Box>
                  <Box marginBottom={1}>
                    <Typography
                      className={sharedClasses.fadedText}
                      component="h3"
                      variant="subtitle1"
                    >
                      Approvers
                    </Typography>
                  </Box>
                  <Typography
                    component="p"
                    variant="h5"
                    data-testid="approvers"
                  >
                    {props.project.approvers.length === 0
                      ? "Not assigned yet"
                      : props.project.approvers.length}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
        <CardActions>
          <Box clone marginRight={2}>
            <Button
              color="secondary"
              onClick={() => props.onClickDelete(props.project.key)}
              variant="text"
              data-testid="delete-button"
            >
              Delete this project
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={handleClickGoToProject}
            variant="contained"
            data-testid="go-to-project-button"
          >
            Go to project
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NotStartedProject;
