// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
}

const LoseSelectedFilesDialogue: FC<Props> = (props): ReactElement => {
  const history = useHistory();

  const handleClickBack = (): void => {
    history.push("/admin/dashboard");
  };

  const handleClickCancel = (): void => {
    props.onClickClose();
  };

  return (
    <Dialog
      onClose={props.onClickClose}
      aria-labelledby="lose-selected-files-dialogue-heading"
      open={props.isOpen}
      data-testid="lose-selected-files-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Box marginBottom={2}>
            <Typography
              component="h2"
              variant="h4"
              data-testid="lose-selected-files-dialogue-heading"
            >
              You will lose your selected files
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography>
              You can go back to your dashboard and continue later, but{" "}
              <span style={{ textDecoration: "underline" }}>
                you will lose the selected files in this step
              </span>
              .
              <br />
              <br />
              Please confirm this.
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Box marginRight={2}>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickCancel}
              variant="text"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={handleClickBack}
            data-testid="back-button"
          >
            Go back to dashboard
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LoseSelectedFilesDialogue;
