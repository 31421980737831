// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Interfaces
import { Item } from "interfaces";
import { IKeyword } from "@qti-scraper/interfaces";

// Utils
import { useApi } from "utils/context";
import { sharedStyles } from "utils/theme";
import { useDebouncedEffect } from "utils/useDebouncedEffect";

// Vendor
import { colours, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import { LabelOutlined } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash.isequal");
import Highlighter from "react-highlight-words";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

interface Props {
  isEditing: boolean;
  item: Item;
  onChangeMandatoryMetadata?: (hasMandatoryMetadata: boolean) => void;
  onSaveMetadata?: (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ) => void;
  projectKey?: string;
  shouldSaveMetadata?: boolean;
}

interface Option extends IKeyword {
  sectionTitle: string;
}

const ItemInfo: FC<Props> = (props): ReactElement => {
  const { getSkills, getTopics } = useApi();
  const [activeSkills, setActiveSkills] = useState(
    props.item.skills as Option[]
  );
  const [activeTopics, setActiveTopics] = useState(
    props.item.topics as Option[]
  );
  const [formFields, setFormFields] = useState({
    additionalInformation: props.item.additionalInformation,
    difficulty: props.item.difficulty,
    skill: "",
    topic: ""
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [skills, setSkills] = useState([] as Option[]);
  const [topics, setTopics] = useState([] as Option[]);
  const sharedClasses = sharedStyles();

  useDebouncedEffect(
    () => {
      if (formFields.skill.length < 1) {
        setSkills([]);

        return;
      }

      getSkills<IKeyword[]>(props.projectKey as string, {
        search: formFields.skill,
        isSkills: "true"
      }).then((response) => {
        if (response.data === undefined) {
          setSkills([]);

          return;
        }

        const broaderKeywordMatchesIndex = parseFloat(
          response.headers.get("x-broader-keyword") || "-1"
        );

        if (broaderKeywordMatchesIndex === -1) {
          setSkills(response.data as Option[]);
        } else {
          const matches = (response.data as Option[])
            .slice(0, broaderKeywordMatchesIndex)
            .map((skill) => ({ ...skill, sectionTitle: "" }));
          const broaderKeywordMatches = (response.data as Option[])
            .slice(broaderKeywordMatchesIndex)
            .map((skill) => ({
              ...skill,
              sectionTitle: "Found in the broader skill:"
            }));

          setSkills(matches.concat(broaderKeywordMatches));
        }
      });
    },
    1000,
    [formFields.skill]
  );

  useDebouncedEffect(
    () => {
      if (formFields.topic.length < 1) {
        setTopics([]);

        return;
      }

      getTopics<IKeyword[]>(props.projectKey as string, {
        search: formFields.topic
      }).then((response) => {
        if (response.data === undefined) {
          setTopics([]);

          return;
        }

        const broaderKeywordMatchesIndex = parseFloat(
          response.headers.get("x-broader-keyword") || "-1"
        );

        if (broaderKeywordMatchesIndex === -1) {
          setTopics(response.data as Option[]);
        } else {
          const matches = (response.data as Option[])
            .slice(0, broaderKeywordMatchesIndex)
            .map((topic) => ({ ...topic, sectionTitle: "" }));
          const broaderKeywordMatches = (response.data as Option[])
            .slice(broaderKeywordMatchesIndex)
            .map((topic) => ({
              ...topic,
              sectionTitle: "Found in the broader topic:"
            }));

          setTopics(matches.concat(broaderKeywordMatches));
        }
      });
    },
    1000,
    [formFields.topic]
  );

  useEffect(() => {
    props.onChangeMandatoryMetadata &&
      props.onChangeMandatoryMetadata(
        activeSkills.length && activeTopics.length ? true : false
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSkills, activeTopics]);

  useEffect(() => {
    if (!props.shouldSaveMetadata) {
      return;
    }

    props.onSaveMetadata &&
      props.onSaveMetadata(
        {
          additionalInformation: formFields.additionalInformation,
          difficulty: formFields.difficulty,
          skills: activeSkills,
          topics: activeTopics
        },
        hasUnsavedChanges
      );

    setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldSaveMetadata]);

  const changeAdditionalInformation = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      additionalInformation: event.target.value
    });

    setHasUnsavedChanges(true);
  };

  const changeDifficulty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as "low" | "medium" | "high";

    setFormFields({
      ...formFields,
      difficulty: value
    });

    setHasUnsavedChanges(true);
  };

  const changeSkillInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setFormFields({
      ...formFields,
      skill: event.target.value
    });
  };

  const changeSkillOptions = (
    event: ChangeEvent<unknown>,
    options: Option[]
  ): void => {
    setActiveSkills(options);

    setHasUnsavedChanges(true);
  };

  const changeTopicInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setFormFields({
      ...formFields,
      topic: event.target.value
    });
  };

  const changeTopicOptions = (
    event: ChangeEvent<unknown>,
    options: Option[]
  ): void => {
    setActiveTopics(options);

    setHasUnsavedChanges(true);
  };

  const handleGetOptionSelected = (option: Option, value: Option): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sectionTitle, ...optionWithoutSectionTitle } = option;

    return isEqual(option, value) || isEqual(optionWithoutSectionTitle, value);
  };

  const renderOption = (
    option: IKeyword,
    value: string,
    type: "skill" | "topic"
  ): ReactElement => {
    const startOfWordRegEx = new RegExp(`\\b${value}`, "g");
    const startOfWordWithPrefixRegEx = new RegExp(`\\b(de|in|un)${value}`, "g");
    const broaderKeywordMatchWithPrefix = startOfWordWithPrefixRegEx.exec(
      option.broaderKeyword
    );
    const preferredNarrowKeywordMatchWithPrefix =
      startOfWordWithPrefixRegEx.exec(option.preferredNarrowKeyword);

    return (
      <Box
        alignItems="center"
        display="flex"
        paddingY={1}
        width={1}
        data-testid={`${type}-option`}
      >
        <Box marginRight={1}>
          <LabelOutlined htmlColor={colours.monochromeMid} />
        </Box>
        <Box>
          <Box marginBottom={1}>
            <Typography>
              <Highlighter
                highlightTag={({ children }: { children: string }) => (
                  <>
                    {preferredNarrowKeywordMatchWithPrefix ? (
                      <span>
                        {children.substring(0, 2)}
                        <span style={{ fontWeight: 700 }}>
                          {children.substring(2)}
                        </span>
                      </span>
                    ) : (
                      <span style={{ fontWeight: 700 }}>{children}</span>
                    )}
                  </>
                )}
                searchWords={[startOfWordRegEx, startOfWordWithPrefixRegEx]}
                textToHighlight={option.preferredNarrowKeyword}
              />{" "}
              {option.narrowAlternativeKeywords.map((keyword, index) => {
                const narrowAlternativeKeywordMatchWithPrefix =
                  startOfWordWithPrefixRegEx.exec(keyword);

                return (
                  <React.Fragment key={keyword}>
                    {index === 0 && "("}
                    <Highlighter
                      highlightTag={({ children }: { children: string }) => (
                        <>
                          {narrowAlternativeKeywordMatchWithPrefix ? (
                            <span>
                              {children.substring(0, 2)}
                              <span style={{ fontWeight: 700 }}>
                                {children.substring(2)}
                              </span>
                            </span>
                          ) : (
                            <span style={{ fontWeight: 700 }}>{children}</span>
                          )}
                        </>
                      )}
                      searchWords={[
                        startOfWordRegEx,
                        startOfWordWithPrefixRegEx
                      ]}
                      textToHighlight={keyword}
                    />
                    {index < option.narrowAlternativeKeywords.length - 1 &&
                      ", "}
                    {index === option.narrowAlternativeKeywords.length - 1 &&
                      ")"}
                  </React.Fragment>
                );
              })}
            </Typography>
          </Box>
          <Typography style={{ opacity: 0.64 }}>
            <Highlighter
              highlightTag={({ children }: { children: string }) => (
                <>
                  {broaderKeywordMatchWithPrefix ? (
                    <span>
                      {children.substring(0, 2)}
                      <span style={{ textDecoration: "underline" }}>
                        {children.substring(2)}
                      </span>
                    </span>
                  ) : (
                    <span style={{ textDecoration: "underline" }}>
                      {children}
                    </span>
                  )}
                </>
              )}
              searchWords={[startOfWordRegEx, startOfWordWithPrefixRegEx]}
              textToHighlight={option.broaderKeyword}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div data-testid="info">
      <Box marginBottom={2}>
        <Typography component="h2" variant="h5">
          Topics
        </Typography>
      </Box>
      {props.isEditing && (
        <Box marginBottom={5}>
          <Autocomplete
            filterOptions={(topic) => topic}
            filterSelectedOptions
            getOptionLabel={(option) => option.preferredNarrowKeyword}
            getOptionSelected={handleGetOptionSelected}
            groupBy={(option) => option.sectionTitle}
            multiple
            onChange={changeTopicOptions}
            options={[...activeTopics, ...topics]}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "topics-input"
                }}
                onChange={changeTopicInput}
                placeholder="Search for a topic"
              />
            )}
            renderOption={(option, { inputValue }) =>
              renderOption(option, inputValue, "topic")
            }
            value={activeTopics}
            data-testid="topics-autocomplete"
          />
        </Box>
      )}
      {!props.isEditing && (
        <Box marginBottom={5}>
          {props.item.topics?.map((topic, index) => (
            <Box
              clone
              key={topic.preferredNarrowKeyword}
              marginRight={
                index === (props.item.topics?.length || 0) - 1 ? 0 : 1
              }
            >
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="topic">
                    {topic.preferredNarrowKeyword}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      <Box marginBottom={2}>
        <Typography component="h2" variant="h5">
          Skills
        </Typography>
      </Box>
      {props.isEditing && (
        <Box marginBottom={5}>
          <Autocomplete
            filterOptions={(skill) => skill}
            filterSelectedOptions
            getOptionLabel={(option) => option.preferredNarrowKeyword}
            getOptionSelected={handleGetOptionSelected}
            groupBy={(option) => option.sectionTitle}
            multiple
            onChange={changeSkillOptions}
            options={[...activeSkills, ...skills]}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "skills-input"
                }}
                onChange={changeSkillInput}
                placeholder="Search for a skill"
              />
            )}
            renderOption={(option, { inputValue }) =>
              renderOption(option, inputValue, "skill")
            }
            value={activeSkills}
            data-testid="skills-autocomplete"
          />
        </Box>
      )}
      {!props.isEditing && (
        <Box marginBottom={5}>
          {props.item.skills?.map((skill, index) => (
            <Box
              clone
              key={skill.preferredNarrowKeyword}
              marginRight={
                index === (props.item.skills?.length || 0) - 1 ? 0 : 1
              }
            >
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="skill">
                    {skill.preferredNarrowKeyword}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      <Box marginBottom={5}>
        <Box marginBottom={2}>
          <Typography component="h2" variant="h5">
            Difficulty{" "}
            <span className={sharedClasses.fadedText}>(optional)</span>
          </Typography>
        </Box>
        {props.isEditing && (
          <RadioGroup
            aria-label="difficulty"
            name="difficulty"
            onChange={changeDifficulty}
            row
            value={formFields.difficulty}
          >
            <FormControlLabel
              value="low"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "low",
                      "data-testid": "difficulty-low-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="Low"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="medium"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "medium",
                      "data-testid": "difficulty-medium-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="Medium"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="high"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "high",
                      "data-testid": "difficulty-high-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="High"
              labelPlacement="bottom"
            />
          </RadioGroup>
        )}
        {!props.isEditing && (
          <>
            {props.item.difficulty && (
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="difficulty">
                    {props.item.difficulty}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {!props.item.difficulty && (
              <Typography data-testid="difficulty">Not provided</Typography>
            )}
          </>
        )}
      </Box>
      <Box marginBottom={5}>
        <Box marginBottom={2}>
          <Typography component="h2" variant="h5">
            Additional information{" "}
            <span className={sharedClasses.fadedText}>(optional)</span>
          </Typography>
        </Box>
        {props.isEditing && (
          <TextField
            inputProps={{
              "data-testid": "additional-information-input"
            }}
            multiline={true}
            onChange={changeAdditionalInformation}
            value={formFields.additionalInformation}
          />
        )}
        {!props.isEditing && (
          <>
            {props.item.additionalInformation && (
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="additional-information">
                    {props.item.additionalInformation}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {!props.item.additionalInformation && (
              <Typography data-testid="additional-information">
                Not provided
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box marginBottom={2}>
        <Typography
          className={sharedClasses.fadedText}
          component="h2"
          variant="h5"
        >
          Data captured from the question paper
        </Typography>
      </Box>
      {props.item.metadata &&
        Object.values(props.item.metadata).map((datum, index) => (
          <Box
            clone
            key={datum}
            marginRight={
              index === Object.values(props.item.metadata || []).length - 1
                ? 0
                : 1
            }
          >
            <Card elevation={0} style={{ display: "inline-block" }}>
              <CardContent style={{ padding: "12px 16px" }}>
                <Typography data-testid="metadata">{datum}</Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
    </div>
  );
};

export default ItemInfo;
