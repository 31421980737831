// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Box, Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  body: string;
  heading?: string;
  icon?: ReactElement;
  testId?: string;
}

const Reminder: FC<Props> = (props): ReactElement => {
  return (
    <Box clone alignItems="center" display="flex" height="100%">
      <Card data-testid={props.testId ? props.testId : "reminder"}>
        <CardContent>
          <Box alignItems="center" display="flex">
            {props.icon && (
              <Box
                marginRight={2}
                data-testid={
                  props.testId ? `${props.testId}-icon` : "reminder-icon"
                }
              >
                {props.icon}
              </Box>
            )}
            <Box>
              {props.heading && (
                <Box marginBottom={1}>
                  <Typography
                    component="h3"
                    variant="subtitle1"
                    data-testid={
                      props.testId
                        ? `${props.testId}-heading`
                        : "reminder-heading"
                    }
                  >
                    {props.heading}
                  </Typography>
                </Box>
              )}

              <Typography>{props.body}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Reminder;
