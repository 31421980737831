// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import DayjsUtils from "@date-io/dayjs";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

const Details: FC = (): ReactElement => {
  const { updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [
    areApproverInstructionsMinLength,
    setAreApproverInstructionsMinLength
  ] = useState(true);
  const [
    areSubjectExpertInstructionsMinLength,
    setAreSubjectExpertInstructionsMinLength
  ] = useState(true);
  const [formFields, setFormFields] = useState({
    approverInstructions: "",
    deadline: "" as "none" | "choose",
    deadlineDate: new Date(),
    subjectExpertInstructions: ""
  });
  const history = useHistory();

  useEffect(() => {
    setAreApproverInstructionsMinLength(
      formFields.approverInstructions.length > 2
    );
  }, [formFields.approverInstructions]);

  useEffect(() => {
    setAreSubjectExpertInstructionsMinLength(
      formFields.subjectExpertInstructions.length > 2
    );
  }, [formFields.subjectExpertInstructions]);

  useEffect(() => {
    if (Object.keys(project).length) {
      setFormFields({
        approverInstructions: !!project.approverInstructions
          ? project.approverInstructions
          : "Review the items submitted by subject experts. If suitable, submit them to the item bank.",
        deadline: project.targetEndDate ? "choose" : "none",
        deadlineDate: project.targetEndDate
          ? dayjs((project.targetEndDate as string).split("T")[0]).toDate()
          : new Date(),
        subjectExpertInstructions: !!project.subjectExpertInstructions
          ? project.subjectExpertInstructions
          : "Check the extracted items. You will need to provide topics and skills information for each item. When completed, submit items to the approver."
      });
    }
  }, [project]);

  const handleChangeApproverInstructions = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      approverInstructions: event.target.value
    });
  };

  const handleChangeDeadline = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as "choose" | "none";

    setFormFields({
      ...formFields,
      deadline: value
    });
  };

  const handleChangeDeadlineDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    setFormFields({
      ...formFields,
      deadlineDate: dayjs(date).toDate()
    });
  };

  const handleChangeSubjectExpertInstructions = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      subjectExpertInstructions: event.target.value
    });
  };

  const handleClickContinue = (): void => {
    const body = {
      approverInstructions: formFields.approverInstructions,
      name: project.name,
      subjectExpertInstructions: formFields.subjectExpertInstructions,
      targetEndDate:
        formFields.deadline === "none"
          ? ""
          : dayjs(formFields.deadlineDate).format("YYYY-MM-DDTHH:mm:ssZZ[Z]"),
      type: project.type
    };

    updateProject(project.key, {
      ...project,
      ...body
    })
      .then(() => {
        updateProjectSuccess({
          ...project,
          ...body
        });

        history.push(`/projects/${project.key}/edit/confirm`);
      })
      .catch(console.error);
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div data-testid="details-page">
        {Object.keys(project).length > 0 && (
          <>
            <Box marginBottom={4}>
              <Box display="flex" marginBottom={4}>
                <Box>
                  <Box marginBottom={1}>
                    <Typography
                      component="h2"
                      variant="h4"
                      data-testid="page-heading"
                    >
                      Enter your project details
                    </Typography>
                  </Box>
                  <Typography data-testid="page-introduction">
                    Subject experts and the approver will see these details on
                    their tasks
                  </Typography>
                </Box>
                <Box marginLeft="auto">
                  <Button
                    color="primary"
                    disableElevation
                    disabled={
                      !formFields.deadline ||
                      !formFields.approverInstructions ||
                      !formFields.subjectExpertInstructions ||
                      !areApproverInstructionsMinLength ||
                      !areSubjectExpertInstructionsMinLength
                    }
                    onClick={handleClickContinue}
                    data-testid="continue-button"
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
              <Divider />
            </Box>
            <Box marginBottom={4}>
              <Box marginBottom={2}>
                <Typography variant="subtitle1">Timeline</Typography>
              </Box>
              <RadioGroup
                aria-label="deadline"
                name="deadline"
                onChange={handleChangeDeadline}
                value={formFields.deadline}
              >
                <FormControlLabel
                  value="none"
                  control={
                    <Radio
                      color="primary"
                      inputProps={
                        {
                          "data-testid": "deadline-none-radio"
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                      }
                    />
                  }
                  label="No deadline"
                />
                <FormControlLabel
                  value="choose"
                  control={
                    <Radio
                      color="primary"
                      inputProps={
                        {
                          "data-testid": "deadline-choose-radio"
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any
                      }
                    />
                  }
                  label="Choose a deadline date for the project"
                />
              </RadioGroup>
              {formFields.deadline === "choose" && (
                <KeyboardDatePicker
                  autoOk={true}
                  disableToolbar
                  format="DD/MM/YYYY"
                  id="date-picker-inline"
                  inputProps={{
                    "data-testid": "deadline-date-input"
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  margin="normal"
                  onChange={handleChangeDeadlineDate}
                  value={formFields.deadlineDate}
                  variant="inline"
                />
              )}
              <Divider />
            </Box>
            <Box marginBottom={4}>
              <Box marginBottom={5}>
                <Box marginBottom={1}>
                  <Typography variant="subtitle1">
                    Provide instructions to examiners
                  </Typography>
                </Box>
                <Typography>
                  You can customise your instructions according to your project.
                </Typography>
              </Box>
              <Grid container spacing={10}>
                <Grid item md={6}>
                  <Box marginBottom={1}>
                    <Typography variant="subtitle1">Subject experts</Typography>
                  </Box>
                  <TextField
                    helperText={
                      !areSubjectExpertInstructionsMinLength
                        ? "Must be at least three characters long"
                        : undefined
                    }
                    inputProps={{
                      "data-testid": "subject-expert-instructions-input"
                    }}
                    multiline={true}
                    onChange={handleChangeSubjectExpertInstructions}
                    validationType={
                      !areSubjectExpertInstructionsMinLength
                        ? "error"
                        : undefined
                    }
                    value={formFields.subjectExpertInstructions}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box marginBottom={1}>
                    <Typography variant="subtitle1">Approver</Typography>
                  </Box>
                  <TextField
                    helperText={
                      !areApproverInstructionsMinLength
                        ? "Must be at least three characters long"
                        : undefined
                    }
                    inputProps={{
                      "data-testid": "approver-instructions-input"
                    }}
                    multiline={true}
                    onChange={handleChangeApproverInstructions}
                    validationType={
                      !areApproverInstructionsMinLength ? "error" : undefined
                    }
                    value={formFields.approverInstructions}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Details;
