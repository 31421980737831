// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";

interface Props {
  isSaveAndContinueDisabled: boolean;
  onClickReject: () => void;
  onClickSaveAndContinue: () => void;
}

const InitialChoiceInteractionHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="initial-choice-interaction-header"
    >
      <Box>
        <Box marginBottom={1}>
          <Typography variant="h4" data-testid="instructions">
            Make sure the{" "}
            <span style={{ textDecoration: "underline" }}>content</span> matches
            the original item
          </Typography>
        </Box>
        <Typography>Click on text areas to make changes if needed.</Typography>
      </Box>
      <Box display="flex" marginLeft="auto">
        <Box marginRight={2}>
          <Button
            color="secondary"
            disableElevation
            onClick={props.onClickReject}
            variant="outlined"
            data-testid="reject-button"
          >
            Reject
          </Button>
        </Box>
        <Button
          color="primary"
          disableElevation
          disabled={props.isSaveAndContinueDisabled}
          onClick={props.onClickSaveAndContinue}
          data-testid="save-and-continue-button"
        >
          Save and continue
        </Button>
      </Box>
    </Box>
  );
};

export default InitialChoiceInteractionHeader;
