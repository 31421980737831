// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { IPossibleSharedStimulus } from "@qti-scraper/interfaces";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box } from "@material-ui/core";
import { AddCircle, RemoveCircle } from "@material-ui/icons";

interface Props {
  onClickAddOrRemoveStimulus: (
    stimulus: IPossibleSharedStimulus,
    action: "add" | "remove"
  ) => void;
  stimulus: IPossibleSharedStimulus;
  type: "added" | "available";
}

const PossibleSharedStimulus: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      data-testid={`${props.type}-possible-shared-stimulus`}
    >
      <Box clone marginRight={1}>
        <img src={props.stimulus.isolatedImage} />
      </Box>
      {props.type === "available" && (
        <AddCircle
          htmlColor={colours.corporateBlue}
          onClick={() =>
            props.onClickAddOrRemoveStimulus(props.stimulus, "add")
          }
          style={{ cursor: "pointer" }}
          data-testid="add-button"
        />
      )}
      {props.type === "added" && (
        <RemoveCircle
          htmlColor={colours.corporateBlue}
          onClick={() =>
            props.onClickAddOrRemoveStimulus(props.stimulus, "remove")
          }
          style={{ cursor: "pointer" }}
          data-testid="remove-button"
        />
      )}
    </Box>
  );
};

export default PossibleSharedStimulus;
