// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import ItemInfo from "components/itemInfo/ItemInfo";
import PdfPreview from "components/pdfPreview/PdfPreview";
import RejectionReasons from "pages/projects/completed/components/rejectionReasons/RejectionReasons";

// Interfaces
import {
  ChoiceInteraction as ChoiceInteractionInterface,
  Item
} from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { spaceCamelCaseString } from "utils/string";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { Check, ChevronLeft, Clear, Visibility } from "@material-ui/icons";
import LazyLoad from "react-lazyload";
import { useHistory, useParams } from "react-router-dom";

const Completed: FC = (): ReactElement => {
  const { getItems } = useApi();
  const { project } = useProject();
  const [items, setItems] = useState([] as Item[]);
  const [visiblePdfs, setVisiblePdfs] = useState([] as string[]);
  const history = useHistory();
  const { status } = useParams<Record<string, string>>();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getItems<Item[]>(project.key, {
      status: status === "banked" ? status : "rejectedByApprover",
      select:
        "additionalInformation,content,difficulty,job,key,metadata,project,rejectionExplanation,rejectionReasons,status,topics,skills",
      images: "true",
      pdfs: "true"
    }).then((response) => {
      setItems(response.data || []);
    }, console.error);
  }, [getItems, project, status]);

  const handleClickBack = (): void => {
    history.push(`/projects/${project.key}`);
  };

  const handleClickToggleViewOriginalItem = (itemKey: string): void => {
    setVisiblePdfs(
      visiblePdfs.includes(itemKey)
        ? visiblePdfs.filter((key) => key !== itemKey)
        : [...visiblePdfs, itemKey]
    );
  };

  const renderItem = (item: Item): ReactElement => {
    if (item.content.qtiInteractionType === "choiceInteraction") {
      return (
        <ChoiceInteraction
          content={item.content as ChoiceInteractionInterface}
          isEditing={false}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div data-testid={`${status}-page`}>
      <Box
        clone
        className={sharedClasses.bleed}
        marginTop="-47px"
        marginBottom={6}
        paddingY={5}
      >
        <Paper elevation={0}>
          <Box clone marginBottom={3}>
            <Button
              color="primary"
              onClick={handleClickBack}
              data-testid="back-button"
              startIcon={<ChevronLeft />}
              variant="text"
            >
              Back to project details
            </Button>
          </Box>
          <Box alignItems="center" display="flex" marginBottom={4}>
            <Box marginRight={6}>
              <Box marginBottom={1}>
                <Typography
                  component="h2"
                  variant="h3"
                  data-testid="project-name"
                >
                  {project.name}
                </Typography>
              </Box>
              <Typography
                component="h3"
                variant="h4"
                data-testid={`${status}-items`}
              >
                {spaceCamelCaseString(status)} items ({items.length})
              </Typography>
            </Box>
            <Box clone marginRight={2}>
              <Chip
                color="primary"
                icon={<Check fontSize="small" />}
                label={`Completed on ${project.completionDate}`}
                variant="outlined"
                data-testid="completion-date"
              />
            </Box>
            <Chip
              color={status === "banked" ? "primary" : "secondary"}
              label={`${spaceCamelCaseString(status)} items`}
              variant="outlined"
            />
          </Box>
        </Paper>
      </Box>
      <Grid container spacing={10}>
        {items.map((item, index) => (
          <React.Fragment key={item.key}>
            <Grid item xs={6}>
              <LazyLoad once>{renderItem(item)}</LazyLoad>
            </Grid>
            <Grid item xs={6}>
              <LazyLoad once>
                {visiblePdfs.includes(item.key) && (
                  <>
                    <Box alignItems="center" display="flex" marginBottom={2}>
                      <Box>
                        <Typography component="h2" variant="h5">
                          Original item
                        </Typography>
                      </Box>
                      <Box clone marginLeft="auto">
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            handleClickToggleViewOriginalItem(item.key)
                          }
                          data-testid="close-original-item-button"
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    </Box>
                    <PdfPreview url={item.isolatedImage} />
                  </>
                )}
                {!visiblePdfs.includes(item.key) && (
                  <>
                    <Box clone marginBottom={5}>
                      <Button
                        color="primary"
                        onClick={() =>
                          handleClickToggleViewOriginalItem(item.key)
                        }
                        data-testid="view-original-item-button"
                        startIcon={<Visibility />}
                        variant="text"
                      >
                        View original item
                      </Button>
                    </Box>
                    {status === "banked" && (
                      <ItemInfo isEditing={false} item={item} />
                    )}
                    {status === "rejected" && (
                      <RejectionReasons
                        rejectionExplanation={item.rejectionExplanation}
                        rejectionReasons={
                          (item.rejectionReasons as string[]) || []
                        }
                      />
                    )}
                  </>
                )}
              </LazyLoad>
            </Grid>
            {index !== items.length - 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default Completed;
