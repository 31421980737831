// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Tooltip as MuiTooltip, TooltipProps } from "@material-ui/core";
import { Help } from "@material-ui/icons";

interface Props {
  placement?: TooltipProps["placement"];
  title: string;
}

const Tooltip: FC<Props> = (props): ReactElement => {
  return (
    <Box clone marginLeft={1} data-testid="tooltip">
      <MuiTooltip
        arrow={true}
        placement={props.placement ? props.placement : "top"}
        title={props.title}
      >
        <span style={{ marginLeft: props.children ? "0" : "8px" }}>
          {props.children && <>{props.children}</>}
          {!props.children && (
            <Help
              fontSize="small"
              htmlColor={colours.monochromeMid}
              data-testid="help-icon"
            />
          )}
        </span>
      </MuiTooltip>
    </Box>
  );
};

export default Tooltip;
