// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Box, Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  rejectionExplanation?: string;
  rejectionReasons: string[];
}

const RejectionReasons: FC<Props> = (props): ReactElement => {
  return (
    <div data-testid="rejection-reasons">
      <Box marginBottom={2}>
        <Typography component="h2" variant="h5">
          Rejection reasons
        </Typography>
      </Box>
      {props.rejectionReasons.map((reason) => (
        <Box display="block" key={reason} marginBottom={1}>
          <Card elevation={0} style={{ display: "inline-block" }}>
            <CardContent style={{ padding: "12px 16px" }}>
              <Typography data-testid="rejection-reason">{reason}</Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
      {props.rejectionExplanation && (
        <Card elevation={0} style={{ display: "inline-block" }}>
          <CardContent style={{ padding: "12px 16px" }}>
            <Typography display="inline" style={{ fontWeight: 700 }}>
              Comments:{" "}
            </Typography>
            <Typography display="inline" data-testid="rejection-explanation">
              {props.rejectionExplanation}
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default RejectionReasons;
