// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, Typography } from "@material-ui/core";
import { Flag } from "@material-ui/icons";

interface Props {
  isOpen: boolean;
  onClickStartProject: () => void;
  setIsConfirmStartProjectDialogueOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ConfirmStartProjectDialogue: FC<Props> = (props): ReactElement => {
  const handleClickClose = (): void => {
    props.setIsConfirmStartProjectDialogueOpen(false);
  };

  return (
    <Dialog
      onClose={handleClickClose}
      aria-labelledby="confirm-start-project-dialogue-heading"
      open={props.isOpen}
      data-testid="confirm-start-project-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={1}>
          <Typography
            component="h2"
            variant="h4"
            data-testid="confirm-start-project-dialogue-heading"
          >
            Do you want to start the project?
          </Typography>
        </Box>
        <Typography>
          Once you start the project, all subject experts and approvers will be
          notified.
        </Typography>
        {/* TODO: Re-implement this once we've discussed pausing, delete and editing an "inProgress" project as a team (reassignments, adding/removing subject experts and approvers, etc). IH 23/04/21 */}
        {/* <Box marginBottom={2}>
          <Typography>
            Once you start the project, all subject experts and approvers will
            be notified. You can delete or pause the project at any time, but
            cannot recall notifications.
          </Typography>
        </Box>
        <Typography>
          You can also change subject experts and approvers while the project is
          in progress.
        </Typography> */}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" padding={3}>
        <Box clone marginRight={2}>
          <Button
            color="default"
            onClick={handleClickClose}
            variant="text"
            data-testid="cancel-start-project-button"
          >
            Cancel
          </Button>
        </Box>
        <Button
          color="primary"
          disableElevation
          onClick={props.onClickStartProject}
          startIcon={<Flag />}
          variant="contained"
          data-testid="start-project-button"
        >
          Start project
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmStartProjectDialogue;
