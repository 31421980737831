// Core
import React, { FC, ReactElement } from "react";

// Assets
import CIPapers from "assets/images/admin-onboarding/step-four-ci-papers.svg";
import OCRPapers from "assets/images/admin-onboarding/step-four-ocr-papers.svg";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Dialog,
  IconButton,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
}

const FileNamingConventionsDialogue: FC<Props> = (props): ReactElement => {
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Dialog
      aria-labelledby="file-naming-conventions-dialogue-heading"
      fullWidth={true}
      maxWidth="md"
      onClose={props.onClickClose}
      open={props.isOpen}
      data-testid="file-naming-conventions-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Box alignItems="center" display="flex" marginBottom={1}>
            <Typography
              component="h2"
              variant="h4"
              data-testid="file-naming-conventions-dialogue-heading"
            >
              Naming your files properly
            </Typography>
            <Box clone marginLeft="auto">
              <IconButton
                aria-label="close"
                onClick={props.onClickClose}
                data-testid="close-button"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Typography>
            All question papers and mark schemes must be named in the right way
            and in PDF format.
          </Typography>
        </Box>
        <Divider />
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isSmScreen ? "column" : "row"}
          justifyContent="center"
        >
          <Box
            marginBottom={isSmScreen ? 4 : 0}
            marginRight={isSmScreen ? 0 : 8}
          >
            <img alt="File naming - CI papers" src={CIPapers} />
          </Box>
          <Box>
            <img alt="File naming - OCR papers" src={OCRPapers} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FileNamingConventionsDialogue;
