// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";

interface Props {
  isApproveDisabled: boolean;
  onClickApprove: () => void;
  onClickReject: () => void;
}

const RejectedHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="rejected-header"
    >
      <Box data-testid="instructions">
        <Box marginBottom={1}>
          <Typography variant="h4">
            Make sure the{" "}
            <span style={{ textDecoration: "underline" }}>content</span> matches
            the original item
          </Typography>
        </Box>
        <Typography>Click on text areas to make changes if needed.</Typography>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="auto">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box marginRight={2}>
            <Button
              color="secondary"
              disableElevation
              onClick={props.onClickReject}
              variant="contained"
              data-testid="reject-button"
            >
              Reject item
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            disabled={props.isApproveDisabled}
            onClick={props.onClickApprove}
            variant="outlined"
            data-testid="approve-button"
          >
            Accept item and add info
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RejectedHeader;
