// Core
import React, { FC, ReactElement, useMemo } from "react";

// Interfaces
import {
  ExtendedTextOrTextEntryInteractionTaskStepperStep,
  Item
} from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Step, StepButton, Stepper } from "@material-ui/core";

interface Props {
  item: Item;
  onClickTaskStep: (status: Item["status"]) => void;
}

const ExtendedTextOrTextEntryInteractionTaskStepper: FC<Props> = (
  props
): ReactElement => {
  const steps = useMemo(
    () =>
      [
        {
          disabled: false,
          name: "Optional parts",
          status: "initial"
        },
        {
          disabled: props.item.status === "initial",
          name: "Content check",
          status: "sharedStimuliChecked"
        },
        {
          disabled:
            props.item.status === "initial" ||
            props.item.status === "sharedStimuliChecked",
          name: "Mark scheme check",
          status: "contentChecked"
        },
        {
          disabled:
            props.item.status === "initial" ||
            props.item.status === "sharedStimuliChecked" ||
            props.item.status === "contentChecked",
          name: "Add topics and skills",
          status: "markSchemeChecked"
        }
      ] as ExtendedTextOrTextEntryInteractionTaskStepperStep[],
    [props.item.status]
  );
  const sharedClasses = sharedStyles();

  const handleClickStep =
    (step: ExtendedTextOrTextEntryInteractionTaskStepperStep) => () => {
      if (step.disabled || props.item.status === step.status) {
        return;
      }

      props.onClickTaskStep(step.status);
    };

  return (
    <Box
      className={sharedClasses.bleed}
      marginBottom={4}
      marginTop={-4}
      style={{ backgroundColor: colours.white }}
      data-testid="extended-text-or-text-entry-interaction-task-stepper"
    >
      <Stepper nonLinear>
        {steps.map((step) => (
          <Step
            active={props.item.status === step.status}
            data-testid={`${step.name}-step`}
            disabled={step.disabled}
            key={step.name}
            onClick={handleClickStep(step)}
          >
            <StepButton>{step.name}</StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ExtendedTextOrTextEntryInteractionTaskStepper;
