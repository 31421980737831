// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ApproverTask from "pages/examinerDashboard/components/approverTask/ApproverTask";
import SubjectExpertTask from "pages/examinerDashboard/components/subjectExpertTask/SubjectExpertTask";

// Interfaces
import { UserOnboard } from "interfaces";
import { ITask } from "@qti-scraper/interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const ExaminerDashboard: FC = (): ReactElement => {
  const { createUser, getTasks, getUserOnboardStatus, user } = useApi();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [tasks, setTasks] = useState([] as ITask[]);
  const history = useHistory();

  useEffect(() => {
    getTasks<ITask[]>().then((response) => {
      setIsInitialLoad(false);
      setTasks(getActiveTasks(response.data || []));
    }, console.error);
  }, [getTasks]);

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  const getActiveTasks = (items: ITask[]): ITask[] => {
    const activeApproverTasks = items.filter(
      (item) =>
        item.type === "approver" &&
        item.completedItems.length < item.project.items
    );
    const activeSubjectExpertTasks = items.filter(
      (item) => item.type === "subject-expert" && item.pendingItems.length > 0
    );

    return activeApproverTasks.concat(activeSubjectExpertTasks);
  };

  const getTasksCount = (taskType: ITask["type"]): number => {
    return tasks.filter((task) => task.type === taskType).length;
  };

  const handleClickStartTask = (
    taskKey: string,
    role: "approver" | "subjectExpert",
    itemKey?: string
  ): void => {
    const slug = role === "approver" ? "approver" : "subject-expert";

    getUserOnboardStatus<UserOnboard>(role)
      .then((response) => {
        history.push(
          response.data?.onboarded
            ? `/${slug}/tasks/${taskKey}/items/${itemKey}`
            : {
                pathname: `/${slug}/onboarding`,
                state: {
                  taskUrl: `/${slug}/tasks/${taskKey}/items/${itemKey}`
                }
              }
        );
      })
      .catch((error) => {
        if (error.data.extra === "User was not found") {
          createUser(role).then(() =>
            history.push({
              pathname: `/${slug}/onboarding`,
              state: {
                taskUrl: `/${slug}/tasks/${taskKey}/items/${itemKey}`
              }
            })
          );
        }
      });
  };

  const handleClickViewOnboarding = (
    role: "approver" | "subjectExpert"
  ): void => {
    const slug = role === "approver" ? "approver" : "subject-expert";

    history.push(`/${slug}/onboarding`);
  };

  return (
    <div data-testid="examiner-dashboard-page">
      <Box alignItems="center" display="flex" marginBottom={4}>
        <Typography variant="h3" data-testid="page-heading">
          Your tasks
        </Typography>
        <Box marginLeft="auto">
          {getTasksCount("subject-expert") > 0 && (
            <Box clone marginRight={getTasksCount("approver") ? 2 : 0}>
              <Button
                color="primary"
                disableElevation
                onClick={() => handleClickViewOnboarding("subjectExpert")}
                startIcon={<Visibility />}
                variant="outlined"
                data-testid="view-subject-expert-onboarding-button"
              >
                View Subject Expert onboarding
              </Button>
            </Box>
          )}
          {getTasksCount("approver") > 0 && (
            <Button
              color="primary"
              disableElevation
              onClick={() => handleClickViewOnboarding("approver")}
              startIcon={<Visibility />}
              variant="outlined"
              data-testid="view-approver-onboarding-button"
            >
              View Approver onboarding
            </Button>
          )}
        </Box>
      </Box>
      <Box data-testid="tasks">
        {isInitialLoad && (
          <Box alignItems="center" display="flex">
            <Box clone marginRight={1}>
              <CircularProgress color="inherit" size={18} />
            </Box>
            <Typography>Loading tasks...</Typography>
          </Box>
        )}
        {!isInitialLoad && tasks.length === 0 && (
          <Typography>
            You don&rsquo;t have any tasks assigned to you.
          </Typography>
        )}
        {tasks.map((task) => (
          <React.Fragment key={task.key}>
            {task.type === "approver" && (
              <ApproverTask
                key={task.key}
                onClickStart={handleClickStartTask}
                task={task}
              />
            )}
            {task.type === "subject-expert" && (
              <SubjectExpertTask
                key={task.key}
                onClickStart={handleClickStartTask}
                task={task}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </div>
  );
};

export default ExaminerDashboard;
