// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import Initial from "pages/projects/upload/components/initial/Initial";
import Summary from "pages/projects/upload/components/summary/Summary";

// Interfaces
import { ISubjectInfo } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import { Box, CircularProgress } from "@material-ui/core";

const Upload: FC = (): ReactElement => {
  const { getSubjectInfo } = useApi();
  const { project } = useProject();
  const [activeStep, setActiveStep] = useState(
    "initial" as "initial" | "summary"
  );
  const [invalidFiles, setInvalidFiles] = useState({
    existing: [],
    invalid: [],
    rejected: []
  } as { existing: File[]; invalid: File[]; rejected: File[] });
  const [subjectInfo, setSubjectInfo] = useState({} as ISubjectInfo);
  const [validFiles, setValidFiles] = useState([] as File[]);

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getSubjectInfo<ISubjectInfo[]>().then((response) => {
      setSubjectInfo(
        response.data?.filter(
          (item) => item.syllabusCode === project.syllabusCode
        )[0] as ISubjectInfo
      );
    });
  }, [getSubjectInfo, project]);

  return (
    <div data-testid="upload-page">
      {Object.keys(subjectInfo).length === 0 && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {Object.keys(subjectInfo).length > 0 && (
        <>
          {activeStep === "initial" && (
            <Initial
              businessUnit={subjectInfo.businessUnit}
              setActiveStep={setActiveStep}
              setInvalidFiles={setInvalidFiles}
              setValidFiles={setValidFiles}
            />
          )}
          {activeStep === "summary" && (
            <Summary
              businessUnit={subjectInfo.businessUnit}
              invalidFiles={invalidFiles}
              setActiveStep={setActiveStep}
              setInvalidFiles={setInvalidFiles}
              setValidFiles={setValidFiles}
              validFiles={validFiles}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Upload;
