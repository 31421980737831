// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Assets
import initialFigure from "assets/images/approver-onboarding/initial-figure.svg";
import stepOneApproverTask from "assets/images/approver-onboarding/step-one-approver-task.svg";
import stepOneArrow from "assets/images/approver-onboarding/step-one-arrow.svg";
import stepOneArrows from "assets/images/approver-onboarding/step-one-arrows.svg";
import stepOneSubjectExpertTasks from "assets/images/approver-onboarding/step-one-subject-expert-tasks.svg";
import stepOnePdf from "assets/images/approver-onboarding/step-one-pdf.svg";
import stepTwoArrows from "assets/images/approver-onboarding/step-two-arrows.svg";
import stepTwoPdf from "assets/images/approver-onboarding/step-two-pdf.svg";
import stepTwoItem from "assets/images/approver-onboarding/step-two-item.svg";
import stepThreeApproverActions from "assets/images/approver-onboarding/step-three-approver-actions.svg";
import stepThreeArrow from "assets/images/approver-onboarding/step-three-arrow.svg";
import stepThreeItemRejected from "assets/images/approver-onboarding/step-three-item-rejected.svg";
import stepFourApproverActions from "assets/images/approver-onboarding/step-four-approver-actions.svg";
import stepFourArrow from "assets/images/approver-onboarding/step-four-arrow.svg";
import stepFourItemEdited from "assets/images/approver-onboarding/step-four-item-edited.svg";
import stepFiveArrow from "assets/images/approver-onboarding/step-five-arrow.svg";
import stepFivePdf from "assets/images/approver-onboarding/step-five-pdf.svg";
import stepFiveItemEditing from "assets/images/approver-onboarding/step-five-item-editing.svg";
import stepSixItemEditing from "assets/images/approver-onboarding/step-six-item-editing.svg";
import stepSevenArrow from "assets/images/approver-onboarding/step-seven-arrow.svg";
import stepSevenItem from "assets/images/approver-onboarding/step-seven-item.svg";
import stepSevenTopicsSkills from "assets/images/approver-onboarding/step-seven-topics-skills.svg";
import stepEightArrow from "assets/images/approver-onboarding/step-eight-arrow.svg";
import stepEightBanking from "assets/images/approver-onboarding/step-eight-banking.svg";

// Components
import OnboardingStepHeader from "components/onboardingStepHeader/OnboardingStepHeader";
import OnboardingStepNavigation from "components/onboardingStepNavigation/OnboardingStepNavigation";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { StaticContext } from "react-router";
import { RouteComponentProps, useHistory } from "react-router-dom";

interface LocationState {
  taskUrl?: string;
}

const ApproverOnboarding: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (props): ReactElement => {
  const { updateUser } = useApi();
  const [activeStep, setActiveStep] = useState(0);
  const [taskUrl, setTaskUrl] = useState("");
  const history = useHistory();
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    document.body.classList.add("onboarding");

    return () => {
      document.body.classList.remove("onboarding");
    };
  }, []);

  useEffect(() => {
    if (!props.location.state || !props.location.state.taskUrl) {
      return;
    }

    setTaskUrl(props.location.state.taskUrl);
  }, [props]);

  const handleClickComplete = (): void => {
    updateUser("approver", true).then(() => {
      history.push(taskUrl ? taskUrl : "/examiner/dashboard");
    });
  };

  const handleClickDot = (index: number): void => {
    setActiveStep(index);
  };

  const handleClickNext = (): void => {
    setActiveStep(activeStep + 1);
  };

  const handleClickPrevious = (): void => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div data-testid="approver-onboarding-page">
      {activeStep === 0 && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isSmScreen ? "column" : "row"}
          paddingX={isSmScreen ? 0 : 12}
        >
          <Box
            clone
            marginBottom={isSmScreen ? 12 : 0}
            marginRight={isSmScreen ? 0 : 12}
          >
            <img alt="Two people having a conversation" src={initialFigure} />
          </Box>
          <Box>
            <Box marginBottom={4}>
              <Typography variant="h2">Welcome,</Typography>
            </Box>
            <Box marginBottom={12}>
              <Typography component="p" variant="h5">
                Before you start, let us guide you through our tool.
              </Typography>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickNext}
              data-testid="continue-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
      {activeStep !== 0 && (
        <Box marginBottom={20} data-testid={`step-${activeStep}`}>
          {activeStep === 1 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Subject Experts will review and handover the items to you
                    for banking. So{" "}
                    <span style={{ textDecoration: "underline" }}>
                      it may take some time
                    </span>{" "}
                    to get all the items on a project.
                  </>
                }
                chipLabel="Receiving items"
                heading="Receiving the submitted items"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 2}
                >
                  <img
                    alt="Subject expert tasks"
                    src={stepOneSubjectExpertTasks}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={4}>
                    <img alt="Arrows" src={stepOneArrows} />
                  </Box>
                )}
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 4}
                >
                  <img alt="Question paper PDF" src={stepOnePdf} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={4}>
                    <img alt="Arrow" src={stepOneArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="Approver task" src={stepOneApproverTask} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    You are the{" "}
                    <span style={{ textDecoration: "underline" }}>
                      last person
                    </span>{" "}
                    who will see the items before they are banked, so make sure
                    that everything is correct and sits in the right place.
                  </>
                }
                chipLabel="Content review"
                heading="Double-check the items"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 9}
                >
                  <img alt="QTI rendering in HTML" src={stepTwoItem} />
                </Box>
                {isSmScreen && (
                  <Box marginBottom={4} marginTop={-12}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                    <ArrowUpward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={9}>
                    <img alt="Arrows" src={stepTwoArrows} />
                  </Box>
                )}
                <Box>
                  <img alt="Question paper PDF" src={stepTwoPdf} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 3 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Subject Experts{" "}
                    <span style={{ textDecoration: "underline" }}>
                      may reject items
                    </span>
                    , and you will still receive them.
                    <br />
                    If you agree with the Subject expert, then simply reject the
                    item and it will not be banked.
                  </>
                }
                chipLabel="Rejected items"
                heading="Check the decision"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 7}
                >
                  <img
                    alt="An item rejected by the Subject expert"
                    src={stepThreeItemRejected}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-6}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={7}>
                    <img alt="Arrow" src={stepThreeArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="Approver actions" src={stepThreeApproverActions} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 4 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Subject Experts{" "}
                    <span style={{ textDecoration: "underline" }}>
                      may edit items
                    </span>{" "}
                    to correct a problem before they submit.
                    <br /> If everything looks good, simply save and continue.
                  </>
                }
                chipLabel="Edited items"
                heading="Check the status"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 7}
                >
                  <img
                    alt="An item edited by the Subject expert"
                    src={stepFourItemEdited}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-6}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={7}>
                    <img alt="Arrow" src={stepFourArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="Approver actions" src={stepFourApproverActions} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 5 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Subject Experts{" "}
                    <span style={{ textDecoration: "underline" }}>
                      may miss
                    </span>{" "}
                    a text or styling issue.
                    <br /> When you spot one, you need to edit it.
                  </>
                }
                chipLabel="Proof reading"
                heading="Check the text and styling"
                marginBottom={2}
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 2}
                >
                  <img alt="Editing an item" src={stepFiveItemEditing} />
                </Box>
                {isSmScreen && (
                  <Box marginBottom={4} marginTop={-2}>
                    <ArrowUpward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={4}>
                    <img alt="Arrow" src={stepFiveArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="Question paper PDF" src={stepFivePdf} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 6 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Subject Experts{" "}
                    <span style={{ textDecoration: "underline" }}>
                      may miss out
                    </span>{" "}
                    an image or diagram issue.
                    <br /> When you spot one, you need to edit it.
                  </>
                }
                chipLabel="Figures"
                heading="Check the images and diagrams"
              />
              <Box display="flex" justifyContent="center">
                <img alt="Editing an item" src={stepSixItemEditing} />
              </Box>
            </>
          )}
          {activeStep === 7 && (
            <>
              <OnboardingStepHeader
                body="Subject Experts add the most relevant topics and skills. Review those before banking the item. You can also add or remove."
                chipLabel="Topics and skills"
                heading="Review the taxonomies"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 2}
                >
                  <img alt="QTI rendering in HTML" src={stepSevenItem} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-6}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={2}>
                    <img alt="Arrow" src={stepSevenArrow} />
                  </Box>
                )}
                <Box>
                  <img
                    alt="Adding topics and skills"
                    src={stepSevenTopicsSkills}
                  />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 8 && (
            <>
              <OnboardingStepHeader
                body="Cambridge Assessment holds highly valuable content in it archives. Now it's time to prepare that content for the future."
                chipLabel="Banking"
                heading="Your job is to bank items"
                marginBottom={2}
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box marginBottom={-1} zIndex={1}>
                  <img alt="Arrow" src={stepEightArrow} />
                </Box>
                <Box>
                  <img alt="Banking an item" src={stepEightBanking} />
                </Box>
                <Typography>
                  You can always access this onboarding content later from your
                  dashboard
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
      {activeStep > 0 && (
        <OnboardingStepNavigation
          activeStep={activeStep}
          isSmScreen={isSmScreen}
          onClickComplete={handleClickComplete}
          onClickDot={handleClickDot}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
          stepCount={8}
          userRole="examiner"
        />
      )}
    </div>
  );
};

export default ApproverOnboarding;
