// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Vendor
import { Box } from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  index?: number;
  isEditing: boolean;
  isShared?: boolean;
  onChange: (value: string) => void;
  stimulus: string;
}

const Stimulus: FC<Props> = (props): ReactElement => {
  return (
    <Box data-testid={props.isShared ? "shared-stimulus" : "stimulus"}>
      {props.isEditing && (
        <Editor
          allowImages={true}
          allowLists={true}
          allowTables={true}
          onChange={props.onChange}
          value={props.stimulus}
          testId={
            props.isShared
              ? `shared-stimulus-${props.index ? props.index : 0}-editor`
              : "stimulus-editor"
          }
        />
      )}
      {!props.isEditing && (
        <div className="read-only" data-testid="stimulus-content">
          <MathJax
            math={props.stimulus}
            sanitizeOptions={{
              ADD_ATTR: ["colspan", "rowspan", "src"],
              ADD_TAGS: [
                "b",
                "br",
                "i",
                "img",
                "li",
                "ol",
                "p",
                "span",
                "sub",
                "sup",
                "table",
                "tbody",
                "td",
                "th",
                "thead",
                "tr",
                "u",
                "ul"
              ]
            }}
            wrapperTag="span"
          />
        </div>
      )}
    </Box>
  );
};

export default Stimulus;
