// Core
import React, { FC, ReactElement, useEffect } from "react";

// Interfaces
import { UserOnboard } from "interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { useHistory } from "react-router-dom";

const LoginRedirect: FC = (): ReactElement => {
  const { createUser, getUserOnboardStatus, user } = useApi();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.role === "admin") {
      getUserOnboardStatus<UserOnboard>("admin")
        .then((response) => {
          history.push(
            response.data?.onboarded ? "/admin/dashboard" : "/admin/onboarding"
          );
        })
        .catch((error) => {
          if (error.data.extra === "User was not found") {
            createUser("admin").then(() => history.push("/admin/onboarding"));
          }
        });

      return;
    }

    if (user.role === "examiner") {
      history.push("/examiner/dashboard");

      return;
    }

    history.push("/unauthorised");
  }, [createUser, getUserOnboardStatus, history, user]);

  return <></>;
};

export default LoginRedirect;
