// Core
import React, { FC, ReactElement, useState } from "react";

// Components
import FileNamingConventionsDialogue from "pages/projects/upload/components/uploadHeader/components/fileNamingConventionsDialogue/FileNamingConventionsDialogue";
import Tooltip from "components/tooltip/Tooltip";

// Vendor
import { Box, Typography } from "@material-ui/core";

interface Props {
  actions?: ReactElement;
  syllabusCode: string;
}

const UploadHeader: FC<Props> = (props): ReactElement => {
  const [
    isFileNamingConventionsDialogueOpen,
    setIsFileNamingConventionsDialogueOpen
  ] = useState(false);

  const handleClickViewFileNamingConventions = (): void => {
    setIsFileNamingConventionsDialogueOpen(true);
  };

  return (
    <Box display="flex" marginBottom={4} data-testid="upload-header">
      <FileNamingConventionsDialogue
        isOpen={isFileNamingConventionsDialogueOpen}
        onClickClose={() => setIsFileNamingConventionsDialogueOpen(false)}
      />
      <Box>
        <Box display="flex" marginBottom={1}>
          <Typography component="h2" variant="h4" data-testid="page-heading">
            Upload question papers and mark schemes for {props.syllabusCode}
          </Typography>
          <Tooltip title="To upload papers for another syllabus please go back to the previous step and change your code." />
        </Box>
        <Typography data-testid="page-introduction">
          All the files must be in PDF format and named accordingly. View our
          guidelines{" "}
          <span
            onClick={handleClickViewFileNamingConventions}
            style={{ cursor: "pointer", textDecoration: "underline" }}
            data-testid="view-file-naming-conventions-button"
          >
            here
          </span>
          .
        </Typography>
      </Box>
      {props.actions && <Box marginLeft="auto">{props.actions}</Box>}
    </Box>
  );
};

export default UploadHeader;
