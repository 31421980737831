// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, Typography } from "@material-ui/core";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  subjectExperts: IProject["subjectExperts"];
}

const AllocateItemsDialogue: FC<Props> = (props): ReactElement => {
  const sharedClasses = sharedStyles();

  return (
    <Dialog
      onClose={props.onClickClose}
      open={props.isOpen}
      data-testid="allocate-items-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={1}>
          <Typography component="h2" variant="h4">
            You need to allocate items to each examiner
          </Typography>
        </Box>
        <Typography>
          You need to allocate items to each examiner or remove the idle
          examiner to continue.
        </Typography>
      </Box>
      <Divider />
      <Box padding={3}>
        {props.subjectExperts.map((subjectExpert, index) => (
          <Box
            display="flex"
            key={subjectExpert.sub}
            marginBottom={index === props.subjectExperts.length - 1 ? 0 : 1}
          >
            <Box marginRight={10}>
              {index === 0 && (
                <Box marginBottom={1}>
                  <Typography
                    className={sharedClasses.fadedText}
                    component="h4"
                    variant="subtitle1"
                  >
                    Subject expert
                  </Typography>
                </Box>
              )}
              <Typography component="div" variant="h5">
                {subjectExpert.email}
              </Typography>
            </Box>
            <Box>
              {index === 0 && (
                <Box marginBottom={1}>
                  <Typography
                    className={sharedClasses.fadedText}
                    component="h4"
                    variant="subtitle1"
                  >
                    Items allocated
                  </Typography>
                </Box>
              )}
              <Typography component="div" variant="h5">
                0
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" padding={3}>
        <Button
          color="primary"
          disableElevation
          onClick={props.onClickClose}
          variant="contained"
          data-testid="back-button"
        >
          Go back
        </Button>
      </Box>
    </Dialog>
  );
};

export default AllocateItemsDialogue;
