// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import ChoiceInteractionTaskStepper from "components/choiceInteractionTaskStepper/ChoiceInteractionTaskStepper";
import ContentCheckedChoiceInteractionHeader from "pages/approverTask/components/contentCheckedChoiceInteractionHeader/ContentCheckedChoiceInteractionHeader";
import ItemInfo from "components/itemInfo/ItemInfo";
import Message from "components/message/Message";
import MetadataAddedChoiceInteractionHeader from "pages/approverTask/components/metadataAddedChoiceInteractionHeader/MetadataAddedChoiceInteractionHeader";
import PdfPreview from "components/pdfPreview/PdfPreview";
import RejectContentDialogue from "components/rejectContentDialogue/RejectContentDialogue";
import RejectedHeader from "pages/approverTask/components/rejectedHeader/RejectedHeader";
import RejectionReasonsDialogue from "pages/approverTask/components/rejectionReasonsDialogue/RejectionReasonsDialogue";

// Interfaces
import {
  ChoiceInteraction as ChoiceInteractionInterface,
  Item,
  Message as MessageInterface
} from "interfaces";
import { ResponseItem } from "@qti-scraper/interfaces";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Grid, Typography } from "@material-ui/core";

interface Props {
  isAccepted: boolean;
  isBanking: boolean;
  item: Item;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickConfirmReject: (body: any) => void;
  onClickContinueFromContent: () => void;
  onClickContinueFromMetadata: () => void;
  onClickTaskStep: (status: Item["status"]) => void;
  onSaveContent: (
    content: {
      prompt: string;
      stimulus: string;
      responses: ResponseItem[];
    },
    hasUnsavedChanges: boolean
  ) => void;
  onSaveMetadata: (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ) => void;
  projectKey: string;
  shouldSaveContent: boolean;
  shouldSaveMetadata: boolean;
}

const ChoiceInteractionTask: FC<Props> = (props): ReactElement => {
  const [hasMandatoryMetadata, setHasMandatoryMetadata] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isRejectContentDialogueOpen, setIsRejectContentDialogueOpen] =
    useState(false);
  const [isRejectionReasonsDialogueOpen, setIsRejectionReasonsDialogueOpen] =
    useState(false);
  const [messages, setMessages] = useState([] as MessageInterface[]);

  useEffect(() => {
    setIsEditing(
      props.item.status === "rejectedBySubjectExpert" ||
        (props.item.status === "metadataAdded" && !props.isAccepted)
        ? true
        : false
    );
  }, [props.isAccepted, props.item.status]);

  const handleChangeMandatoryMetadata = (value: boolean): void => {
    setHasMandatoryMetadata(value);
  };

  const handleClickReject = (): void => {
    setIsRejectContentDialogueOpen(true);
  };

  const handleClickViewRejectionReasons = (): void => {
    setIsRejectionReasonsDialogueOpen(true);
  };

  return (
    <div data-testid="choice-interaction-task">
      <RejectContentDialogue
        isOpen={isRejectContentDialogueOpen}
        onClickClose={() => setIsRejectContentDialogueOpen(false)}
        onClickConfirmReject={props.onClickConfirmReject}
      />
      <RejectionReasonsDialogue
        isOpen={isRejectionReasonsDialogueOpen}
        onClickClose={() => setIsRejectionReasonsDialogueOpen(false)}
        rejectionExplanation={props.item.rejectionExplanation}
        rejectionReasons={(props.item.rejectionReasons as string[]) || []}
      />
      <ChoiceInteractionTaskStepper
        isAddTopicsAndSkillsDisabled={
          messages.filter((message) => message.level === "error").length > 0
        }
        item={props.item}
        onClickTaskStep={props.onClickTaskStep}
      />
      <Box alignItems="start" display="flex" marginBottom={4}>
        {props.item.status === "metadataAdded" && (
          <>
            <MetadataAddedChoiceInteractionHeader
              isAccepted={props.isAccepted}
              isBankDisabled={!hasMandatoryMetadata || props.isBanking}
              isSaveAndContinueDisabled={
                messages.filter((message) => message.level === "error").length >
                0
              }
              onClickReject={handleClickReject}
              onClickSaveAndContinue={props.onClickContinueFromContent}
              onClickSaveMetadata={props.onClickContinueFromMetadata}
            />
          </>
        )}
        {props.item.status === "rejectedBySubjectExpert" && (
          <RejectedHeader
            isApproveDisabled={
              messages.filter((message) => message.level === "error").length > 0
            }
            onClickApprove={props.onClickContinueFromContent}
            onClickReject={() => {
              const body = {
                rejectionReasons: props.item.rejectionReasons
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any;

              if (props.item.rejectionExplanation) {
                body["rejectionExplanation"] = props.item.rejectionExplanation;
              }

              props.onClickConfirmReject(body);
            }}
          />
        )}
        {props.item.status === "contentChecked" && (
          <ContentCheckedChoiceInteractionHeader
            isBankDisabled={!hasMandatoryMetadata || props.isBanking}
            onClickBank={props.onClickContinueFromMetadata}
            onClickReject={handleClickReject}
          />
        )}
      </Box>
      <Divider />
      {props.item.status === "contentChecked" && (
        <>
          <Message
            body="Because this item was rejected by the subject expert, no item information was provided."
            heading="No data provided"
            level="information"
          />
        </>
      )}
      {props.item.status === "metadataAdded" && (
        <>
          {props.isAccepted && (
            <Message
              body=""
              heading="Item information was provided by the subject expert"
              level="success"
            />
          )}
          {!props.isAccepted && props.item.content.edited && (
            <Message
              body=""
              heading="This item was edited and accepted by the subject expert"
              level="warning"
            />
          )}
          {!props.isAccepted && !props.item.content.edited && (
            <Message
              body=""
              heading="This item was checked and accepted by the subject expert"
              level="success"
            />
          )}
        </>
      )}
      {props.item.status === "rejectedBySubjectExpert" && (
        <>
          <Message
            body={
              <span
                onClick={handleClickViewRejectionReasons}
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                View rejection reasons
              </span>
            }
            heading="This item was checked and rejected by the subject expert"
            level="error"
          />
        </>
      )}
      {(props.item.status === "contentChecked" ||
        (props.item.status === "metadataAdded" && !props.isAccepted) ||
        props.item.status === "rejectedBySubjectExpert") && (
        <>
          {messages.map((message) => (
            <Message
              body={message.body}
              heading={message.heading}
              key={message.body}
              level={message.level}
            />
          ))}
        </>
      )}
      <Box marginTop={5}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            {!isEditing && (
              <Box marginBottom={2}>
                <Typography component="h2" variant="h5">
                  Extracted item
                </Typography>
              </Box>
            )}
            <ChoiceInteraction
              content={props.item.content as ChoiceInteractionInterface}
              initialRagDetails={props.item.initialRagDetails}
              isEditing={isEditing}
              itemKey={props.item.key}
              onSaveContent={props.onSaveContent}
              setMessages={setMessages}
              shouldSaveContent={props.shouldSaveContent}
            />
          </Grid>
          <Grid item xs={6}>
            {((props.item.status === "metadataAdded" && !props.isAccepted) ||
              props.item.status === "rejectedBySubjectExpert") && (
              <>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Original item
                  </Typography>
                </Box>
                <PdfPreview url={props.item.isolatedImage} />
              </>
            )}
            {((props.item.status === "metadataAdded" && props.isAccepted) ||
              props.item.status === "contentChecked") && (
              <ItemInfo
                isEditing={true}
                item={props.item}
                onChangeMandatoryMetadata={handleChangeMandatoryMetadata}
                onSaveMetadata={props.onSaveMetadata}
                projectKey={props.projectKey}
                shouldSaveMetadata={props.shouldSaveMetadata}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ChoiceInteractionTask;
