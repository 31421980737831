// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { EditProjectFormFields } from "interfaces";

// Vendor
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  makeStyles,
  Radio,
  Typography
} from "@material-ui/core";

interface Props {
  isActive: boolean;
  onClickProjectType: (type: EditProjectFormFields["projectType"]) => void;
  projectType: EditProjectFormFields["projectType"];
}

const ProjectType: FC<Props> = (props): ReactElement => {
  const projectTypes = {
    "harvesting-past-papers": {
      body: "Extracts the assessment content from past QPs and MSs, and repurposes them to create an item bank",
      chipLabel: "",
      heading: "Harvesting (Multiple-choice only)"
    },
    "harvesting-on-screen": {
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida adipiscing velit faucibus nec.",
      chipLabel: "Not available yet",
      heading: "Harvesting (on-screen)"
    },
    authoring: {
      body: "Creates multiple choice question items with media to save into an item bank",
      chipLabel: "Not available yet",
      heading: "Authoring"
    }
  };
  const useStyles = makeStyles({
    disabledProjectType: {
      opacity: 0.5,
      pointerEvents: "none"
    },
    projectType: {
      cursor: "pointer"
    }
  });
  const classes = useStyles();

  return (
    <Box
      clone
      // TODO: Remove the display none from harvesting-on-screen when we are ready for it. IH 10/05/21
      display={props.projectType === "harvesting-on-screen" ? "none" : "block"}
      marginBottom={4}
      position="relative"
    >
      <Card
        className={`
          ${classes.projectType}
          ${
            props.projectType === "harvesting-past-papers"
              ? ""
              : classes.disabledProjectType
          }
        `}
        onClick={() => {
          if (props.projectType !== "harvesting-past-papers") {
            return;
          }

          props.onClickProjectType(props.projectType);
        }}
        data-testid={`${props.projectType}-project-type`}
      >
        {projectTypes[props.projectType].chipLabel && (
          <Box position="absolute" top={10} right={10}>
            <Chip
              color="default"
              label={projectTypes[props.projectType].chipLabel}
            />
          </Box>
        )}
        <CardContent>
          <Box alignItems="center" display="flex">
            <Box marginRight={2}>
              <Radio
                checked={props.isActive}
                color="primary"
                name={`${props.projectType}-radio`}
                inputProps={
                  {
                    "aria-label": `${projectTypes[props.projectType].heading}`,
                    "data-testid": `${props.projectType}-radio`
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } as any
                }
              />
            </Box>
            <Box>
              <Box marginBottom={1}>
                <Typography variant="subtitle1">
                  {projectTypes[props.projectType].heading}
                </Typography>
              </Box>
              <Grid item md={8}>
                <Typography>{projectTypes[props.projectType].body}</Typography>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProjectType;
