// Enums
import { MessageBody, MessageHeading } from "enums";
import { IRagDetails } from "@qti-scraper/interfaces";

const messageLevel = new Map<
  IRagDetails["status"],
  "information" | "warning"
>();

messageLevel.set("G", "information");
messageLevel.set("A", "warning");

const messageInfo = new Map<string, { body: string; heading: string }>();
messageInfo.set("Missing stimulus", {
  body: MessageBody.noStimulus,
  heading: MessageHeading.noStimulus
});
messageInfo.set("Missing choices", {
  body: MessageBody.incorrectNumberOfResponses,
  heading: MessageHeading.incorrectNumberOfResponses
});
messageInfo.set("Missing prompt", {
  body: MessageBody.noPrompt,
  heading: MessageHeading.noPrompt
});
messageInfo.set("Missing item content", {
  body: MessageBody.noItemContent,
  heading: MessageHeading.noItemContent
});
messageInfo.set("Missing styling information", {
  body: MessageBody.missingStyling,
  heading: MessageHeading.missingStyling
});
messageInfo.set("No Unicode mapping", {
  body: MessageBody.missingCharacters,
  heading: MessageHeading.missingCharacters
});
messageInfo.set("Possibly incorrect table contents", {
  body: MessageBody.incorrectTable,
  heading: MessageHeading.incorrectTable
});

export const getMessageInfo = (
  key: string
): { body: string; heading: string } => {
  let info = messageInfo.get(key);

  if (!info) {
    info = {
      body: key,
      heading: "Alert"
    };
  }

  return info;
};

export const getMessageLevel = (
  key: IRagDetails["status"]
): "information" | "warning" => {
  let level = messageLevel.get(key);

  if (!level) {
    // log an error if this happens because it means that Java has sent us something other than "G" or "A", which it shouldn't
    console.error(
      "Key value '" +
        key +
        "' not recognised for the message level. Setting to Warning by default."
    );

    level = "warning";
  }

  return level;
};
