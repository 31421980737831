// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { createThemeOptions } from "utils/theme";
import { useDebouncedEffect } from "utils/useDebouncedEffect";

// Vendor
import {
  Button,
  TextField,
  themeOptions
} from "@cambridgeassessment/cambridge-ui";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

interface Props {
  onClickDelete: (email: string) => void;
  subjectExperts: IProject["subjectExperts"];
  unassignedItemsCount: number;
  updateAssignedItems: (email: string, assignedItems: number) => void;
}

const SubjectExpertsTable: FC<Props> = (props): ReactElement => {
  const [formFields, setFormFields] = useState({
    assignedItems: {} as { [key: string]: string }
  });
  const [newAssignment, setNewAssignment] = useState(
    {} as { email: string; newNumber: number; oldNumber: number }
  );
  const baseThemeOptions = createThemeOptions(themeOptions);

  const getMuiTheme = () =>
    createTheme({
      ...baseThemeOptions,
      overrides: {
        ...baseThemeOptions.overrides,
        MuiInput: {
          root: {
            margin: "auto",
            width: "100px"
          }
        },
        MuiTableCell: {
          ...baseThemeOptions.overrides?.MuiTableCell,
          body: {
            width: "33%"
          }
        }
      }
    });

  const columns = [
    {
      name: "email",
      label: "Subject expert",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "email",
      label: "Item allocation",
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string): ReactElement => {
          return (
            <TextField
              inputProps={{
                "data-testid": "assigned-items-input"
              }}
              onChange={(event) => changeAssignedItems(event, value)}
              value={formFields.assignedItems[value] || ""}
            />
          );
        }
      }
    },
    {
      name: "email",
      label: "Actions",
      options: {
        empty: true,
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string): ReactElement => {
          return (
            <Button
              color="primary"
              onClick={() => props.onClickDelete(value)}
              variant="text"
              data-testid="delete-button"
            >
              Remove subject expert
            </Button>
          );
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    customToolbar: null,
    download: false,
    filter: false,
    pagination: false,
    print: false,
    responsive: "vertical",
    search: false,
    selectableRows: "none",
    selectToolbarPlacement: "none",
    viewColumns: false
    // TODO: I have to do this because the @types for mui-datables are out of date, and the valid customToolbar: null declaration above is reported as an error. When the types are updated, we can remove this casting. IH 24/02/21
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  useDebouncedEffect(
    () => {
      if (isNaN(newAssignment.newNumber)) {
        return;
      }

      if (
        newAssignment.newNumber - newAssignment.oldNumber >
        props.unassignedItemsCount
      ) {
        props.updateAssignedItems(
          newAssignment.email,
          newAssignment.oldNumber + props.unassignedItemsCount
        );
      } else {
        props.updateAssignedItems(newAssignment.email, newAssignment.newNumber);
      }
    },
    1000,
    [newAssignment]
  );

  useEffect(() => {
    if (!props.subjectExperts) {
      return;
    }

    setFormFields({
      assignedItems: props.subjectExperts.reduce(
        (previousValue, currentValue) => {
          previousValue[currentValue.email] =
            currentValue.assignedItems.toString();

          return previousValue;
        },
        {} as { [key: string]: string }
      )
    });
  }, [props.subjectExperts]);

  const changeAssignedItems = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    email: string
  ): void => {
    if (new RegExp("[^0-9]+").test(event.target.value)) {
      return;
    }

    setNewAssignment({
      email,
      newNumber: parseInt(event.target.value, 10),
      oldNumber: props.subjectExperts.filter((item) => item.email === email)[0]
        .assignedItems
    });

    setFormFields({
      assignedItems: {
        ...formFields.assignedItems,
        [email]: event.target.value
      }
    });
  };

  return (
    <div data-testid="subject-experts-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          columns={columns}
          data={props.subjectExperts}
          options={options}
          title={""}
        />
      </ThemeProvider>
    </div>
  );
};

export default SubjectExpertsTable;
