// Core
import React, { FC, ReactElement, useState } from "react";

// Component
import WholeQuestionDialogue from "components/wholeQuestionDialogue/WholeQuestionDialogue";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

interface Props {
  onClickContinue: () => void;
  onClickReject: () => void;
  wholeQuestionImageUrls: string[];
}

const InitialExtendedTextOrTextEntryInteractionHeader: FC<Props> = (
  props
): ReactElement => {
  const [isWholeQuestionDialogueOpen, setIsWholeQuestionDialogueOpen] =
    useState(false);

  const handleClickViewWholeQuestion = (): void => {
    setIsWholeQuestionDialogueOpen(true);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="initial-extended-text-or-text-entry-interaction-header"
    >
      <WholeQuestionDialogue
        imageUrls={props.wholeQuestionImageUrls}
        isOpen={isWholeQuestionDialogueOpen}
        onClickClose={() => setIsWholeQuestionDialogueOpen(false)}
      />
      <Box>
        <Box marginBottom={3}>
          <Box marginBottom={1}>
            <Typography variant="h4" data-testid="instructions">
              Check if the left item can stand alone
            </Typography>
          </Box>
          <Typography>
            If any of the optional parts on the right are required, please add
            them.
          </Typography>
        </Box>
        <Button
          color="primary"
          onClick={handleClickViewWholeQuestion}
          startIcon={<Visibility />}
          variant="text"
          data-testid="view-whole-question-button"
        >
          View whole question
        </Button>
      </Box>
      <Box display="flex" marginLeft="auto">
        <Box marginRight={2}>
          <Button
            color="secondary"
            disableElevation
            onClick={props.onClickReject}
            variant="outlined"
            data-testid="reject-button"
          >
            Reject
          </Button>
        </Box>
        <Button
          color="primary"
          disableElevation
          onClick={props.onClickContinue}
          data-testid="continue-button"
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default InitialExtendedTextOrTextEntryInteractionHeader;
