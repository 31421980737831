// Core
import React, { FC, ReactElement, useState } from "react";

// Vendor
import { colours, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import {
  CheckCircleOutlined,
  ErrorOutline,
  InfoOutlined,
  ReportProblemOutlined
} from "@material-ui/icons";

interface Props {
  body: ReactElement | string;
  dismissable?: boolean;
  heading?: string;
  level: "error" | "information" | "success" | "warning";
  testId?: string;
}

const Message: FC<Props> = (props): ReactElement => {
  const [isDimissed, setIsDismissed] = useState(false);

  // this ternary operator is necessary to make  colour  a const; hence "nosonar"
  const colour = // nosonar
    props.level === "error"
      ? colours.negative
      : props.level === "information"
      ? colours.informative
      : props.level === "warning"
      ? "#bd4f00" // There is no corresponding colour for this in cambridge-ui. IH 24/09/21
      : colours.positive;

  const handleClickDismiss = (): void => {
    setIsDismissed(true);
  };

  return (
    <Box
      display={isDimissed ? "none" : "block"}
      data-testid={props.testId ? props.testId : "message"}
    >
      <Box alignItems="center" display="flex" marginY={3}>
        {props.level === "error" && (
          <Box clone marginRight={2}>
            <ErrorOutline htmlColor={colour} />
          </Box>
        )}
        {props.level === "information" && (
          <Box clone marginRight={2}>
            <InfoOutlined htmlColor={colour} />
          </Box>
        )}
        {props.level === "success" && (
          <Box clone marginRight={2}>
            <CheckCircleOutlined htmlColor={colour} />
          </Box>
        )}
        {props.level === "warning" && (
          <Box clone marginRight={2}>
            <ReportProblemOutlined htmlColor={colour} />
          </Box>
        )}
        {props.heading && (
          <Box marginRight={2}>
            <Typography
              variant="subtitle1"
              style={{
                color: colour
              }}
            >
              {props.heading}
            </Typography>
          </Box>
        )}
        {typeof props.body === "string" && (
          <Typography
            style={{
              color: colour
            }}
          >
            {props.body}
          </Typography>
        )}
        {typeof props.body !== "string" && (
          <div
            style={{
              color: colour
            }}
          >
            {props.body}
          </div>
        )}
        {props.dismissable && (
          <Box marginLeft={6}>
            <Typography
              onClick={handleClickDismiss}
              style={{
                color: colour,
                cursor: "pointer",
                textDecoration: "underline"
              }}
              variant="subtitle1"
              data-testid="dismiss-button"
            >
              Dismiss
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default Message;
