// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";

interface CheckboxInterface {
  testId: string;
  value: string;
}

interface FormFields {
  additionalComment: string;
  rejectionReasons: string[];
}

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  onClickConfirmReject: (body: {
    rejectionExplanation?: string;
    rejectionReasons: string[];
  }) => void;
}

const checkboxes = [
  {
    testId: "it-depends-on-another-question-checkbox",
    value: "It depends on another question"
  },
  {
    testId: "not-suitable-for-on-screen-reuse-checkbox",
    value: "Not suitable for on-screen reuse"
  },
  {
    testId: "there-is-a-visual-problem-with-the-items-checkbox",
    value: "There is a visual problem with the items"
  },
  {
    testId: "other-checkbox",
    value: "Other"
  }
];

const RejectSharedStimuliDialogue: FC<Props> = (props): ReactElement => {
  const [formFields, setFormFields] = useState({
    additionalComment: "",
    rejectionReasons: []
  } as FormFields);
  const [isAdditionalCommentMinLength, setIsAdditionalCommentMinLength] =
    useState(true);

  useEffect(() => {
    setIsAdditionalCommentMinLength(
      formFields.additionalComment
        ? formFields.additionalComment.length > 2
        : true
    );
  }, [formFields.additionalComment]);

  const handleChangeAdditionalComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      additionalComment: event.target.value
    });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFormFields({
      ...formFields,
      rejectionReasons: event.target.checked
        ? [...formFields.rejectionReasons, value]
        : formFields.rejectionReasons.filter((reason) => reason !== value)
    });
  };

  const handleClickClose = (): void => {
    setFormFields({
      ...formFields,
      additionalComment: "",
      rejectionReasons: []
    });

    props.onClickClose();
  };

  const handleClickReject = (): void => {
    const body = {
      rejectionReasons: formFields.rejectionReasons
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (formFields.additionalComment) {
      body["rejectionExplanation"] = formFields.additionalComment;
    }

    props.onClickConfirmReject(body);
    handleClickClose();
  };

  const renderCheckbox = (checkbox: CheckboxInterface): ReactElement => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={formFields.rejectionReasons.includes(checkbox.value)}
            color="primary"
            inputProps={
              {
                "aria-checked": formFields.rejectionReasons.includes(
                  checkbox.value
                ),
                "data-testid": checkbox.testId
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any
            }
            onChange={(event) => handleChangeCheckbox(event)}
            name={checkbox.testId}
            value={checkbox.value}
          />
        }
        key={checkbox.testId}
        label={checkbox.value}
      />
    );
  };

  return (
    <Dialog
      onClose={handleClickClose}
      aria-labelledby="reject-shared-stimuli-dialogue-heading"
      open={props.isOpen}
      data-testid="reject-shared-stimuli-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Box marginBottom={2}>
            <Typography
              component="h2"
              variant="h4"
              data-testid="reject-shared-stimuli-dialogue-heading"
            >
              What is wrong with the item?
            </Typography>
          </Box>
          <Typography>
            Please note: You cannot recover a rejected item later; it&rsquo;s
            irreversible.
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <FormControl component="fieldset">
            <FormGroup>
              {checkboxes.map((checkbox) => renderCheckbox(checkbox))}
            </FormGroup>
          </FormControl>
        </Box>
        <Box marginBottom={3}>
          <Box marginBottom={1}>
            <Box display="inline">
              <Typography display="inline" style={{ fontWeight: 600 }}>
                Additional comment
              </Typography>
            </Box>{" "}
            <Typography display="inline">
              (
              {formFields.rejectionReasons.includes("Other")
                ? "Required"
                : "Optional"}
              )
            </Typography>
          </Box>
          <TextField
            helperText={
              !isAdditionalCommentMinLength
                ? "Must be at least three characters long"
                : undefined
            }
            inputProps={{
              "data-testid": "additional-comment-input"
            }}
            multiline={true}
            onChange={handleChangeAdditionalComment}
            validationType={!isAdditionalCommentMinLength ? "error" : undefined}
            value={formFields.additionalComment}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Box clone marginRight={2}>
            <Button
              color="default"
              onClick={handleClickClose}
              variant="text"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
          <Button
            color={
              !isAdditionalCommentMinLength ||
              !formFields.rejectionReasons.length ||
              (formFields.rejectionReasons.includes("Other") &&
                !formFields.additionalComment)
                ? "default"
                : "secondary"
            }
            disableElevation
            disabled={
              !isAdditionalCommentMinLength ||
              !formFields.rejectionReasons.length ||
              (formFields.rejectionReasons.includes("Other") &&
                !formFields.additionalComment)
            }
            onClick={handleClickReject}
            data-testid="reject-button"
            variant="contained"
          >
            Reject this item
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RejectSharedStimuliDialogue;
