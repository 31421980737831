// Core
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useReducer
} from "react";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

type Action = { type: string; payload: unknown };
type Dispatch = (action: Action) => void;
type ProjectContextProps = { children: ReactNode };
type State = {
  hasError: boolean;
  isFetching: boolean;
  project: IProject;
  projects: IProject[];
};

const initialState = {
  hasError: false,
  isFetching: false,
  project: {} as IProject,
  projects: [] as IProject[]
};
const ProjectDispatchContext = createContext<Dispatch | undefined>(undefined);
const ProjectStateContext = createContext<State | undefined>(undefined);

interface UseProject {
  createProjectSuccess: (project: IProject) => void;
  deleteProjectSuccess: (projectKey: string) => void;
  fetchProjectRequest: () => void;
  fetchProjectSuccess: (project: IProject) => void;
  fetchProjectsRequest: () => void;
  fetchProjectsSuccess: (projects: IProject[]) => void;
  project: IProject;
  projects: IProject[];
  updateProjectSuccess: (project: IProject) => void;
}

const projectReducer = (state: State, action: Action): State => {
  if (action.type === "CREATE_PROJECT_SUCCESS") {
    const projects = [...state.projects];

    projects.push(action.payload as IProject);

    return {
      ...state,
      projects
    };
  }

  if (action.type === "DELETE_PROJECT_SUCCESS") {
    return {
      ...state,
      projects: state.projects.filter(
        (project) => project.key !== (action.payload as string)
      )
    };
  }

  if (
    action.type === "FETCH_PROJECT_FAILURE" ||
    action.type === "FETCH_PROJECTS_FAILURE"
  ) {
    return {
      ...state,
      hasError: true,
      isFetching: false
    };
  }

  if (
    action.type === "FETCH_PROJECT_REQUEST" ||
    action.type === "FETCH_PROJECTS_REQUEST"
  ) {
    return {
      ...state,
      hasError: false,
      isFetching: true
    };
  }

  if (action.type === "FETCH_PROJECT_SUCCESS") {
    return {
      ...state,
      project: action.payload as IProject
    };
  }

  if (action.type === "FETCH_PROJECTS_SUCCESS") {
    return {
      ...state,
      projects: action.payload as IProject[]
    };
  }

  if (action.type === "UPDATE_PROJECT_SUCCESS") {
    const projects = [...state.projects];

    if (projects.length) {
      projects[
        projects.findIndex(
          (project) => project.key === (action.payload as IProject).key
        )
      ] = action.payload as IProject;
    }

    return {
      ...state,
      project: action.payload as IProject,
      projects
    };
  }

  return state;
};

const ProjectContext = ({ children }: ProjectContextProps): ReactElement => {
  const [state, dispatch] = useReducer(projectReducer, initialState);

  return (
    <ProjectStateContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectStateContext.Provider>
  );
};

const useProjectDispatch = (): Dispatch => {
  const context = useContext(ProjectDispatchContext);

  if (context === undefined) {
    throw new Error("useProjectDispatch must be used within a ProjectContext");
  }

  return context;
};

const useProjectState = (): State => {
  const context = useContext(ProjectStateContext);

  if (context === undefined) {
    throw new Error("useProjectState must be used within a ProjectContext");
  }

  return context;
};

const useProject = (): UseProject => {
  const dispatch = useProjectDispatch();
  const { project, projects } = useProjectState();

  const createProjectSuccess = useCallback<UseProject["createProjectSuccess"]>(
    (value) => {
      dispatch({ type: "CREATE_PROJECT_SUCCESS", payload: value });
    },
    [dispatch]
  );

  const deleteProjectSuccess = useCallback<UseProject["deleteProjectSuccess"]>(
    (value) => {
      dispatch({ type: "DELETE_PROJECT_SUCCESS", payload: value });
    },
    [dispatch]
  );

  const fetchProjectRequest = useCallback<
    UseProject["fetchProjectRequest"]
  >(() => {
    dispatch({ type: "FETCH_PROJECT_REQUEST", payload: "" });
  }, [dispatch]);

  const fetchProjectSuccess = useCallback<UseProject["fetchProjectSuccess"]>(
    (value) => {
      dispatch({ type: "FETCH_PROJECT_SUCCESS", payload: value });
    },
    [dispatch]
  );

  const fetchProjectsRequest = useCallback<
    UseProject["fetchProjectsRequest"]
  >(() => {
    dispatch({ type: "FETCH_PROJECTS_REQUEST", payload: "" });
  }, [dispatch]);

  const fetchProjectsSuccess = useCallback<UseProject["fetchProjectsSuccess"]>(
    (value) => {
      dispatch({ type: "FETCH_PROJECTS_SUCCESS", payload: value });
    },
    [dispatch]
  );

  const updateProjectSuccess = useCallback<UseProject["updateProjectSuccess"]>(
    (value) => {
      dispatch({ type: "UPDATE_PROJECT_SUCCESS", payload: value });
    },
    [dispatch]
  );

  return {
    createProjectSuccess,
    deleteProjectSuccess,
    fetchProjectRequest,
    fetchProjectSuccess,
    fetchProjectsRequest,
    fetchProjectsSuccess,
    project,
    projects,
    updateProjectSuccess
  };
};

export { ProjectContext, useProject };
