// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Components
import ApproversTable from "pages/projects/approvers/components/approversTable/ApproversTable";
import UserOption from "components/userOption/UserOption";

// Interfaces
import { ISapCdcUser } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  withStyles
} from "@material-ui/core";
import { Add, Search } from "@material-ui/icons";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const filterOptions = createFilterOptions({
  limit: 10,
  stringify: (option: ISapCdcUser) =>
    `${option.email} ${option.name} ${option.family_name}`
});

const Approvers: FC = (): ReactElement => {
  const { getUsers, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeApproverOption, setActiveApproverOption] = useState(
    {} as ISapCdcUser
  );
  const [approverOptions, setApproverOptions] = useState([] as ISapCdcUser[]);
  const [formFields, setFormFields] = useState({
    approver: ""
  } as { approver: string });
  const history = useHistory();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getUsers<ISapCdcUser[]>().then((response) => {
      const emails = project.subjectExperts.map((subjectExpert) =>
        subjectExpert.email.toLowerCase()
      );

      setApproverOptions(
        response.data
          ?.filter((user) => !emails.includes(user.email.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      );
    });
  }, [getUsers, project]);

  const handleChangeApproverInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      approver: event.target.value
    });
  };

  const handleChangeApproversAutocomplete = (
    event: ChangeEvent<unknown>,
    option: ISapCdcUser | null
  ): void => {
    setFormFields({
      ...formFields,
      approver: ""
    });

    if (!option) {
      return;
    }

    setActiveApproverOption(option);
  };

  const handleClickAdd = (): void => {
    const approver = {
      email: activeApproverOption.email,
      sub: activeApproverOption.sub
    };

    updateProject(project.key, {
      ...project,
      approvers: [...project.approvers, approver]
    }).then(() => {
      updateProjectSuccess({
        ...project,
        approvers: [...project.approvers, approver]
      });

      setActiveApproverOption({} as ISapCdcUser);
    });
  };

  const handleClickContinue = (): void => {
    history.push(`/projects/${project.key}/edit/details`);
  };

  const handleClickDelete = (email: string): void => {
    const approvers = project.approvers.filter(
      (approver) => approver.email !== email
    );

    updateProject(project.key, {
      ...project,
      approvers
    }).then(() => {
      updateProjectSuccess({
        ...project,
        approvers
      });
    });
  };

  return (
    <div data-testid="approvers-page">
      {Object.keys(project).length > 0 && (
        <>
          <Box marginBottom={4}>
            <Box display="flex" marginBottom={4}>
              <Box>
                <Box marginBottom={1}>
                  <Typography
                    component="h2"
                    variant="h4"
                    data-testid="page-heading"
                  >
                    Add an approver
                  </Typography>
                </Box>
                <Typography data-testid="page-introduction">
                  Approvers perform a final check before the items are banked.
                  They will be notified when the project starts
                </Typography>
              </Box>
              <Box marginLeft="auto">
                <Button
                  color="primary"
                  disableElevation
                  disabled={!project.approvers.length}
                  onClick={handleClickContinue}
                  data-testid="continue-button"
                >
                  Continue
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box marginBottom={4}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item md={7} xs={12}>
                <Autocomplete
                  disabled={project.approvers.length > 0}
                  filterOptions={filterOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.email || ""}
                  noOptionsText="No results found."
                  onChange={handleChangeApproversAutocomplete}
                  options={
                    formFields.approver
                      ? [activeApproverOption, ...approverOptions]
                      : []
                  }
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "approver-input"
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                      onChange={handleChangeApproverInput}
                      placeholder="Search name or email address"
                    />
                  )}
                  renderOption={(option) => <UserOption option={option} />}
                  value={activeApproverOption}
                  data-testid="approvers-autocomplete"
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disableElevation
                  disabled={!Object.keys(activeApproverOption).length}
                  onClick={handleClickAdd}
                  startIcon={<Add />}
                  data-testid="add-button"
                >
                  Add selected approver
                </Button>
              </Grid>
            </Grid>
          </Box>
          {project.approvers.length > 0 && (
            <ApproversTable
              approvers={project.approvers}
              onClickDelete={handleClickDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Approvers;
