// Core
import React, { FC, ReactElement, useMemo, useState } from "react";

// Components
import ConfirmStartProjectDialogue from "pages/projects/confirm/components/confirmStartProjectDialogue/ConfirmStartProjectDialogue";
import ProjectSetupDialogue from "components/projectSetupDialogue/ProjectSetupDialogue";
import ProjectSummary from "components/projectSummary/ProjectSummary";

// Utils
import { useApi, useProject } from "utils/context";
import { getAssignedItemsCount } from "utils/project";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

const Confirm: FC = (): ReactElement => {
  const { startProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [
    isConfirmStartProjectDialogueOpen,
    setIsConfirmStartProjectDialogueOpen
  ] = useState(false);
  const [isProjectSetupDialogueOpen, setIsProjectSetupDialogueOpen] =
    useState(false);
  const history = useHistory();
  const assignedItemsCount = getAssignedItemsCount(project);
  const summaryItems = useMemo(
    () => [
      {
        data: project.name,
        heading: "Project name",
        onClickEdit: () => setIsProjectSetupDialogueOpen(true)
      },
      {
        data:
          project.subjectExperts?.map(
            (subjectExpert, index) =>
              `${subjectExpert.email}${
                index === project.subjectExperts.length - 1 ? "" : ", "
              }`
          ) || [],
        heading: "Subject experts",
        onClickEdit: () =>
          history.push(`/projects/${project.key}/edit/subject-experts`)
      },
      {
        data: project.subjectExpertInstructions || "",
        heading: "Instructions (Subject expert)",
        onClickEdit: () => history.push(`/projects/${project.key}/edit/details`)
      },
      {
        data:
          project.approvers?.map(
            (approver, index) =>
              `${approver.email}${
                index === project.approvers.length - 1 ? "" : ", "
              }`
          ) || [],
        heading: "Approvers",
        onClickEdit: () =>
          history.push(`/projects/${project.key}/edit/approvers`)
      },
      {
        data: project.approverInstructions || "",
        heading: "Instructions (Approver)",
        onClickEdit: () => history.push(`/projects/${project.key}/edit/details`)
      },
      {
        data: project.targetEndDate
          ? dayjs((project.targetEndDate as string).split("T")[0]).format(
              "DD/MM/YYYY"
            )
          : "None",
        heading: "Project deadline",
        onClickEdit: () => history.push(`/projects/${project.key}/edit/details`)
      }
    ],
    [history, project]
  );
  const sharedClasses = sharedStyles();

  const handleClickConfirm = (): void => {
    setIsConfirmStartProjectDialogueOpen(true);
  };

  const handleClickStartProject = (): void => {
    setIsConfirmStartProjectDialogueOpen(false);

    startProject(project.key).then(() => {
      updateProjectSuccess({
        ...project,
        status: "inProgress"
      });

      history.push({
        pathname: "/admin/projects",
        state: {
          activeStatusFilter: "inProgress"
        }
      });
    });
  };

  return (
    <div data-testid="confirm-page">
      <ConfirmStartProjectDialogue
        isOpen={isConfirmStartProjectDialogueOpen}
        onClickStartProject={handleClickStartProject}
        setIsConfirmStartProjectDialogueOpen={
          setIsConfirmStartProjectDialogueOpen
        }
      />
      <ProjectSetupDialogue
        isOpen={isProjectSetupDialogueOpen}
        onClickClose={() => setIsProjectSetupDialogueOpen(false)}
        project={project}
      />
      <Box marginBottom={4}>
        <Box display="flex" marginBottom={4}>
          <Box>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Confirm your project details
              </Typography>
            </Box>
            <Typography data-testid="page-introduction">
              Once you start the project, subject experts and approvers will be
              notified
            </Typography>
          </Box>
          <Box marginLeft="auto">
            <Button
              color="primary"
              disableElevation
              disabled={
                !project.name ||
                !project.items ||
                !project.approverInstructions ||
                !project.subjectExpertInstructions ||
                !project.approvers.length ||
                !project.subjectExperts.length ||
                assignedItemsCount !== project.items
              }
              onClick={handleClickConfirm}
              data-testid="confirm-button"
            >
              Confirm and start
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
      {Object.keys(project).length > 0 && (
        <>
          <Box marginBottom={4}>
            <Card data-testid="project-statistics">
              <CardContent>
                <Box display="flex">
                  <Box marginRight={11}>
                    <Box marginBottom={1}>
                      <Typography
                        className={sharedClasses.fadedText}
                        component="h3"
                        variant="subtitle1"
                      >
                        Syllabus code
                      </Typography>
                    </Box>
                    <Typography
                      component="p"
                      variant="h5"
                      data-testid="syllabus-code"
                    >
                      {project.syllabusCode}
                    </Typography>
                  </Box>
                  <Box marginRight={11}>
                    <Box marginBottom={1}>
                      <Typography
                        className={sharedClasses.fadedText}
                        component="h3"
                        variant="subtitle1"
                      >
                        Uploaded tests
                      </Typography>
                    </Box>
                    <Typography
                      component="p"
                      variant="h5"
                      data-testid="uploaded-jobs"
                    >
                      {project.uploadedJobs}
                    </Typography>
                  </Box>
                  <Box marginRight={11}>
                    <Box marginBottom={1}>
                      <Typography
                        className={sharedClasses.fadedText}
                        component="h3"
                        variant="subtitle1"
                      >
                        Harvested items
                      </Typography>
                    </Box>
                    <Typography component="p" variant="h5" data-testid="items">
                      {project.items}
                    </Typography>
                  </Box>
                  <Box marginRight={11}>
                    <Box marginBottom={1}>
                      <Typography
                        className={sharedClasses.fadedText}
                        component="h3"
                        variant="subtitle1"
                      >
                        Subject experts
                      </Typography>
                    </Box>
                    <Typography
                      component="p"
                      variant="h5"
                      data-testid="subject-experts"
                    >
                      {project.subjectExperts.length}
                    </Typography>
                  </Box>
                  <Box>
                    <Box marginBottom={1}>
                      <Typography
                        className={sharedClasses.fadedText}
                        component="h3"
                        variant="subtitle1"
                      >
                        Approvers
                      </Typography>
                    </Box>
                    <Typography
                      component="p"
                      variant="h5"
                      data-testid="approvers"
                    >
                      {project.approvers.length}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <ProjectSummary items={summaryItems} />
        </>
      )}
    </div>
  );
};

export default Confirm;
