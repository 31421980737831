/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useRef } from "react";

export const useInterval = (
  callback: (...args: any[]) => void,
  delay: number | null,
  ...args: any[]
): void => {
  const savedCallback = useRef<(...args: any[]) => void>(callback);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const tick = () => {
      savedCallback.current(...args);
    };

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [args, delay]);
};
