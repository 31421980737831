// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import LoseSelectedFilesDialogue from "components/editProjectHeader/components/loseSelectedFilesDialogue/LoseSelectedFilesDialogue";
import ProjectStepper from "components/projectStepper/ProjectStepper";

// Enums
import { ProjectType } from "enums";

// Utils
import { useProject } from "utils/context";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";

const EditProjectHeader: FC = (): ReactElement => {
  const [activeStep, setActiveStep] = useState("");
  const [isLoseSelectedFilesDialogueOpen, setIsLoseSelectedFilesDialogueOpen] =
    useState(false);
  const { project } = useProject();
  const history = useHistory();
  const location = useLocation();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    const slugs = location.pathname.split("/");

    setActiveStep(slugs[slugs.length - 1]);
  }, [location, project]);

  const handleClickBack = (): void => {
    if (activeStep === "upload") {
      setIsLoseSelectedFilesDialogueOpen(true);

      return;
    }

    history.push("/admin/dashboard");
  };

  return (
    <Box marginTop="-47px" marginBottom={6} data-testid="edit-project-header">
      <LoseSelectedFilesDialogue
        isOpen={isLoseSelectedFilesDialogueOpen}
        onClickClose={() => setIsLoseSelectedFilesDialogueOpen(false)}
      />
      <Card
        className={`${sharedClasses.bleed} ${sharedClasses.heroHeaderCard}`}
        square
        variant="outlined"
      >
        <CardContent className={sharedClasses.heroHeaderCardContent}>
          <Box marginBottom={4}>
            <Button
              color="primary"
              onClick={handleClickBack}
              startIcon={<ArrowBack />}
              variant="text"
              data-testid="back-button"
            >
              Save and continue later
            </Button>
          </Box>
          <Box marginBottom={4}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h3"
                data-testid="project-name"
              >
                {project?.name}
              </Typography>
            </Box>
            <Typography
              className={sharedClasses.fadedText}
              variant="h5"
              data-testid="project-type"
            >
              {ProjectType[project?.type]}
            </Typography>
          </Box>
          <ProjectStepper />
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditProjectHeader;
