// Interfaces
import { IProject } from "@qti-scraper/interfaces";

export const getAssignedItemsCount = (project: IProject): number => {
  if (!Object.keys(project).length) {
    return 0;
  }

  return project.subjectExperts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.assignedItems,
    0
  );
};
