// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Box, Dialog } from "@material-ui/core";

interface Props {
  imageUrls: string[];
  isOpen: boolean;
  onClickClose: () => void;
}

const WholeQuestionDialogue: FC<Props> = (props): ReactElement => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={props.onClickClose}
      open={props.isOpen}
      data-testid="whole-question-dialogue"
    >
      <Box display="flex" flexDirection="column" padding={4}>
        {props.imageUrls.map((imageUrl) => (
          <img
            key={imageUrl}
            src={imageUrl}
            data-testid="whole-question-image"
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default WholeQuestionDialogue;
