// Core
import React, { FC, ReactElement, useEffect } from "react";

// Utils
import { useApi } from "utils/context";
import { useHistory } from "react-router-dom";

interface Props {
  children: ReactElement | ReactElement[];
}

const ProjectsLayout: FC<Props> = (props: Props): ReactElement => {
  const { user } = useApi();
  const history = useHistory();

  useEffect(() => {
    if (!user || user.role !== "admin") {
      history.push("/");
    }
  }, [history, user]);

  return <div data-testid="projects-layout">{props.children}</div>;
};

export default ProjectsLayout;
