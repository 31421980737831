// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ProjectSetupDialogue from "components/projectSetupDialogue/ProjectSetupDialogue";
import Reminder from "pages/adminDashboard/components/reminder/Reminder";

// Enums
import { ProjectStatus } from "enums";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core";
import { Add, Alarm, AlarmOff, Visibility } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const AdminDashboard: FC = (): ReactElement => {
  const { user } = useApi();
  const { projects } = useProject();
  const [isProjectSetupDialogueOpen, setIsProjectSetupDialogueOpen] =
    useState(false);
  const history = useHistory();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!user || user.role !== "admin") {
      history.push("/");
    }
  }, [history, user]);

  const handleClickCreateProject = (): void => {
    setIsProjectSetupDialogueOpen(true);
  };

  const handleClickViewOnboarding = (): void => {
    history.push("/admin/onboarding");
  };

  const renderStatusCount = (
    status: IProject["status"],
    first?: boolean
  ): ReactElement => {
    return (
      <>
        <Box marginLeft={first ? 8 : 0} marginRight={8}>
          <Box marginBottom={1}>
            <Typography
              className={sharedClasses.fadedText}
              component="h4"
              variant="subtitle1"
            >
              {ProjectStatus[status]}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography component="p" variant="h5">
              {projects.reduce(
                (previousValue, currentValue) =>
                  previousValue +
                  ((
                    status === "notStarted"
                      ? currentValue.status === status ||
                        currentValue.status === "harvestingFailed"
                      : currentValue.status === status
                  )
                    ? 1
                    : 0),
                0
              )}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <div data-testid="admin-dashboard-page">
      <ProjectSetupDialogue
        isOpen={isProjectSetupDialogueOpen}
        onClickClose={() => setIsProjectSetupDialogueOpen(false)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box clone marginBottom={8} data-testid="projects-summary">
            <Card>
              <CardContent>
                <Box alignItems="center" display="flex" data-testid="detail">
                  <Box marginRight={8}>
                    <Typography component="h2" variant="h3">
                      Hello, {user ? user.name : ""}
                    </Typography>
                  </Box>
                  <Divider flexItem orientation="vertical" />
                  {renderStatusCount("notStarted", true)}
                  {renderStatusCount("inProgress")}
                  {renderStatusCount("completed")}
                </Box>
              </CardContent>
              <CardActions>
                <Box clone marginRight={2}>
                  <Button
                    color="primary"
                    disableElevation
                    onClick={handleClickViewOnboarding}
                    data-testid="view-onboarding-button"
                    startIcon={<Visibility />}
                    variant="outlined"
                  >
                    View onboarding content
                  </Button>
                </Box>
                <Button
                  color="primary"
                  disableElevation
                  onClick={handleClickCreateProject}
                  data-testid="create-project-button"
                  startIcon={<Add />}
                >
                  Set up a new project
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <Box height="100%" data-testid="reminders">
            <Box marginBottom={2}>
              <Typography component="h2" variant="h4">
                Reminders
              </Typography>
            </Box>
            {projects.length > 0 && (
              <Box clone alignItems="center" display="flex" height="100%">
                <Reminder
                  body="You have no reminders."
                  icon={<AlarmOff color="primary" fontSize="large" />}
                />
              </Box>
            )}
            {projects.length === 0 && (
              <Box clone alignItems="center" display="flex" height="100%">
                <Reminder
                  body="Welcome to the Content Management tool!"
                  heading="Set up your first project"
                  icon={<Alarm color="primary" fontSize="large" />}
                  testId="set-up-project-reminder"
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height="100%" data-testid="updates">
            <Box marginBottom={2}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="section-heading"
              >
                Updates
              </Typography>
            </Box>
            <Box clone alignItems="center" display="flex" height="100%">
              <Card>
                <CardContent>
                  <Typography>You have no updates at the moment.</Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
