// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";

interface Props {
  onClickBank: () => void;
  onClickReject: () => void;
  isBankDisabled: boolean;
}

const ContentCheckedChoiceInteractionHeader: FC<Props> = (
  props
): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="content-checked-choice-interaction-header"
    >
      <Box>
        <Box marginBottom={1}>
          <Typography variant="h4">Bank the item</Typography>
        </Box>
        <Typography>
          Check the data added by the subject expert, then bank this item.
          <br />
          You can make changes before banking it.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="auto">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box marginRight={2}>
            <Button
              color="secondary"
              disableElevation
              onClick={props.onClickReject}
              variant="outlined"
              data-testid="reject-button"
            >
              Reject item
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            disabled={props.isBankDisabled}
            onClick={props.onClickBank}
            startIcon={<Save />}
            data-testid="bank-button"
          >
            Bank this item
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ContentCheckedChoiceInteractionHeader;
