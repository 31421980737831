// Core
import React, { FC, ReactElement } from "react";

// Components
import Tooltip from "components/tooltip/Tooltip";

// Interface
import { ITask } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core";
import { PlayArrow, WatchLater } from "@material-ui/icons";
import dayjs from "dayjs";

interface Props {
  onClickStart: (
    taskKey: string,
    role: "approver" | "subjectExpert",
    itemKey?: string
  ) => void;
  task: ITask;
}

const ApproverTask: FC<Props> = (props): ReactElement => {
  const assignedItemsCount =
    props.task.completedItems.length + props.task.pendingItems.length;
  const completedItemsCount = props.task.completedItems.length;
  const sharedClasses = sharedStyles();

  return (
    <Box marginBottom={4}>
      <Card data-testid="approver-task">
        <CardContent>
          <Box marginBottom={5}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="project-name"
              >
                {props.task.project.name}
              </Typography>
            </Box>
            <Typography
              className={sharedClasses.fadedText}
              component="h2"
              variant="h5"
            >
              Approver
            </Typography>
          </Box>
          <Box marginBottom={5}>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <Typography data-testid="instructions">
                  {props.task.project.approverInstructions}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex">
            <Box marginRight={8}>
              <Box display="flex" marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Received items
                </Typography>
                <Tooltip title="This shows how many items you've received and are expecting in total from subject experts." />
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="received-items"
              >
                <Tooltip title="Items you've received so far.">
                  <>{assignedItemsCount}</>
                </Tooltip>{" "}
                /{" "}
                <Tooltip title="Total items you are expecting for this project.">
                  <>{props.task.project.items}</>
                </Tooltip>
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginX={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  {assignedItemsCount === 0 ? "Status" : "Your progress"}
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid={`${
                  assignedItemsCount === 0 ? "status" : "progress"
                }`}
              >
                {assignedItemsCount === 0 && <>Waiting to receive items</>}
                {assignedItemsCount > 0 && (
                  <>
                    {completedItemsCount} / {assignedItemsCount}
                  </>
                )}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginLeft={8}>
              <Box alignItems="center" display="flex" marginBottom={1}>
                <Box marginRight={1}>
                  <Typography
                    className={sharedClasses.fadedText}
                    component="h3"
                    variant="subtitle1"
                  >
                    Due date
                  </Typography>
                </Box>
                <WatchLater
                  fontSize="small"
                  htmlColor={colours.monochromeMid}
                />
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="target-end-date"
              >
                {props.task.project.targetEndDate
                  ? dayjs(
                      (props.task.project.targetEndDate as string).split("T")[0]
                    ).format("DD/MM/YYYY")
                  : "None"}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disableElevation
            disabled={!props.task.pendingItems.length}
            onClick={() =>
              props.onClickStart(
                props.task.key,
                "approver",
                props.task.pendingItems[0]
              )
            }
            startIcon={<PlayArrow />}
            data-testid="start-button"
          >
            {completedItemsCount ? "Continue" : "Start"} this task
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ApproverTask;
