// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Vendor
import { Box } from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  content: string;
  isEditing: boolean;
  onChange: (value: string) => void;
}

const Content: FC<Props> = (props): ReactElement => {
  return (
    <Box marginBottom={props.isEditing ? 0 : 3} data-testid="content">
      {props.isEditing && (
        <Editor
          allowImages={false}
          allowLists={false}
          allowTables={false}
          onChange={props.onChange}
          testId="content-editor"
          value={props.content}
        />
      )}
      {!props.isEditing && (
        <div className="read-only" data-testid="content-content">
          <MathJax
            math={props.content}
            sanitizeOptions={{
              ADD_TAGS: ["b", "br", "i", "p", "span", "sub", "sup", "u"]
            }}
            wrapperTag="span"
          />
        </div>
      )}
    </Box>
  );
};

export default Content;
