// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, Typography } from "@material-ui/core";
import { CheckCircleOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

interface Props {
  isOpen: boolean;
}

const TaskCompletedDialogue: FC<Props> = (props): ReactElement => {
  const history = useHistory();

  const handleClickClose = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ): void => {
    if (reason === "backdropClick") {
      return;
    }
  };

  const handleClickGoToDashboard = (): void => {
    history.push("/examiner/dashboard");
  };

  return (
    <Dialog
      onClose={handleClickClose}
      aria-labelledby="task-completed-dialogue-heading"
      open={props.isOpen}
      data-testid="task-completed-dialogue"
    >
      <Box padding={4} textAlign="center">
        <Box marginBottom={5}>
          <Box clone marginBottom={7}>
            <CheckCircleOutlined
              fontSize="large"
              htmlColor={colours.positive}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              data-testid="task-completed-dialogue-heading"
            >
              All items completed
            </Typography>
          </Box>
          <Typography>
            Thank you. You can go back to your dashboard now.
          </Typography>
        </Box>
        <Button
          color="primary"
          disableElevation
          onClick={handleClickGoToDashboard}
          data-testid="go-to-dashboard-button"
        >
          Go to dashboard
        </Button>
      </Box>
    </Dialog>
  );
};

export default TaskCompletedDialogue;
