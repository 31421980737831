// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteractionTask from "pages/subjectExpertTask/components/choiceInteractionTask/ChoiceInteractionTask";
import ExtendedTextInteractionTask from "pages/subjectExpertTask/components/extendedTextInteractionTask/ExtendedTextInteractionTask";
import LoadingDialogue from "components/loadingDialogue/LoadingDialogue";
import TaskCompletedDialogue from "components/taskCompletedDialogue/TaskCompletedDialogue";
import TaskHeader from "components/taskHeader/TaskHeader";
import TextEntryInteractionTask from "pages/subjectExpertTask/components/textEntryInteractionTask/TextEntryInteractionTask";

// Interfaces
import {
  ExtendedTextInteraction,
  Item,
  SharedStimulus,
  TextEntryInteraction
} from "interfaces";
import { ITask } from "@qti-scraper/interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { useHistory, useParams } from "react-router-dom";

const SubjectExpertTask: FC = (): ReactElement => {
  const {
    getItem,
    getSharedStimulus,
    getTask,
    getWholeQuestion,
    patchItem,
    rejectItem,
    updateItem,
    updateItemStatus,
    updateSharedStimulus,
    user
  } = useApi();
  const [activeAction, setActiveAction] = useState(
    null as "isRejecting" | "isSubmitting" | null
  );
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isRejecting, setIsRejecting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isSubmitting, setIsSubmitting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isTaskCompletedDialogueOpen, setIsTaskCompletedDialogueOpen] =
    useState(false);
  const [item, setItem] = useState({} as Item);
  const [sharedStimuli, setSharedStimuli] = useState([] as SharedStimulus[]);
  const [shouldSaveContent, setShouldSaveContent] = useState(false);
  const [shouldSaveMetadata, setShouldSaveMetadata] = useState(false);
  const [shouldSaveSharedStimuli, setShouldSaveSharedStimuli] = useState(false);
  const [statusToSet, setStatusToSet] = useState("" as Item["status"]);
  const [task, setTask] = useState({} as ITask);
  const [wasBackClicked, setWasBackClicked] = useState(false);
  const [wholeQuestionImageUrls, setWholeQuestionImageUrls] = useState(
    [] as string[]
  );
  const history = useHistory();
  const { itemKey } = useParams<Record<string, string | undefined>>();
  const { taskKey } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (!Object.keys(item).length) {
      return;
    }

    if (
      item.content.qtiInteractionType === "extendedTextInteraction" ||
      item.content.qtiInteractionType === "textEntryInteraction"
    ) {
      const content = item.content as
        | ExtendedTextInteraction
        | TextEntryInteraction;

      if (!content.sharedStimuli) {
        return;
      }

      Promise.all(
        content.sharedStimuli.map((sharedStimulus) =>
          getSharedStimulus<SharedStimulus>(sharedStimulus)
        )
      ).then((responses) => {
        setSharedStimuli(
          responses.map((response) => response.data || ({} as SharedStimulus))
        );
      });
    }
  }, [getSharedStimulus, item]);

  useEffect(() => {
    if (!Object.keys(item).length) {
      return;
    }

    if (
      item.content.qtiInteractionType === "extendedTextInteraction" ||
      item.content.qtiInteractionType === "textEntryInteraction"
    ) {
      getWholeQuestion<string[]>(item.job, item.questionNumber, {
        imagesOnly: "true"
      }).then((response) => {
        setWholeQuestionImageUrls(response.data || []);
      });
    }
  }, [getWholeQuestion, item]);

  useEffect(() => {
    if (!taskKey) {
      return;
    }

    getTask<ITask>(taskKey).then((response) => {
      setTask(response.data || ({} as ITask));
    }, console.error);
  }, [getTask, taskKey]);

  useEffect(() => {
    if (!Object.keys(task).length || !itemKey) {
      return;
    }

    if (activeAction === "isRejecting") {
      setIsRejecting({ shouldFadeIn: false, value: true });
    } else if (activeAction === "isSubmitting") {
      setIsSubmitting({ shouldFadeIn: false, value: true });
    }

    getItem<Item>(itemKey, { includePossibleSharedStimuli: "true" }).then(
      (response) => {
        if (activeAction === "isRejecting") {
          setIsRejecting({ shouldFadeIn: false, value: false });
        } else if (activeAction === "isSubmitting") {
          setIsSubmitting({ shouldFadeIn: false, value: false });
        }

        setActiveAction(null);

        setItem({} as Item);

        setWasBackClicked(false);

        setItem(response.data || ({} as Item));
      },
      console.error
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, itemKey, task]);

  const handleClickBack = (): void => {
    setWasBackClicked(true);

    switch (item.content.qtiInteractionType) {
      case "choiceInteraction":
        navigateFromChoiceInteraction();
        break;
      case "extendedTextInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      case "textEntryInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickConfirmReject = (body: any): void => {
    setIsRejecting({
      shouldFadeIn: true,
      value: true
    });

    rejectItem(item.key, body)
      .then(() => {
        setTimeout(() => {
          setActiveAction("isRejecting");
          incrementItem();
        }, 2000);
      })
      .catch(() => {
        setIsRejecting({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  const handleClickContinueFromContent = (): void => {
    patchItem(item.key, { status: "contentChecked" })
      .then(() => {
        setShouldSaveContent(true);
      })
      .catch(console.error);
  };

  const handleClickContinueFromMetadata = (): void => {
    setShouldSaveMetadata(true);
  };

  const handleClickContinueFromSharedStimuli = (): void => {
    setShouldSaveSharedStimuli(true);
  };

  const handleClickTaskStep = (status: Item["status"]): void => {
    setStatusToSet(status);

    switch (item.content.qtiInteractionType) {
      case "choiceInteraction":
        navigateFromChoiceInteraction();
        break;
      case "extendedTextInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      case "textEntryInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      default:
        break;
    }
  };

  const handleSaveContent = (
    content: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ): void => {
    const { sharedStimuli: sharedStimuliFromContent } = content;
    const unsavedSharedStimuli =
      item.content.qtiInteractionType === "extendedTextInteraction" ||
      item.content.qtiInteractionType === "textEntryInteraction"
        ? (sharedStimuliFromContent as SharedStimulus[]).filter(
            (sharedStimulus) => sharedStimulus.hasUnsavedChanges
          )
        : [];

    if (!hasUnsavedChanges && !unsavedSharedStimuli.length) {
      if (wasBackClicked) {
        history.push("/examiner/dashboard");

        return;
      }

      setShouldSaveContent(false);

      if (statusToSet) {
        patchItem(item.key, { status: statusToSet });

        setItem({ ...item, status: statusToSet });

        setStatusToSet("" as Item["status"]);

        return;
      }
    }

    if (unsavedSharedStimuli.length) {
      Promise.all(
        unsavedSharedStimuli.map((sharedStimulus) =>
          updateSharedStimulus(sharedStimulus.key, {
            content: sharedStimulus.content
          })
        )
      ).then(() => {
        if (!hasUnsavedChanges) {
          if (wasBackClicked) {
            history.push("/examiner/dashboard");

            return;
          }

          setShouldSaveContent(false);

          if (statusToSet) {
            patchItem(item.key, { status: statusToSet });

            setItem({ ...item, status: statusToSet });

            setStatusToSet("" as Item["status"]);

            return;
          }
        }
      });
    }

    if (hasUnsavedChanges) {
      updateItem(item.key, {
        content: {
          ...item.content,
          ...content
        },
        metadata: item.metadata,
        skills: item.skills,
        topics: item.topics
      })
        .then(() => {
          if (wasBackClicked) {
            history.push("/examiner/dashboard");

            return;
          }

          setShouldSaveContent(false);

          if (statusToSet) {
            patchItem(item.key, { status: statusToSet }).then(() => {
              getItem<Item>(item.key, {
                includePossibleSharedStimuli: "true"
              }).then((response) => {
                setItem(response.data || ({} as Item));
              }, console.error);
            });

            setStatusToSet("" as Item["status"]);
          }
        })
        .catch(console.error);
    }

    if (!wasBackClicked) {
      setItem({ ...item, status: "contentChecked" });
    }
  };

  const handleSaveMetadata = (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ): void => {
    if (wasBackClicked) {
      if (hasUnsavedChanges) {
        updateItem(item.key, metadata)
          .then(() => {
            history.push("/examiner/dashboard");
          })
          .catch(console.error);

        return;
      }

      history.push("/examiner/dashboard");
    } else if (statusToSet) {
      if (hasUnsavedChanges) {
        updateItem(item.key, metadata)
          .then(() => {
            setShouldSaveMetadata(false);

            patchItem(item.key, { status: statusToSet });

            setItem({
              ...item,
              additionalInformation: metadata.additionalInformation,
              difficulty: metadata.difficulty,
              skills: metadata.skills,
              topics: metadata.topics,
              status: statusToSet
            });

            setStatusToSet("" as Item["status"]);
          })
          .catch(console.error);

        return;
      }

      setShouldSaveMetadata(false);

      patchItem(item.key, { status: statusToSet });

      setItem({ ...item, status: statusToSet });

      setStatusToSet("" as Item["status"]);
    } else {
      setIsSubmitting({
        shouldFadeIn: true,
        value: true
      });

      updateItem(item.key, metadata)
        .then(() => {
          updateItemStatus("metadata-added", item.key)
            .then(() => {
              setShouldSaveMetadata(false);

              setTimeout(() => {
                setActiveAction("isSubmitting");
                incrementItem();
              }, 2000);
            })
            .catch(console.error);
        })
        .catch(console.error);
    }
  };

  const handleSaveSharedStimuli = (
    sharedStimuliKeys: string[],
    hasUnsavedChanges: boolean
  ): void => {
    if (!hasUnsavedChanges) {
      setShouldSaveSharedStimuli(false);

      if (wasBackClicked) {
        history.push("/examiner/dashboard");

        return;
      }

      if (statusToSet) {
        patchItem(item.key, { status: statusToSet });

        setItem({ ...item, status: statusToSet });

        setStatusToSet("" as Item["status"]);

        return;
      }
    }

    patchItem(item.key, {
      content: {
        sharedStimuli: sharedStimuliKeys
      },
      status: wasBackClicked
        ? null
        : statusToSet
        ? statusToSet
        : "sharedStimuliChecked"
    }).then(() => {
      if (wasBackClicked) {
        history.push("/examiner/dashboard");

        return;
      }

      setShouldSaveSharedStimuli(false);

      if (statusToSet) {
        setStatusToSet("" as Item["status"]);
      }

      getItem<Item>(item.key).then((response) => {
        setItem(response.data || ({} as Item));
      }, console.error);
    });
  };

  const incrementItem = (): void => {
    if (activeItemIndex === task.pendingItems.length - 1) {
      if (activeAction === "isRejecting") {
        setIsRejecting({ shouldFadeIn: false, value: false });
      } else if (activeAction === "isSubmitting") {
        setIsSubmitting({ shouldFadeIn: false, value: false });
      }

      setActiveAction(null);

      setIsTaskCompletedDialogueOpen(true);
    } else {
      setActiveItemIndex(activeItemIndex + 1);

      history.push(
        `/subject-expert/tasks/${taskKey}/items/${
          task.pendingItems[activeItemIndex + 1]
        }`
      );
    }
  };

  const navigateFromChoiceInteraction = (): void => {
    switch (item.status) {
      case "initial":
        setShouldSaveContent(true);
        break;
      case "contentChecked":
        setShouldSaveMetadata(true);
        break;
      default:
        break;
    }
  };

  const navigateFromExtendedTextOrTextEntryInteraction = (): void => {
    switch (item.status) {
      case "initial":
        setShouldSaveSharedStimuli(true);
        break;
      case "sharedStimuliChecked":
        setShouldSaveContent(true);
        break;
      case "contentChecked":
        console.log("save the mark scheme and reroute to the dashboard");
        break;
      case "markSchemeChecked":
        setShouldSaveMetadata(true);
        break;
      default:
        break;
    }
  };

  return (
    <div data-testid="subject-expert-task-page">
      {isRejecting.value && (
        <LoadingDialogue
          body="Item has been rejected"
          heading="Thank you"
          shouldFadeIn={isRejecting.shouldFadeIn}
        />
      )}
      {isSubmitting.value && (
        <LoadingDialogue
          body="Item submitted for approval"
          heading="Thank you"
          shouldFadeIn={isSubmitting.shouldFadeIn}
        />
      )}
      <TaskCompletedDialogue isOpen={isTaskCompletedDialogueOpen} />
      {Object.keys(item).length > 0 && Object.keys(task).length > 0 && (
        <>
          <TaskHeader
            activeItemIndex={activeItemIndex}
            onClickBack={handleClickBack}
            task={task}
          />
          {item.content.qtiInteractionType === "choiceInteraction" && (
            <ChoiceInteractionTask
              isSubmitting={isSubmitting.value}
              item={item}
              onClickConfirmReject={handleClickConfirmReject}
              onClickContinueFromContent={handleClickContinueFromContent}
              onClickSubmit={handleClickContinueFromMetadata}
              onClickTaskStep={handleClickTaskStep}
              onSaveContent={handleSaveContent}
              onSaveMetadata={handleSaveMetadata}
              projectKey={task.project.key}
              shouldSaveContent={shouldSaveContent}
              shouldSaveMetadata={shouldSaveMetadata}
            />
          )}
          {item.content.qtiInteractionType === "extendedTextInteraction" && (
            <ExtendedTextInteractionTask
              isSubmitting={isSubmitting.value}
              item={item}
              onClickConfirmReject={handleClickConfirmReject}
              onClickContinueFromSharedStimuli={
                handleClickContinueFromSharedStimuli
              }
              onClickContinueFromContent={handleClickContinueFromContent}
              onClickTaskStep={handleClickTaskStep}
              onSaveContent={handleSaveContent}
              onSaveMetadata={handleSaveMetadata}
              onSaveSharedStimuli={handleSaveSharedStimuli}
              projectKey={task.project.key}
              sharedStimuli={sharedStimuli}
              shouldSaveContent={shouldSaveContent}
              shouldSaveMetadata={shouldSaveMetadata}
              shouldSaveSharedStimuli={shouldSaveSharedStimuli}
              wholeQuestionImageUrls={wholeQuestionImageUrls}
            />
          )}
          {item.content.qtiInteractionType === "textEntryInteraction" && (
            <TextEntryInteractionTask
              isSubmitting={isSubmitting.value}
              item={item}
              onClickConfirmReject={handleClickConfirmReject}
              onClickContinueFromSharedStimuli={
                handleClickContinueFromSharedStimuli
              }
              onClickContinueFromContent={handleClickContinueFromContent}
              onClickTaskStep={handleClickTaskStep}
              onSaveContent={handleSaveContent}
              onSaveMetadata={handleSaveMetadata}
              onSaveSharedStimuli={handleSaveSharedStimuli}
              projectKey={task.project.key}
              sharedStimuli={sharedStimuli}
              shouldSaveContent={shouldSaveContent}
              shouldSaveMetadata={shouldSaveMetadata}
              shouldSaveSharedStimuli={shouldSaveSharedStimuli}
              wholeQuestionImageUrls={wholeQuestionImageUrls}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SubjectExpertTask;
