// Core
import React, { FC, ReactElement, useRef, useState } from "react";

// Utils
import { generateBase64FromFile } from "utils/generateBase64";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Editor as TinyMCEEditorComponent } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce/tinymce";
import { Box, Dialog, Typography } from "@material-ui/core";
import ReactCrop, { Crop } from "react-image-crop";

interface Props {
  allowImages: boolean;
  allowLists: boolean;
  allowTables: boolean;
  onChange: (value: string) => void;
  testId?: string;
  value: string;
}

const Editor: FC<Props> = (props): ReactElement => {
  const [activeImageAction, setActiveImageAction] = useState(
    "" as "insert" | "replace"
  );
  const [croppableImage, setCroppableImage] = useState(
    null as HTMLImageElement | null
  );
  const [imageCrop, setImageCrop] = useState({} as Crop);
  const [isImagineCroppingDialogueOpen, setIsImageCroppingDialogueOpen] =
    useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const clearImageCrop = (): void => {
    setImageCrop({} as Crop);
  };

  const cropImage = async () => {
    if (croppableImage && imageCrop.width && imageCrop.height) {
      const base64 = await generateBase64FromCanvas();
      const editor = editorRef.current as TinyMCEEditor;
      const image = editor.selection.getNode();

      editor.dom.setAttrib(image, "data-old-src", image.getAttribute("src"));
      editor.dom.setAttrib(image, "src", base64);

      props.onChange(editor.getContent());

      clearImageCrop();
    }
  };

  const generateBase64FromCanvas = (): Promise<string> => {
    const canvas = document.createElement("canvas");
    const image = croppableImage as HTMLImageElement;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = imageCrop.width;
    canvas.height = imageCrop.height;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        imageCrop.x * scaleX,
        imageCrop.y * scaleY,
        imageCrop.width * scaleX,
        imageCrop.height * scaleY,
        0,
        0,
        imageCrop.width,
        imageCrop.height
      );
    }

    return Promise.resolve(canvas.toDataURL("image/png"));
  };

  const getNonEditableNodesCount = (htmlString: string): number => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const nonEditableNodes = doc.body.querySelectorAll(".non-editable");

    return nonEditableNodes.length;
  };

  const handleChangeImageCrop = (newCrop: Crop): void => {
    setImageCrop(newCrop);
  };

  const handleChangeFileInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = e.target.files as FileList;

    if (!files.length) {
      return;
    }

    const editor = editorRef.current as TinyMCEEditor;
    const base64 = await generateBase64FromFile(files[0]);

    switch (activeImageAction) {
      case "insert": {
        editor.execCommand(
          "mceInsertContent",
          false,
          `<img src="${base64}" alt="figure" />`
        );
        break;
      }
      case "replace": {
        const image = editor.selection.getNode();

        image.removeAttribute("alt");
        image.removeAttribute("height");
        image.removeAttribute("width");
        editor.dom.setAttrib(image, "data-old-src", image.getAttribute("src"));
        editor.dom.setAttrib(image, "src", base64);
        editor.dom.setAttrib(image, "alt", "figure");

        break;
      }
      default:
        break;
    }
  };

  const handleClickCancelCrop = (): void => {
    clearImageCrop();
    setIsImageCroppingDialogueOpen(false);
  };

  const handleClickConfirmCrop = (): void => {
    cropImage();
    setIsImageCroppingDialogueOpen(false);
  };

  const handleImageLoaded = (image: HTMLImageElement): void => {
    setCroppableImage(image);
  };

  return (
    <Box flex="1" data-testid={props.testId ? props.testId : "editor"}>
      <Dialog
        onClose={handleClickCancelCrop}
        aria-labelledby="image-cropping-dialogue-heading"
        open={isImagineCroppingDialogueOpen}
        data-testid="image-cropping-dialogue"
      >
        <Box padding={4}>
          <Box marginBottom={2}>
            <Typography variant="h4">Crop image</Typography>
          </Box>
          <ReactCrop
            crop={imageCrop}
            crossorigin="anonymous"
            imageAlt="Croppable image"
            imageStyle={{ verticalAlign: "bottom" }}
            onChange={handleChangeImageCrop}
            onImageLoaded={handleImageLoaded}
            src={
              isImagineCroppingDialogueOpen
                ? ((editorRef.current as TinyMCEEditor).selection
                    .getNode()
                    .getAttribute("src") as string)
                : ""
            }
            style={{ verticalAlign: "bottom" }}
          />
        </Box>
        <Divider />
        <Box display="flex" padding={3}>
          <Box marginLeft="auto">
            <Box clone marginRight={2}>
              <Button
                color="primary"
                onClick={handleClickCancelCrop}
                variant="text"
                data-testid="cancel-crop-button"
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickConfirmCrop}
              variant="contained"
              data-testid="confirm-crop-button"
            >
              Crop
            </Button>
          </Box>
        </Box>
      </Dialog>
      <input
        accept="image/jpeg, image/png"
        onChange={handleChangeFileInput}
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
        data-testid="file-input"
      />
      <TinyMCEEditorComponent
        apiKey="9pc8tzpclojkfcp1b6o53kxemnn8d8tlf8hi2im2jjzxyqrc"
        id={props.testId ? `${props.testId}-input` : "editor-input"}
        inline={true}
        init={{
          content_style: "body { font-size:16px }",
          deprecation_warnings: false,
          extended_valid_elements: "b,i",
          external_plugins: {
            tiny_mce_wiris:
              "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js"
          },
          // Commenting this out as there's currently no way to reference a file in the node_modules directory. Ideally, we would reference node_modules/@wiris/mathtype-tinymce5/plugin.min.js (npm package). The only way I can get this working is by copying the plugin.min.js file from our node_modules directory and pasting it in our public directory (renamed to mathtype-tinymce5.min.js), as show below, but this feels nasty. IH 26/05/21
          // external_plugins: {
          //   tiny_mce_wiris: `${process.env.PUBLIC_URL}/mathtype-tinymce5.min.js`
          // },
          formats: {
            bold: { inline: "b" },
            italic: { inline: "i" },
            underline: { inline: "u" }
          },
          inline_boundaries: false,
          menubar: false,
          noneditable_noneditable_class: "non-editable",
          object_resizing: false,
          plugins: ["charmap", "lists", "noneditable", "table"],
          setup: (editor) => {
            let previousNonEditableNodesCount = 0;

            editor.ui.registry.addButton("cropImageButton", {
              icon: "crop",
              tooltip: "Crop image",
              onAction: () => {
                setIsImageCroppingDialogueOpen(true);
              }
            });

            editor.ui.registry.addButton("insertImageButton", {
              icon: "image",
              tooltip: "Insert image",
              onAction: () => {
                setActiveImageAction("insert");
                (fileInputRef.current as HTMLInputElement).click();
              }
            });

            editor.ui.registry.addButton("replaceImageButton", {
              icon: "browse",
              tooltip: "Replace image",
              onAction: () => {
                setActiveImageAction("replace");
                (fileInputRef.current as HTMLInputElement).click();
              }
            });

            editor.ui.registry.addButton("restoreImageButton", {
              icon: "restore-draft",
              tooltip: "Restore image",
              onAction: () => {
                const image = editor.selection.getNode();

                if (!image.getAttribute("data-original-src")) {
                  return;
                }

                editor.dom.setAttrib(
                  image,
                  "src",
                  image.getAttribute("data-original-src")
                );

                props.onChange(editor.getContent());
              }
            });

            editor.ui.registry.addContextToolbar("imageToolbar", {
              predicate: (node) => {
                return node.nodeName.toLowerCase() === "img";
              },
              items: "cropImageButton replaceImageButton restoreImageButton",
              position: "node",
              scope: "node"
            });

            editor.on("keydown", function (event) {
              if (event.key !== "Backspace" && event.key !== "Delete") {
                return;
              }

              previousNonEditableNodesCount = getNonEditableNodesCount(
                editor.getContent()
              );
              editor.undoManager.add();
            });

            editor.on("keyup", function (event) {
              if (event.key !== "Backspace" && event.key !== "Delete") {
                return;
              }

              if (
                previousNonEditableNodesCount !==
                getNonEditableNodesCount(editor.getContent())
              ) {
                editor.undoManager.undo();
              }
            });
          },
          table_advtab: false,
          table_cell_advtab: false,
          table_resize_bars: false,
          table_row_advtab: false,
          table_toolbar: "",
          toolbar: `undo | bold | italic | underline | superscript | subscript | charmap | tiny_mce_wiris_formulaEditor ${
            props.allowImages ? "| insertImageButton" : ""
          } ${props.allowLists ? "| numlist bullist" : ""} ${
            props.allowTables ? "| table" : ""
          }`
        }}
        onEditorChange={(value) => props.onChange(value)}
        onInit={(e, editor) => (editorRef.current = editor)}
        value={props.value}
      />
    </Box>
  );
};

export default Editor;
