// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ConditionalWrapper from "components/conditionalWrapper/ConditionalWrapper";
import Prompt from "components/qti/prompt/Prompt";
import Stimulus from "components/qti/stimulus/Stimulus";

// Enums
import { MessageBody, MessageHeading } from "enums";

// Interfaces
import {
  ExtendedTextInteraction as ExtendedTextInteractionInterface,
  Message,
  SharedStimulus
} from "interfaces";
import { IRagDetails } from "@qti-scraper/interfaces";

// Utils
import { getMessageLevel, getMessageInfo } from "utils/ragToMessage";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { alpha, Box, Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  content: ExtendedTextInteractionInterface;
  initialRagDetails?: IRagDetails[];
  isEditing: boolean;
  itemKey?: string;
  onSaveContent?: (
    content: {
      prompt: string;
      sharedStimuli: SharedStimulus[];
      stimulus: string;
    },
    hasUnsavedChanges: boolean
  ) => void;
  setMessages?: React.Dispatch<React.SetStateAction<Message[]>>;
  sharedStimuli: SharedStimulus[];
  shouldSaveContent?: boolean;
}

const ExtendedTextInteraction: FC<Props> = (props): ReactElement => {
  const [formFields, setFormFields] = useState({
    prompt: "",
    sharedStimuli: [],
    stimulus: ""
  } as { prompt: ExtendedTextInteractionInterface["prompt"]; sharedStimuli: SharedStimulus[]; stimulus: ExtendedTextInteractionInterface["stimulus"] });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [highlightedFormFields, setHighlightedFormFields] = useState({
    prompt: false,
    stimulus: false
  });
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tinyMCEInitialisations, setTinyMCEInitialisations] = useState(
    [] as string[]
  );
  const { setMessages } = props;

  useEffect(() => {
    if (!props.itemKey) {
      return;
    }

    setHasUnsavedChanges(false);
    setIsContentLoaded(false);
    setTinyMCEInitialisations([]);
  }, [props.itemKey]);

  useEffect(() => {
    if (!isContentLoaded || !props.setMessages) {
      return;
    }

    const hasPrompt = !!formFields.prompt;
    const hasSharedStimuli = formFields.sharedStimuli.length;
    const hasStimulus = !!formFields.stimulus;
    const messages = [] as Message[];
    let highlightPrompt = false;
    let highlightStimulus = false;

    if (props.initialRagDetails?.length) {
      props.initialRagDetails.forEach((initialRagDetail) => {
        const info = getMessageInfo(initialRagDetail.message);
        const level = getMessageLevel(initialRagDetail.status);

        if (
          !(
            info.heading === MessageHeading.noStimulus ||
            info.heading === MessageHeading.noPrompt
          )
        ) {
          switch (initialRagDetail.questionElement) {
            case "prompt":
              highlightPrompt = true;
              break;
            case "stimulus":
              highlightStimulus = true;
              break;
            default:
              break;
          }

          messages.push({
            body: info.body,
            heading: info.heading,
            level: level
          });
        }
      });
    }

    if (!hasStimulus) {
      messages.push({
        body: MessageBody.noStimulus,
        heading: MessageHeading.noStimulus,
        level: "information"
      });
      highlightStimulus = true;
    }

    if (!hasPrompt) {
      messages.push({
        body: MessageBody.noPrompt,
        heading: MessageHeading.noPrompt,
        level: "error"
      });
      highlightPrompt = true;
    }

    if (hasSharedStimuli) {
      messages.push({
        body: MessageBody.sharedStimulus,
        heading: MessageHeading.sharedStimulus,
        level: "information"
      });
    }

    setHighlightedFormFields({
      prompt: highlightPrompt,
      stimulus: highlightStimulus
    });

    props.setMessages(messages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields, setMessages]);

  useEffect(() => {
    setFormFields({
      prompt: props.content.prompt,
      sharedStimuli: props.sharedStimuli || [],
      stimulus: props.content.stimulus
    });

    setIsContentLoaded(true);
  }, [props.content, props.sharedStimuli]);

  useEffect(() => {
    if (isEditing && props.isEditing) {
      return;
    }

    if (props.isEditing) {
      setIsEditing(true);
    }

    if (isEditing && !props.isEditing) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditing]);

  useEffect(() => {
    if (!props.shouldSaveContent) {
      return;
    }

    props.onSaveContent &&
      props.onSaveContent(
        {
          prompt: formFields.prompt,
          sharedStimuli: formFields.sharedStimuli,
          stimulus: formFields.stimulus
        },
        hasUnsavedChanges
      );

    setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldSaveContent]);

  const handleEditFormField = (
    name: "prompt" | "stimulus",
    value: string
  ): void => {
    setFormFields({
      ...formFields,
      [name]: value
    });

    if (!tinyMCEInitialisations.includes(name)) {
      setTinyMCEInitialisations([...tinyMCEInitialisations, name]);
    } else {
      setHasUnsavedChanges(true);
    }
  };

  const handleEditNestedFormField = (
    name: "sharedStimuli",
    index: number,
    value: string
  ): void => {
    const copy = [...formFields[name]];

    copy[index].content = value;

    setFormFields({
      ...formFields,
      [name]: copy
    });

    if (
      tinyMCEInitialisations.filter(
        (tinyMCEInitialisation) => tinyMCEInitialisation === name
      ).length !== formFields[name].length
    ) {
      setTinyMCEInitialisations([...tinyMCEInitialisations, name]);
    } else {
      copy[index].hasUnsavedChanges = true;
    }
  };

  return (
    <div data-testid="extended-text-interaction">
      <ConditionalWrapper
        shouldWrap={!props.isEditing}
        wrapper={(children) => (
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        )}
      >
        <>
          {formFields.sharedStimuli && (
            <>
              {formFields.sharedStimuli.map((sharedStimulus, index) => (
                <ConditionalWrapper
                  key={index}
                  shouldWrap={props.isEditing}
                  wrapper={(children) => (
                    <Box marginBottom={4}>
                      <Box marginBottom={2}>
                        <Typography component="h2" variant="h5">
                          Shared stimulus
                        </Typography>
                      </Box>
                      <Card>
                        <CardContent>{children}</CardContent>
                      </Card>
                    </Box>
                  )}
                >
                  <Stimulus
                    index={index}
                    isEditing={props.isEditing}
                    isShared
                    onChange={(value: string) =>
                      handleEditNestedFormField("sharedStimuli", index, value)
                    }
                    stimulus={formFields.sharedStimuli[index].content}
                  />
                </ConditionalWrapper>
              ))}
            </>
          )}
          <ConditionalWrapper
            shouldWrap={props.isEditing}
            wrapper={(children) => (
              <Box marginBottom={4}>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Stimulus
                  </Typography>
                </Box>
                <Card
                  style={{
                    border: !highlightedFormFields.stimulus
                      ? "0 none"
                      : `3px solid ${alpha(colours.informative, 0.49)}`
                  }}
                >
                  <CardContent>{children}</CardContent>
                </Card>
              </Box>
            )}
          >
            <Stimulus
              isEditing={props.isEditing}
              onChange={(value: string) =>
                handleEditFormField("stimulus", value)
              }
              stimulus={formFields.stimulus}
            />
          </ConditionalWrapper>
          <ConditionalWrapper
            shouldWrap={props.isEditing}
            wrapper={(children) => (
              <Box marginBottom={4}>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Prompt
                  </Typography>
                </Box>
                <Card
                  style={{
                    border: !highlightedFormFields.prompt
                      ? "0 none"
                      : `3px solid ${alpha(colours.pdfRed, 0.49)}`
                  }}
                >
                  <CardContent>{children}</CardContent>
                </Card>
              </Box>
            )}
          >
            <Prompt
              content={formFields.prompt}
              isEditing={isEditing}
              onChange={(value: string) => handleEditFormField("prompt", value)}
            />
          </ConditionalWrapper>
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default ExtendedTextInteraction;
