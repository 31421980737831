// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Interfaces
import { ISubjectInfo } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import {
  Button,
  Divider,
  Grid,
  TextField
} from "@cambridgeassessment/cambridge-ui";
import { Box, Typography, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const SyllabusCode: FC = (): ReactElement => {
  const { getSubjectInfo, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeApiCalls, setActiveApiCalls] = useState([] as string[]);
  const [activeSyllabus, setActiveSyllabus] = useState({} as ISubjectInfo);
  const [subjectInfo, setSubjectInfo] = useState([] as ISubjectInfo[]);
  const history = useHistory();

  useEffect(() => {
    getSubjectInfo<ISubjectInfo[]>().then((response) => {
      setSubjectInfo(response.data as ISubjectInfo[]);
    });
  }, [getSubjectInfo]);

  const handleChangeSyllabusesAutocomplete = (
    event: ChangeEvent<unknown>,
    option: ISubjectInfo | null
  ): void => {
    if (!option) {
      setActiveSyllabus({} as ISubjectInfo);

      return;
    }

    setActiveSyllabus(option);
  };

  const handleClickContinue = (): void => {
    setActiveApiCalls([...activeApiCalls, "updateProject"]);

    updateProject(project.key, {
      ...project,
      syllabusCode: activeSyllabus.syllabusCode,
      topicsKeywordsTaxonomyIds: activeSyllabus.topicsKeywordsTaxonomyIds,
      skillsKeywordsTaxonomyIds: activeSyllabus.skillsKeywordsTaxonomyIds
    })
      .then(() => {
        updateProjectSuccess({
          ...project,
          syllabusCode: activeSyllabus.syllabusCode
        });

        history.push(`/projects/${project.key}/edit/upload`);
      })
      .catch(() => {
        setActiveApiCalls(
          activeApiCalls.filter((apiCall) => apiCall !== "updateProject")
        );

        console.error();
      });
  };

  return (
    <div data-testid="syllabus-code-page">
      <Box marginBottom={4}>
        <Box display="flex" marginBottom={4}>
          <Box>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Enter syllabus code
              </Typography>
            </Box>
            <Typography data-testid="page-introduction">
              You will be able to upload past papers for only this syllabus
            </Typography>
          </Box>
          <Box marginLeft="auto">
            <Button
              color="primary"
              disableElevation
              disabled={
                activeApiCalls.includes("updateProject") ||
                !Object.keys(activeSyllabus).length
              }
              onClick={handleClickContinue}
              data-testid="continue-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Grid container>
        <Grid item sm={12} md={4} lg={4} xl={4}>
          <Autocomplete
            disabled={!subjectInfo || !subjectInfo.length}
            filterSelectedOptions
            getOptionLabel={(option) => option.syllabusFullName}
            onChange={handleChangeSyllabusesAutocomplete}
            options={subjectInfo}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "syllabuses-input"
                }}
                placeholder={
                  !subjectInfo || !subjectInfo.length
                    ? "Loading syllabus data..."
                    : "Search for a syllabus"
                }
              />
            )}
            data-testid={"syllabuses-autocomplete"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SyllabusCode;
