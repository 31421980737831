// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Card, CardContent } from "@material-ui/core";

interface Props {
  url: string;
}

const PdfPreview: FC<Props> = (props): ReactElement => {
  return (
    <Card data-testid="pdf-preview">
      <CardContent style={{ padding: "16px 0" }}>
        <img src={`${props.url}`} />
      </CardContent>
    </Card>
  );
};

export default PdfPreview;
