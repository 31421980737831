const dashOrSpaceRegEx = new RegExp("[\\-\\s]", "g");

const getFileGroup = (file: File): string => {
  const month = monthRegEx.exec(file.name);

  return file.name
    .replace(dashOrSpaceRegEx, "_")
    .replace(paperTypeRegex, "_*_")
    .replace(
      monthRegEx,
      new Date(`${month ? month[0] : "Jan"} 01 2000`).toLocaleDateString("en", {
        month: "long"
      })
    )
    .replace(".pdf", "");
};

const monthRegEx = new RegExp(
  "(janu?a?r?y?)|(febr?u?a?r?y?)|(marc?h?)|(apri?l?)|(may)|(june?)|(july?)|(augu?s?t?)|(sept?e?m?b?e?r?)|(octo?b?e?r?)|(nove?m?b?e?r?)|(dece?m?b?e?r?)",
  "i"
);

const paperTypeRegex = new RegExp(
  "[_\\-\\s]ms[_\\-\\s]|[_\\-\\s]qp[_\\-\\s]",
  "i"
);

const validCIFilenameRegEx = new RegExp(
  "^\\d{4}_[msw]\\d{2}_(ms|qp)_\\d{2}.pdf$"
);

const validOCRFilenameRegEx = new RegExp(
  "^[HJRY\\d]\\d{3}[_\\-\\s]\\d{2}[_\\-\\s](MS|QP)[_\\-\\s][A-Za-z]+\\d{2}.pdf$"
);

export { getFileGroup, validCIFilenameRegEx, validOCRFilenameRegEx };
