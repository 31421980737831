export enum MessageHeading {
  incorrectNumberOfResponses = "Incorrect number of responses",
  noItemContent = "No item content",
  noPrompt = "No prompt",
  noStimulus = "No stimulus",
  missingStyling = "Missing styling",
  missingCharacters = "Missing characters",
  incorrectTable = "Possibly incorrect table",
  sharedStimulus = "Shared stimulus detected"
}
