// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ExtendedTextOrTextEntryInteractionTaskStepper from "components/extendedTextOrTextEntryInteractionTaskStepper/ExtendedTextOrTextEntryInteractionTaskStepper";
import InitialExtendedTextOrTextEntryInteractionHeader from "pages/subjectExpertTask/components/initialExtendedTextOrTextEntryInteractionHeader/InitialExtendedTextOrTextEntryInteractionHeader";
import ItemInfo from "components/itemInfo/ItemInfo";
import Message from "components/message/Message";
import PdfPreview from "components/pdfPreview/PdfPreview";
import PossibleSharedStimulus from "components/possibleSharedStimulus/PossibleSharedStimulus";
import RejectContentDialogue from "components/rejectContentDialogue/RejectContentDialogue";
import RejectSharedStimuliDialogue from "components/rejectSharedStimuliDialogue/RejectSharedStimuliDialogue";
import SharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader from "pages/subjectExpertTask/components/sharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader/SharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader";
import TextEntryInteraction from "components/qti/textEntryInteraction/TextEntryInteraction";

// Interfaces
import {
  Item,
  Message as MessageInterface,
  SharedStimulus,
  TextEntryInteraction as TextEntryInteractionInterface
} from "interfaces";
import { IPossibleSharedStimulus } from "@qti-scraper/interfaces";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Grid, Typography } from "@material-ui/core";

interface Props {
  isSubmitting: boolean;
  item: Item;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickConfirmReject: (body: any) => void;
  onClickContinueFromContent: () => void;
  onClickContinueFromSharedStimuli: () => void;
  onClickTaskStep: (status: Item["status"]) => void;
  onSaveContent: (
    content: {
      content: string;
      sharedStimuli: SharedStimulus[];
    },
    hasUnsavedChanges: boolean
  ) => void;
  onSaveMetadata: (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ) => void;
  onSaveSharedStimuli: (
    sharedStimuliKeys: string[],
    hasUnsavedChanges: boolean
  ) => void;
  projectKey: string;
  sharedStimuli: SharedStimulus[];
  shouldSaveContent: boolean;
  shouldSaveMetadata: boolean;
  shouldSaveSharedStimuli: boolean;
  wholeQuestionImageUrls: string[];
}

const TextEntryInteractionTask: FC<Props> = (props): ReactElement => {
  const [addedStimuli, setAddedStimuli] = useState(
    [] as IPossibleSharedStimulus[]
  );
  // const [hasMandatoryMetadata, setHasMandatoryMetadata] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isRejectContentDialogueOpen, setIsRejectContentDialogueOpen] =
    useState(false);
  const [
    isRejectSharedStimuliDialogueOpen,
    setIsRejectSharedStimuliDialogueOpen
  ] = useState(false);
  const [messages, setMessages] = useState([] as MessageInterface[]);

  useEffect(() => {
    const possibleSharedStimuli = props.item.possibleSharedStimuli;
    const sharedStimuli = (props.item.content as TextEntryInteractionInterface)
      .sharedStimuli;

    if (!possibleSharedStimuli || !sharedStimuli) {
      return;
    }

    setAddedStimuli(
      sharedStimuli.map((sharedStimulus) => ({
        isolatedImage:
          possibleSharedStimuli.find(
            (possibleSharedStimulus) =>
              possibleSharedStimulus.key === sharedStimulus
          )?.isolatedImage || "",
        key: sharedStimulus
      }))
    );
  }, [props.item]);

  useEffect(() => {
    if (!props.shouldSaveSharedStimuli) {
      return;
    }

    props.onSaveSharedStimuli(
      addedStimuli.map((addedStimulus) => addedStimulus.key),
      hasUnsavedChanges
    );

    setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldSaveSharedStimuli]);

  useEffect(() => {
    setIsEditing(props.item.status === "sharedStimuliChecked" ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.status]);

  // const handleChangeMandatoryMetadata = (value: boolean): void => {
  //   setHasMandatoryMetadata(value);
  // };

  const handleClickAddOrRemoveStimulus = (
    stimulus: IPossibleSharedStimulus,
    action: "add" | "remove"
  ): void => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }

    if (action === "add") {
      setAddedStimuli([...addedStimuli, stimulus]);
    } else {
      setAddedStimuli(
        addedStimuli.filter(
          (addedStimulus) => addedStimulus.key !== stimulus.key
        )
      );
    }
  };

  const handleClickRejectContent = (): void => {
    setIsRejectContentDialogueOpen(true);
  };

  const handleClickRejectSharedStimuli = (): void => {
    setIsRejectSharedStimuliDialogueOpen(true);
  };

  return (
    <div data-testid="text-entry-interaction-task">
      <RejectContentDialogue
        isOpen={isRejectContentDialogueOpen}
        onClickClose={() => setIsRejectContentDialogueOpen(false)}
        onClickConfirmReject={props.onClickConfirmReject}
      />
      <RejectSharedStimuliDialogue
        isOpen={isRejectSharedStimuliDialogueOpen}
        onClickClose={() => setIsRejectSharedStimuliDialogueOpen(false)}
        onClickConfirmReject={props.onClickConfirmReject}
      />
      <ExtendedTextOrTextEntryInteractionTaskStepper
        item={props.item}
        onClickTaskStep={props.onClickTaskStep}
      />
      <Box alignItems="start" display="flex" marginBottom={4}>
        {props.item.status === "initial" && (
          <InitialExtendedTextOrTextEntryInteractionHeader
            onClickContinue={props.onClickContinueFromSharedStimuli}
            onClickReject={handleClickRejectSharedStimuli}
            wholeQuestionImageUrls={props.wholeQuestionImageUrls}
          />
        )}
        {props.item.status === "sharedStimuliChecked" && (
          <SharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader
            onClickReject={handleClickRejectContent}
            onClickSaveAndContinue={props.onClickContinueFromContent}
          />
        )}
      </Box>
      <Divider />
      {props.item.status === "sharedStimuliChecked" && (
        <>
          {messages.map((message) => (
            <Message
              body={message.body}
              heading={message.heading}
              key={message.body}
              level={message.level}
            />
          ))}
        </>
      )}
      <Box marginTop={5}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            {props.item.status === "initial" && (
              <>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Item
                  </Typography>
                </Box>
                {props.item.possibleSharedStimuli &&
                  props.item.possibleSharedStimuli
                    .filter((possibleSharedStimulus) =>
                      addedStimuli
                        .map((addedStimulus) => addedStimulus.key)
                        .includes(possibleSharedStimulus.key)
                    )
                    .map((possibleSharedStimulus) => (
                      <PossibleSharedStimulus
                        key={possibleSharedStimulus.key}
                        onClickAddOrRemoveStimulus={
                          handleClickAddOrRemoveStimulus
                        }
                        stimulus={possibleSharedStimulus}
                        type="added"
                      />
                    ))}
                <PdfPreview url={props.item.isolatedImage} />
              </>
            )}
            {(props.item.status === "sharedStimuliChecked" ||
              props.item.status === "markSchemeChecked") && (
              <>
                {!isEditing && (
                  <Box marginBottom={2}>
                    <Typography component="h2" variant="h5">
                      Extracted item
                    </Typography>
                  </Box>
                )}
                <TextEntryInteraction
                  content={props.item.content as TextEntryInteractionInterface}
                  initialRagDetails={props.item.initialRagDetails}
                  isEditing={isEditing}
                  itemKey={props.item.key}
                  onSaveContent={props.onSaveContent}
                  setMessages={setMessages}
                  sharedStimuli={props.sharedStimuli}
                  shouldSaveContent={props.shouldSaveContent}
                />
              </>
            )}
            {props.item.status === "contentChecked" && (
              <div>mark scheme stuff</div>
            )}
          </Grid>
          <Grid item xs={6}>
            {props.item.status === "initial" && (
              <>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Optional parts
                  </Typography>
                </Box>
                {props.item.possibleSharedStimuli &&
                  props.item.possibleSharedStimuli
                    .filter(
                      (possibleSharedStimulus) =>
                        !addedStimuli
                          .map((addedStimulus) => addedStimulus.key)
                          .includes(possibleSharedStimulus.key)
                    )
                    .map((possibleSharedStimulus) => (
                      <PossibleSharedStimulus
                        key={possibleSharedStimulus.key}
                        onClickAddOrRemoveStimulus={
                          handleClickAddOrRemoveStimulus
                        }
                        stimulus={possibleSharedStimulus}
                        type="available"
                      />
                    ))}
                {props.item.possibleSharedStimuli &&
                  props.item.possibleSharedStimuli.filter(
                    (possibleSharedStimulus) =>
                      !addedStimuli
                        .map((addedStimulus) => addedStimulus.key)
                        .includes(possibleSharedStimulus.key)
                  ).length === 0 && (
                    <Typography data-testid="no-available-possible-shared-stimuli">
                      No additional parts left to add.
                    </Typography>
                  )}
              </>
            )}
            {props.item.status === "sharedStimuliChecked" && (
              <>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Original item
                  </Typography>
                </Box>
                <PdfPreview url={props.item.isolatedImage} />
              </>
            )}
            {props.item.status === "contentChecked" && <div>Mark scheme</div>}
            {props.item.status === "markSchemeChecked" && (
              <ItemInfo
                isEditing={true}
                item={props.item}
                onSaveMetadata={props.onSaveMetadata}
                projectKey={props.projectKey}
                shouldSaveMetadata={props.shouldSaveMetadata}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TextEntryInteractionTask;
