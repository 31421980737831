// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";

interface Props {
  isSubmitDisabled: boolean;
  onClickSubmit: () => void;
}

const ContentCheckedChoiceInteractionHeader: FC<Props> = (
  props
): ReactElement => {
  return (
    <Box
      display="flex"
      width={1}
      data-testid="content-checked-choice-interaction-header"
    >
      <Box>
        <Box marginBottom={1}>
          <Typography variant="h4" data-testid="instructions">
            Add info
          </Typography>
        </Box>
        <Typography>
          Add relevant topic(s), skill(s), and other information about this
          item.
        </Typography>
      </Box>
      <Box marginLeft="auto">
        <Button
          color="primary"
          disableElevation
          disabled={props.isSubmitDisabled}
          onClick={props.onClickSubmit}
          data-testid="submit-button"
        >
          Submit this item
        </Button>
      </Box>
    </Box>
  );
};

export default ContentCheckedChoiceInteractionHeader;
