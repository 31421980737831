// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Assets
import initialFigure from "assets/images/subject-expert-onboarding/initial-figure.svg";
import stepOneArrows from "assets/images/subject-expert-onboarding/step-one-arrows.svg";
import stepOnePdf from "assets/images/subject-expert-onboarding/step-one-pdf.svg";
import stepOneItem from "assets/images/subject-expert-onboarding/step-one-item.svg";
import stepTwoArrow from "assets/images/subject-expert-onboarding/step-two-arrow.svg";
import stepTwoPdf from "assets/images/subject-expert-onboarding/step-two-pdf.svg";
import stepTwoItemEditing from "assets/images/subject-expert-onboarding/step-two-item-editing.svg";
import stepThreeItemEditing from "assets/images/subject-expert-onboarding/step-three-item-editing.svg";
import stepFourArrow from "assets/images/subject-expert-onboarding/step-four-arrow.svg";
import stepFourItem from "assets/images/subject-expert-onboarding/step-four-item.svg";
import stepFourTopicsSkills from "assets/images/subject-expert-onboarding/step-four-topics-skills.svg";
import stepFiveArrow from "assets/images/subject-expert-onboarding/step-five-arrow.svg";
import stepFiveSubmitting from "assets/images/subject-expert-onboarding/step-five-submitting.svg";

// Components
import OnboardingStepHeader from "components/onboardingStepHeader/OnboardingStepHeader";
import OnboardingStepNavigation from "components/onboardingStepNavigation/OnboardingStepNavigation";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { StaticContext } from "react-router";
import { RouteComponentProps, useHistory } from "react-router-dom";

interface LocationState {
  taskUrl?: string;
}

const SubjectExpertOnboarding: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (props): ReactElement => {
  const { updateUser } = useApi();
  const [activeStep, setActiveStep] = useState(0);
  const [taskUrl, setTaskUrl] = useState("");
  const history = useHistory();
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    document.body.classList.add("onboarding");

    return () => {
      document.body.classList.remove("onboarding");
    };
  }, []);

  useEffect(() => {
    if (!props.location.state || !props.location.state.taskUrl) {
      return;
    }

    setTaskUrl(props.location.state.taskUrl);
  }, [props]);

  const handleClickComplete = (): void => {
    updateUser("subjectExpert", true).then(() => {
      history.push(taskUrl ? taskUrl : "/examiner/dashboard");
    });
  };

  const handleClickDot = (index: number): void => {
    setActiveStep(index);
  };

  const handleClickNext = (): void => {
    setActiveStep(activeStep + 1);
  };

  const handleClickPrevious = (): void => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div data-testid="subject-expert-onboarding-page">
      {activeStep === 0 && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isSmScreen ? "column" : "row"}
          paddingX={isSmScreen ? 0 : 12}
        >
          <Box
            clone
            marginBottom={isSmScreen ? 12 : 0}
            marginRight={isSmScreen ? 0 : 12}
          >
            <img alt="Two people having a conversation" src={initialFigure} />
          </Box>
          <Box>
            <Box marginBottom={4}>
              <Typography variant="h2">Welcome,</Typography>
            </Box>
            <Box marginBottom={12}>
              <Typography component="p" variant="h5">
                Before you start, let us guide you through our tool.
              </Typography>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickNext}
              data-testid="continue-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
      {activeStep !== 0 && (
        <Box marginBottom={20} data-testid={`step-${activeStep}`}>
          {activeStep === 1 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Items will be presented in sections (
                    <span style={{ textDecoration: "underline" }}>
                      stimulus
                    </span>
                    ,{" "}
                    <span style={{ textDecoration: "underline" }}>prompt</span>{" "}
                    and{" "}
                    <span style={{ textDecoration: "underline" }}>
                      responses
                    </span>
                    ). Make sure all the content sits in the right place.
                  </>
                }
                chipLabel="Sections"
                heading="Checking the extracted items"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 7}
                >
                  <img alt="QTI rendering in HTML" src={stepOneItem} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-6}>
                    <ArrowUpward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={7}>
                    <img alt="Arrows" src={stepOneArrows} />
                  </Box>
                )}
                <Box>
                  <img alt="Question paper PDF" src={stepOnePdf} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Sometimes text and styling{" "}
                    <span style={{ textDecoration: "underline" }}>may not</span>{" "}
                    match the original item due to some technical issues. In
                    that case, you need to edit them.
                  </>
                }
                chipLabel="Editing"
                heading="Checking the text and styling"
                marginBottom={3}
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box marginBottom={isSmScreen ? 4 : 0}>
                  <img alt="Editing an item" src={stepTwoItemEditing} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-4}>
                    <ArrowUpward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={2}>
                    <img alt="Arrow" src={stepTwoArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="Question paper PDF" src={stepTwoPdf} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 3 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Sometimes images and diagrams{" "}
                    <span style={{ textDecoration: "underline" }}>may not</span>{" "}
                    match the original item due to some technical issues. In
                    that case you need to move, delete or replace them.
                  </>
                }
                chipLabel="Editing"
                heading="Checking the images and diagrams"
              />
              <Box display="flex" justifyContent="center">
                <img alt="Editing an item" src={stepThreeItemEditing} />
              </Box>
            </>
          )}
          {activeStep === 4 && (
            <>
              <OnboardingStepHeader
                body="Adding the most relevant topics and skills will help banking the items properly, so all items will be more accessible by search later."
                chipLabel="Topics and skills"
                heading="Set the correct taxonomy"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 2}
                >
                  <img alt="QTI rendering in HTML" src={stepFourItem} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4} marginTop={-6}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={2}>
                    <img alt="Arrow" src={stepFourArrow} />
                  </Box>
                )}
                <Box>
                  <img
                    alt="Adding topics and skills"
                    src={stepFourTopicsSkills}
                  />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 5 && (
            <>
              <OnboardingStepHeader
                body="This is an assisted automation process. You will fix any mismatch between the original and extracted content. If there is a strong reason, then rejection is available."
                chipLabel="Finishing"
                heading="Your job is to submit more"
                marginBottom={2}
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box marginBottom={-1} zIndex={1}>
                  <img alt="Arrow" src={stepFiveArrow} />
                </Box>
                <Box>
                  <img alt="Submitting an item" src={stepFiveSubmitting} />
                </Box>
                <Typography>
                  You can always access this onboarding content later from your
                  dashboard
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
      {activeStep > 0 && (
        <OnboardingStepNavigation
          activeStep={activeStep}
          isSmScreen={isSmScreen}
          onClickComplete={handleClickComplete}
          onClickDot={handleClickDot}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
          stepCount={5}
          userRole="examiner"
        />
      )}
    </div>
  );
};

export default SubjectExpertOnboarding;
