// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";

// Components
import Job from "components/job/Job";
import Message from "components/message/Message";
import Summary from "pages/projects/uploads/components/Summary/Summary";

// Interfaces
import { IJob, IProject, ISubjectInfo } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { useInterval } from "utils/useInterval";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { StaticContext } from "react-router";
import { RouteComponentProps, useHistory } from "react-router-dom";

interface LocationState {
  businessUnit?: ISubjectInfo["businessUnit"];
  jobs?: IJob[];
}

const Uploads: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (props): ReactElement => {
  const { getJobsByProject, getProject, getSubjectInfo } = useApi();
  const { fetchProjectSuccess, project, updateProjectSuccess } = useProject();
  const [businessUnit, setBusinessUnit] = useState(
    "" as ISubjectInfo["businessUnit"]
  );
  const [failedJobs, setFailedJobs] = useState([] as IJob[]);
  const [finishedJobs, setFinishedJobs] = useState([] as IJob[]);
  const [isInitialAPICallMade, setIsInitialAPICallMade] = useState(false);
  const [sentForHarvestingJobs, setSentForHarvestingJobs] = useState(
    [] as IJob[]
  );
  const [shouldCancelInterval, setShouldCancelInterval] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!props.location.state || !props.location.state.businessUnit) {
      return;
    }

    setBusinessUnit(props.location.state.businessUnit);
  }, [props]);

  useEffect(() => {
    if (!props.location.state || !props.location.state.jobs) {
      return;
    }

    setFailedJobs(
      props.location.state.jobs.filter((job) => job.status === "failed")
    );
    setFinishedJobs(
      props.location.state.jobs.filter((job) => job.status === "finished")
    );
    setSentForHarvestingJobs(
      props.location.state.jobs.filter(
        (job) => job.status === "sentForHarvesting"
      )
    );
  }, [props]);

  const getJobsAndProject = useCallback(
    (projectKey: string) => {
      if (!projectKey) {
        return;
      }

      Promise.all([
        getJobsByProject<IJob[]>(projectKey, { status: "failed" }),
        getJobsByProject<IJob[]>(projectKey, { status: "finished" }),
        getJobsByProject<IJob[]>(projectKey, {
          status: "sentForHarvesting"
        }),
        getProject<IProject>(projectKey)
      ])
        .then((response) => {
          if (!isInitialAPICallMade) {
            setIsInitialAPICallMade(true);
          }

          setFailedJobs(response[0].data || []);
          setFinishedJobs(response[1].data || []);
          setSentForHarvestingJobs(response[2].data || []);
          fetchProjectSuccess(response[3].data || ({} as IProject));
          updateProjectSuccess(response[3].data || ({} as IProject));
        })
        .catch(() => setShouldCancelInterval(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchProjectSuccess, getJobsByProject, getProject]
  );

  useEffect(() => {
    if (
      isInitialAPICallMade ||
      !businessUnit ||
      !Object.keys(project).length ||
      (props.location.state && props.location.state.jobs)
    ) {
      return;
    }

    getJobsAndProject(project.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnit, isInitialAPICallMade, project]);

  useInterval(
    getJobsAndProject,
    shouldCancelInterval ? null : 5000,
    project.key
  );

  useEffect(() => {
    if (businessUnit || !Object.keys(project).length) {
      return;
    }

    getSubjectInfo<ISubjectInfo[]>().then((response) => {
      setBusinessUnit(
        response.data?.filter(
          (item) => item.syllabusCode === project.syllabusCode
        )[0].businessUnit as ISubjectInfo["businessUnit"]
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnit, getSubjectInfo]);

  const handleClickContinue = (): void => {
    history.push(`/projects/${project.key}/edit/subject-experts`);
  };

  return (
    <div data-testid="uploads-page">
      <Box display="flex" marginBottom={4}>
        <Box>
          <Box marginBottom={1}>
            <Typography component="h2" variant="h4" data-testid="page-heading">
              Item harvesting
            </Typography>
          </Box>
          <Typography data-testid="page-introduction">
            Extracting assessment content from the question papers and mark
            schemes below
          </Typography>
        </Box>
        <Box marginLeft="auto">
          <Button
            color="primary"
            disableElevation
            disabled={
              failedJobs.concat(finishedJobs).concat(sentForHarvestingJobs)
                .length === 0 ||
              finishedJobs.filter(
                (finishedJob) => finishedJob.acceptedItems > 0
              ).length === 0 ||
              sentForHarvestingJobs.length > 0
            }
            onClick={handleClickContinue}
            data-testid="continue-button"
          >
            Continue
          </Button>
        </Box>
      </Box>
      <Box marginBottom={6}>
        <Divider />
        {failedJobs.concat(finishedJobs).concat(sentForHarvestingJobs).length >
          0 &&
          project.items > 0 && (
            <>
              <Summary jobs={finishedJobs} items={project.items} />
              <Divider />
            </>
          )}
        {failedJobs.concat(finishedJobs).concat(sentForHarvestingJobs)
          .length === 0 && (
          <Message
            body={
              <Box alignContent="center" display="flex">
                <Box marginRight={2}>
                  <Typography>Loading jobs...</Typography>
                </Box>
                <CircularProgress size={18} />
              </Box>
            }
            level="information"
            testId="loading-jobs-message"
          />
        )}
        {failedJobs.concat(
          finishedJobs.filter((finishedJob) => finishedJob.acceptedItems === 0)
        ).length > 0 && (
          <Message
            body={
              sentForHarvestingJobs.length > 0
                ? `Currently, we cannot harvest ${
                    failedJobs.concat(
                      finishedJobs.filter(
                        (finishedJob) => finishedJob.acceptedItems === 0
                      )
                    ).length === 1
                      ? "this paper"
                      : "these papers"
                  }.`
                : finishedJobs.filter(
                    (finishedJob) => finishedJob.acceptedItems > 0
                  ).length === 0
                ? "Currently, we cannot harvest any of these papers and it's not possible for you to continue. You should delete this project from your dashboard."
                : finishedJobs.filter(
                    (finishedJob) => finishedJob.acceptedItems > 0
                  ).length > 0
                ? `Currently, we cannot harvest ${
                    failedJobs.concat(
                      finishedJobs.filter(
                        (finishedJob) => finishedJob.acceptedItems === 0
                      )
                    ).length === 1
                      ? "this paper"
                      : "these papers"
                  }. Please continue with the successful papers.`
                : `Currently, we cannot harvest ${
                    failedJobs.concat(
                      finishedJobs.filter(
                        (finishedJob) => finishedJob.acceptedItems === 0
                      )
                    ).length === 1
                      ? "this paper"
                      : "these papers"
                  }.`
            }
            heading={`${
              failedJobs.concat(
                finishedJobs.filter(
                  (finishedJob) => finishedJob.acceptedItems === 0
                )
              ).length
            } ${
              failedJobs.concat(
                finishedJobs.filter(
                  (finishedJob) => finishedJob.acceptedItems === 0
                )
              ).length === 1
                ? "paper"
                : "papers"
            } failed`}
            level="error"
            testId="failed-jobs-message"
          />
        )}
        {sentForHarvestingJobs.length > 0 && (
          <Message
            body={
              <Box alignContent="center" display="flex">
                <Box marginRight={2}>
                  <Typography>
                    {sentForHarvestingJobs.length}{" "}
                    {sentForHarvestingJobs.length === 1
                      ? "paper is"
                      : "papers are"}{" "}
                    in progress
                  </Typography>
                </Box>
                <CircularProgress size={18} />
              </Box>
            }
            level="information"
            testId="sent-for-harvesting-progress-message"
          />
        )}
      </Box>
      {finishedJobs
        .filter((finishedJob) => finishedJob.acceptedItems > 0)
        .map((finishedJob) => (
          <Job
            businessUnit={businessUnit}
            detail={true}
            job={finishedJob}
            key={finishedJob.key}
          />
        ))}
      {sentForHarvestingJobs.map((sentForHarvestingJob) => (
        <Job
          businessUnit={businessUnit}
          chipColour="primary"
          chipLabel="Harvesting..."
          job={sentForHarvestingJob}
          key={sentForHarvestingJob.key}
        />
      ))}
      {failedJobs
        .concat(
          finishedJobs.filter((finishedJob) => finishedJob.acceptedItems === 0)
        )
        .map((failedJob) => (
          <Job
            businessUnit={businessUnit}
            chipColour="secondary"
            chipLabel="Harvesting failed"
            job={failedJob}
            key={failedJob.key}
          />
        ))}
    </div>
  );
};

export default Uploads;
