// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";

interface Props {
  project: IProject;
}

const ApproversCard: FC<Props> = (props): ReactElement => {
  let completedPercentage = Math.round(
    ((props.project.bankedItems + props.project.rejectedItems) /
      props.project.items) *
      100
  );
  let differencePercentage = Math.round(
    (props.project.approversItems / props.project.items -
      (props.project.bankedItems + props.project.rejectedItems) /
        props.project.items) *
      100
  );

  const sharedClasses = sharedStyles();

  if (isNaN(completedPercentage)) {
    completedPercentage = 0;
  }

  if (isNaN(differencePercentage)) {
    differencePercentage = 0;
  }

  return (
    <Card data-testid="approvers-card">
      <CardContent>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={6} md={3}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Approvers
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="approvers"
              style={{ wordBreak: "break-word" }}
            >
              {props.project.approvers.map(
                (approver, index) =>
                  `${approver.email}${
                    index === props.project.approvers.length - 1 ? "" : ", "
                  }`
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Completed items
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="completed-items"
            >
              {props.project.bankedItems + props.project.rejectedItems} /{" "}
              {props.project.items}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box alignItems="center" display="flex">
              <Box marginRight={4} style={{ width: "calc(100% - 60px)" }}>
                <Box
                  bgcolor={colours.monochromeLight}
                  borderRadius="2px"
                  height="8px"
                  overflow="hidden"
                  position="relative"
                >
                  <Box
                    bgcolor={colours.corporateBlue}
                    height={1}
                    position="absolute"
                    left={0}
                    width={`${completedPercentage}%`}
                  ></Box>
                  <Box
                    bgcolor={colours.corporateBlueLightest}
                    height={1}
                    position="absolute"
                    left={`${completedPercentage}%`}
                    style={{ opacity: 0.59 }}
                    width={`${differencePercentage}%`}
                  ></Box>
                </Box>
              </Box>
              <Box display="flex" minWidth={60}>
                <Box marginRight={1}>
                  <Typography component="p" variant="h5">
                    {completedPercentage}
                  </Typography>
                </Box>
                <Typography display="inline">%</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ApproversCard;
