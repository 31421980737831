// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Components
import AllocateItemsDialogue from "pages/projects/subjectExperts/components/allocateItemsDialogue/AllocateItemsDialogue";
import SubjectExpertsTable from "pages/projects/subjectExperts/components/subjectExpertsTable/SubjectExpertsTable";
import UserOption from "components/userOption/UserOption";

// Interfaces
import { IProject, ISapCdcUser } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { getAssignedItemsCount } from "utils/project";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  withStyles
} from "@material-ui/core";
import { Add, Group, RotateLeft, Search } from "@material-ui/icons";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const filterOptions = createFilterOptions({
  limit: 10,
  stringify: (option: ISapCdcUser) =>
    `${option.email} ${option.name} ${option.family_name}`
});

const SubjectExperts: FC = (): ReactElement => {
  const { getUsers, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeSubjectExpertOptions, setActiveSubjectExpertOptions] = useState(
    [] as ISapCdcUser[]
  );
  const [formFields, setFormFields] = useState({
    subjectExpert: ""
  } as { subjectExpert: string });
  const [isAllocateItemsDialogueOpen, setIsAllocateItemsDialogueOpen] =
    useState(false);
  const [subjectExpertOptions, setSubjectExpertOptions] = useState(
    [] as ISapCdcUser[]
  );
  const history = useHistory();
  const assignedItemsCount = getAssignedItemsCount(project);
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getUsers<ISapCdcUser[]>().then((response) => {
      const approverEmails = project.approvers.map((approver) =>
        approver.email.toLowerCase()
      );
      const subjectExpertEmails = project.subjectExperts.map((subjectExpert) =>
        subjectExpert.email.toLowerCase()
      );

      setSubjectExpertOptions(
        response.data
          ?.filter(
            (user) =>
              !approverEmails.includes(user.email.toLowerCase()) &&
              !subjectExpertEmails.includes(user.email.toLowerCase())
          )
          .sort((a, b) => a.name.localeCompare(b.name)) || []
      );
    });
  }, [getUsers, project]);

  const callUpdateProject = (
    subjectExperts: IProject["subjectExperts"]
  ): void => {
    updateProject(project.key, {
      ...project,
      subjectExperts
    }).then(() => {
      updateProjectSuccess({
        ...project,
        subjectExperts
      });
    });
  };

  const handleChangeSubjectExpertInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      subjectExpert: event.target.value
    });
  };

  const handleChangeSubjectExpertsAutocomplete = (
    event: ChangeEvent<unknown>,
    options: ISapCdcUser[]
  ): void => {
    setFormFields({
      ...formFields,
      subjectExpert: ""
    });
    setActiveSubjectExpertOptions(options);
  };

  const handleClickAdd = (): void => {
    const subjectExperts = activeSubjectExpertOptions.map((option) => ({
      assignedItems: 0,
      email: option.email,
      sub: option.sub
    }));

    updateProject(project.key, {
      ...project,
      subjectExperts: [...project.subjectExperts, ...subjectExperts]
    }).then(() => {
      updateProjectSuccess({
        ...project,
        subjectExperts: [...project.subjectExperts, ...subjectExperts]
      });

      setActiveSubjectExpertOptions([]);
      setFormFields({
        ...formFields,
        subjectExpert: ""
      });
    });
  };

  const handleClickContinue = (): void => {
    if (
      project.subjectExperts.every(
        (subjectExpert) => subjectExpert.assignedItems > 0
      )
    ) {
      history.push(`/projects/${project.key}/edit/approvers`);
    } else {
      setIsAllocateItemsDialogueOpen(true);
    }
  };

  const handleClickDelete = (email: string): void => {
    const subjectExperts = project.subjectExperts.filter(
      (subjectExpert) => subjectExpert.email !== email
    );

    callUpdateProject(subjectExperts);
  };

  const handleClickDivideEqually = (): void => {
    const subjectExperts = project.subjectExperts.map(
      (subjectExpert, index) => {
        const bulkShare = Math.ceil(
          project.items / project.subjectExperts.length
        );
        const remainingShare =
          project.items - bulkShare * (project.subjectExperts.length - 1);

        return {
          assignedItems:
            index !== project.subjectExperts.length - 1
              ? bulkShare
              : remainingShare,
          email: subjectExpert.email,
          sub: subjectExpert.sub
        };
      }
    );

    callUpdateProject(subjectExperts);
  };

  const handleClickResetAllocation = (): void => {
    const subjectExperts = project.subjectExperts.map((subjectExpert) => ({
      email: subjectExpert.email,
      assignedItems: 0,
      sub: subjectExpert.sub
    }));

    callUpdateProject(subjectExperts);
  };

  const updateAssignedItems = (email: string, assignedItems: number): void => {
    const subjectExperts = project.subjectExperts.map((subjectExpert) => ({
      assignedItems:
        subjectExpert.email === email
          ? assignedItems
          : subjectExpert.assignedItems,
      email: subjectExpert.email,
      sub: subjectExpert.sub
    }));

    callUpdateProject(subjectExperts);
  };

  return (
    <div data-testid="subject-experts-page">
      {Object.keys(project).length > 0 && (
        <>
          <AllocateItemsDialogue
            isOpen={isAllocateItemsDialogueOpen}
            onClickClose={() => setIsAllocateItemsDialogueOpen(false)}
            subjectExperts={project.subjectExperts.filter(
              (subjectExpert) => subjectExpert.assignedItems === 0
            )}
          />
          <Box marginBottom={4}>
            <Box display="flex" marginBottom={4}>
              <Box>
                <Box marginBottom={1}>
                  <Typography
                    component="h2"
                    variant="h4"
                    data-testid="page-heading"
                  >
                    Add subject experts
                  </Typography>
                </Box>
                <Typography data-testid="page-introduction">
                  Subject experts will check items and add keywords. They will
                  be notified when the project starts
                </Typography>
              </Box>
              <Box marginLeft="auto">
                <Button
                  color="primary"
                  disableElevation
                  disabled={
                    assignedItemsCount !== project.items ||
                    !project.subjectExperts.length
                  }
                  onClick={handleClickContinue}
                  data-testid="continue-button"
                >
                  Continue
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box alignItems="center" display="flex" marginBottom={4}>
            <Box marginRight={11}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Subject experts
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h4"
                data-testid="subject-experts"
              >
                {project.subjectExperts.length}
              </Typography>
            </Box>
            <Box marginRight={11}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Allocated items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h4"
                data-testid="allocated-items"
              >
                {assignedItemsCount} / {project.items}
              </Typography>
            </Box>
            <Box clone marginRight={5}>
              <Button
                color="primary"
                disabled={!project.subjectExperts.length}
                onClick={handleClickDivideEqually}
                startIcon={<Group />}
                variant="text"
                data-testid="divide-equally-button"
              >
                Divide equally
              </Button>
            </Box>
            <Button
              color="primary"
              disabled={!project.subjectExperts.length}
              onClick={handleClickResetAllocation}
              startIcon={<RotateLeft />}
              variant="text"
              data-testid="reset-allocation-button"
            >
              Reset allocation
            </Button>
          </Box>
          <Box marginBottom={4}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item md={7} xs={12}>
                <Autocomplete
                  filterOptions={filterOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.email}
                  multiple
                  noOptionsText="No results found."
                  onChange={handleChangeSubjectExpertsAutocomplete}
                  options={
                    formFields.subjectExpert ? [...subjectExpertOptions] : []
                  }
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "subject-expert-input"
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                      onChange={handleChangeSubjectExpertInput}
                      placeholder="Search name or email address"
                    />
                  )}
                  renderOption={(option) => <UserOption option={option} />}
                  value={activeSubjectExpertOptions}
                  data-testid="subject-experts-autocomplete"
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disableElevation
                  disabled={!activeSubjectExpertOptions.length}
                  onClick={handleClickAdd}
                  startIcon={<Add />}
                  data-testid="add-button"
                >
                  Add selected subject experts
                </Button>
              </Grid>
            </Grid>
          </Box>
          {project.subjectExperts.length > 0 && (
            <SubjectExpertsTable
              onClickDelete={handleClickDelete}
              subjectExperts={project.subjectExperts}
              unassignedItemsCount={project.items - assignedItemsCount}
              updateAssignedItems={updateAssignedItems}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SubjectExperts;
