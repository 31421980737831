// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";

interface Props {
  onClickSaveAndContinue: () => void;
  onClickReject: () => void;
}

const SharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader: FC<Props> =
  (props): ReactElement => {
    return (
      <Box
        alignItems="center"
        display="flex"
        width={1}
        data-testid="shared-stimuli-checked-extended-text-or-text-entry-interaction-header"
      >
        <Box>
          <Box marginBottom={1}>
            <Typography variant="h4" data-testid="instructions">
              Make sure the{" "}
              <span style={{ textDecoration: "underline" }}>content</span>{" "}
              matches the original item
            </Typography>
          </Box>
          <Typography>You can make edits on the left, below.</Typography>
        </Box>
        <Box display="flex" marginLeft="auto">
          <Box marginRight={2}>
            <Button
              color="secondary"
              disableElevation
              onClick={props.onClickReject}
              variant="outlined"
              data-testid="reject-button"
            >
              Reject
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={props.onClickSaveAndContinue}
            data-testid="save-and-continue-button"
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    );
  };

export default SharedStimuliCheckedExtendedTextOrTextEntryInteractionHeader;
