// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ConditionalWrapper from "components/conditionalWrapper/ConditionalWrapper";
import Content from "components/qti/content/Content";
import Stimulus from "components/qti/stimulus/Stimulus";

// Enums
import { MessageBody, MessageHeading } from "enums";

// Interfaces
import {
  Message,
  SharedStimulus,
  TextEntryInteraction as TextEntryInteractionInterface
} from "interfaces";
import { IRagDetails } from "@qti-scraper/interfaces";

// Utils
import { getMessageLevel, getMessageInfo } from "utils/ragToMessage";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { alpha, Box, Card, CardContent, Typography } from "@material-ui/core";

interface Props {
  content: TextEntryInteractionInterface;
  initialRagDetails?: IRagDetails[];
  isEditing: boolean;
  itemKey?: string;
  onSaveContent: (
    content: {
      content: string;
      sharedStimuli: SharedStimulus[];
    },
    hasUnsavedChanges: boolean
  ) => void;
  setMessages?: React.Dispatch<React.SetStateAction<Message[]>>;
  sharedStimuli: SharedStimulus[];
  shouldSaveContent?: boolean;
}

const TextEntryInteraction: FC<Props> = (props): ReactElement => {
  const [formFields, setFormFields] = useState({
    content: "",
    sharedStimuli: []
  } as { content: TextEntryInteractionInterface["content"]; sharedStimuli: SharedStimulus[] });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [highlightedFormFields, setHighlightedFormFields] = useState({
    content: false
  });
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tinyMCEInitialisations, setTinyMCEInitialisations] = useState(
    [] as string[]
  );
  const { setMessages } = props;

  useEffect(() => {
    if (!props.itemKey) {
      return;
    }

    setHasUnsavedChanges(false);
    setIsContentLoaded(false);
    setTinyMCEInitialisations([]);
  }, [props.itemKey]);

  useEffect(() => {
    if (!isContentLoaded || !props.setMessages) {
      return;
    }

    const hasContent = !!formFields.content;
    const hasSharedStimuli = formFields.sharedStimuli.length;
    const messages = [] as Message[];
    let highlightContent = false;

    if (props.initialRagDetails?.length) {
      props.initialRagDetails.forEach((initialRagDetail) => {
        const info = getMessageInfo(initialRagDetail.message);
        const level = getMessageLevel(initialRagDetail.status);

        if (info.heading !== MessageHeading.noItemContent) {
          if (initialRagDetail.questionElement === "questionContent") {
            highlightContent = true;
          }

          messages.push({
            body: info.body,
            heading: info.heading,
            level: level
          });
        }
      });
    }

    if (!hasContent) {
      messages.push({
        body: MessageBody.noItemContent,
        heading: MessageHeading.noItemContent,
        level: "error"
      });

      highlightContent = true;
    }

    if (hasSharedStimuli) {
      messages.push({
        body: MessageBody.sharedStimulus,
        heading: MessageHeading.sharedStimulus,
        level: "information"
      });
    }

    setHighlightedFormFields({
      content: highlightContent
    });

    props.setMessages(messages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields, setMessages]);

  useEffect(() => {
    setFormFields({
      content: props.content.content,
      sharedStimuli: props.sharedStimuli || []
    });

    setIsContentLoaded(true);
  }, [props.content, props.sharedStimuli]);

  useEffect(() => {
    if (isEditing && props.isEditing) {
      return;
    }

    if (props.isEditing) {
      setIsEditing(true);
    }

    if (isEditing && !props.isEditing) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditing]);

  useEffect(() => {
    if (!props.shouldSaveContent) {
      return;
    }

    props.onSaveContent &&
      props.onSaveContent(
        {
          content: formFields.content,
          sharedStimuli: formFields.sharedStimuli
        },
        hasUnsavedChanges
      );

    setHasUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldSaveContent]);

  const handleEditFormField = (name: "content", value: string): void => {
    setFormFields({
      ...formFields,
      [name]: value
    });

    if (!tinyMCEInitialisations.includes(name)) {
      setTinyMCEInitialisations([...tinyMCEInitialisations, name]);
    } else {
      setHasUnsavedChanges(true);
    }
  };

  const handleEditNestedFormField = (
    name: "sharedStimuli",
    index: number,
    value: string
  ): void => {
    const copy = [...formFields[name]];

    copy[index].content = value;

    setFormFields({
      ...formFields,
      [name]: copy
    });

    if (
      tinyMCEInitialisations.filter(
        (tinyMCEInitialisation) => tinyMCEInitialisation === name
      ).length !== formFields[name].length
    ) {
      setTinyMCEInitialisations([...tinyMCEInitialisations, name]);
    } else {
      copy[index].hasUnsavedChanges = true;
    }
  };

  return (
    <div data-testid="text-entry-interaction">
      <ConditionalWrapper
        shouldWrap={!props.isEditing}
        wrapper={(children) => (
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        )}
      >
        <>
          {formFields.sharedStimuli && (
            <>
              {formFields.sharedStimuli.map((sharedStimulus, index) => (
                <ConditionalWrapper
                  key={index}
                  shouldWrap={props.isEditing}
                  wrapper={(children) => (
                    <Box marginBottom={4}>
                      <Box marginBottom={2}>
                        <Typography component="h2" variant="h5">
                          Shared stimulus
                        </Typography>
                      </Box>
                      <Card>
                        <CardContent>{children}</CardContent>
                      </Card>
                    </Box>
                  )}
                >
                  <Stimulus
                    index={index}
                    isEditing={props.isEditing}
                    isShared
                    onChange={(value: string) =>
                      handleEditNestedFormField("sharedStimuli", index, value)
                    }
                    stimulus={formFields.sharedStimuli[index].content}
                  />
                </ConditionalWrapper>
              ))}
            </>
          )}
          <ConditionalWrapper
            shouldWrap={props.isEditing}
            wrapper={(children) => (
              <Box marginBottom={4}>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Item content
                  </Typography>
                </Box>
                <Card
                  style={{
                    border: !highlightedFormFields.content
                      ? "0 none"
                      : `3px solid ${alpha(colours.pdfRed, 0.49)}`
                  }}
                >
                  <CardContent>{children}</CardContent>
                </Card>
              </Box>
            )}
          >
            <Content
              content={formFields.content}
              isEditing={props.isEditing}
              onChange={(value: string) =>
                handleEditFormField("content", value)
              }
            />
          </ConditionalWrapper>
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default TextEntryInteraction;
