// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Assets
import initialFigure from "assets/images/admin-onboarding/initial-figure.svg";
import stepOneArrow from "assets/images/admin-onboarding/step-one-arrow.svg";
import stepOneProjectType from "assets/images/admin-onboarding/step-one-project-type.svg";
import stepOneTask from "assets/images/admin-onboarding/step-one-task.svg";
import stepTwoSyllabusCode from "assets/images/admin-onboarding/step-two-syllabus-code.svg";
import stepThreeArrow from "assets/images/admin-onboarding/step-three-arrow.svg";
import stepThreeDropzone from "assets/images/admin-onboarding/step-three-dropzone.svg";
import stepThreeFiles from "assets/images/admin-onboarding/step-three-files.svg";
import stepFourCIPapers from "assets/images/admin-onboarding/step-four-ci-papers.svg";
import stepFourOCRPapers from "assets/images/admin-onboarding/step-four-ocr-papers.svg";
import stepFiveArrow from "assets/images/admin-onboarding/step-five-arrow.svg";
import stepFiveFiles from "assets/images/admin-onboarding/step-five-files.svg";
import stepFiveUploadProgress from "assets/images/admin-onboarding/step-five-upload-progress.svg";
import stepSixArrowOne from "assets/images/admin-onboarding/step-six-arrow-one.svg";
import stepSixArrowTwo from "assets/images/admin-onboarding/step-six-arrow-two.svg";
import stepSixJobOne from "assets/images/admin-onboarding/step-six-job-one.svg";
import stepSixJobTwo from "assets/images/admin-onboarding/step-six-job-two.svg";
import stepSixUploadSummary from "assets/images/admin-onboarding/step-six-upload-summary.svg";
import stepSevenArrows from "assets/images/admin-onboarding/step-seven-arrows.svg";
import stepSevenHarvestedItems from "assets/images/admin-onboarding/step-seven-harvested-items.svg";
import stepSevenSubjectExperts from "assets/images/admin-onboarding/step-seven-subject-experts.svg";
import stepEightAllocationCount from "assets/images/admin-onboarding/step-eight-allocation-count.svg";
import stepEightApprover from "assets/images/admin-onboarding/step-eight-approver.svg";
import stepEightArrow from "assets/images/admin-onboarding/step-eight-arrow.svg";
import stepEightArrows from "assets/images/admin-onboarding/step-eight-arrows.svg";
import stepEightSubjectExperts from "assets/images/admin-onboarding/step-eight-subject-experts.svg";
import stepNineArrow from "assets/images/admin-onboarding/step-nine-arrow.svg";
import stepNineInstructions from "assets/images/admin-onboarding/step-nine-instructions.svg";
import stepNineSubjectExpertTask from "assets/images/admin-onboarding/step-nine-subject-expert-task.svg";
import stepTenProjectDashboard from "assets/images/admin-onboarding/step-ten-project-dashboard.svg";
import stepElevenArrow from "assets/images/admin-onboarding/step-eleven-arrow.svg";
import stepElevenBankedItem from "assets/images/admin-onboarding/step-eleven-banked-item.svg";
import stepElevenProjectItemsSummary from "assets/images/admin-onboarding/step-eleven-project-items-summary.svg";

// Components
import OnboardingStepHeader from "components/onboardingStepHeader/OnboardingStepHeader";
import OnboardingStepNavigation from "components/onboardingStepNavigation/OnboardingStepNavigation";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const AdminOnboarding: FC = (): ReactElement => {
  const { updateUser } = useApi();
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    document.body.classList.add("onboarding");

    return () => {
      document.body.classList.remove("onboarding");
    };
  }, []);

  const handleClickComplete = (): void => {
    updateUser("admin", true).then(() => {
      history.push("/admin/dashboard");
    });
  };

  const handleClickDot = (index: number): void => {
    setActiveStep(index);
  };

  const handleClickNext = (): void => {
    setActiveStep(activeStep + 1);
  };

  const handleClickPrevious = (): void => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div data-testid="admin-onboarding-page">
      {activeStep === 0 && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection={isSmScreen ? "column" : "row"}
          paddingX={isSmScreen ? 0 : 12}
        >
          <Box
            clone
            marginBottom={isSmScreen ? 12 : 0}
            marginRight={isSmScreen ? 0 : 12}
          >
            <img alt="Two people having a conversation" src={initialFigure} />
          </Box>
          <Box>
            <Box marginBottom={4}>
              <Typography variant="h2">Welcome,</Typography>
            </Box>
            <Box marginBottom={12}>
              <Typography component="p" variant="h5">
                This demo will help you familiarise yourself with the Content
                Management Tool.
              </Typography>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickNext}
              data-testid="continue-button"
            >
              Let&rsquo;s go!
            </Button>
          </Box>
        </Box>
      )}
      {activeStep !== 0 && (
        <Box marginBottom={20} data-testid={`step-${activeStep}`}>
          {activeStep === 1 && (
            <>
              <OnboardingStepHeader
                body="Give a clear name that will make sense to everyone involved in the project. It needs to be informative and meaningful for examiners and other admins collaborating on the project."
                chipLabel="Naming the project"
                heading="Give Your Project a Relevant Name"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : -2}
                >
                  <img alt="A project being created" src={stepOneProjectType} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={-2} zIndex={1}>
                    <img alt="Arrow" src={stepOneArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="A subject expert's task card" src={stepOneTask} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <Box textAlign="center">
              <OnboardingStepHeader
                body={
                  <>
                    You can select{" "}
                    <span style={{ textDecoration: "underline" }}>
                      only one
                    </span>{" "}
                    code per project. Not all syllabuses/specifications are
                    available for harvesting at the moment.
                  </>
                }
                chipLabel="Project scope"
                heading="Select the Syllabus/Specification Code"
                marginBottom={17}
              />
              <Box display="flex" justifyContent="center">
                <img alt="Entering a syllabus code" src={stepTwoSyllabusCode} />
              </Box>
            </Box>
          )}
          {activeStep === 3 && (
            <>
              <OnboardingStepHeader
                body="Each question paper must be uploaded with its corresponding mark scheme. You will get an error if either is missing."
                chipLabel="Uploading papers"
                heading="Question Papers and Mark Schemes"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : -2}
                >
                  <img alt="Files being paired" src={stepThreeFiles} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={-6} zIndex={1}>
                    <img alt="Arrow" src={stepThreeArrow} />
                  </Box>
                )}
                <Box>
                  <img alt="A dropzone" src={stepThreeDropzone} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 4 && (
            <>
              <OnboardingStepHeader
                body="The question paper and mark scheme files need to be in the PDF format, and named in a specific way (see below)."
                chipLabel="Uploading papers"
                heading="Naming the Files"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 10}
                >
                  <img alt="File naming - CI papers" src={stepFourCIPapers} />
                </Box>
                <Box>
                  <img alt="File naming - OCR papers" src={stepFourOCRPapers} />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 5 && (
            <>
              <OnboardingStepHeader
                body="Once you've uploaded the question papers and mark schemes for harvesting, you cannot add new files to the project. If you wish to add new papers beyond this stage, you will have to set up a new project."
                chipLabel="Uploading papers"
                heading="Send for Harvesting"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : -2}
                >
                  <img alt="Files being paired" src={stepFiveFiles} />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={2}>
                    <img alt="Arrow" src={stepFiveArrow} />
                  </Box>
                )}
                <Box>
                  <img
                    alt="File upload progress"
                    src={stepFiveUploadProgress}
                  />
                </Box>
              </Box>
            </>
          )}
          {activeStep === 6 && (
            <>
              <OnboardingStepHeader
                body="Only unique and supported items will be harvested. Some of them could be duplicate items (previously already harvested), and others could be unsupported."
                chipLabel="Harvesting"
                heading="Understanding the Harvesting Summary"
              />
              <Box display="flex" justifyContent="center">
                <img
                  alt="A summary of the uploaded files"
                  src={stepSixUploadSummary}
                />
              </Box>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                marginTop={12}
              >
                <Box marginRight={8}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="relative"
                  >
                    <Box clone position="absolute" top={-60} right={30}>
                      <img alt="Arrow" src={stepSixArrowOne} />
                    </Box>
                    <img alt="A finished job" src={stepSixJobOne} />
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="relative"
                  >
                    <Box clone position="absolute" top={-60} left={30}>
                      <img alt="Arrow" src={stepSixArrowTwo} />
                    </Box>
                    <img alt="A finished job" src={stepSixJobTwo} />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {activeStep === 7 && (
            <>
              <OnboardingStepHeader
                body="You can allocate harvested items to different subject
                    experts."
                chipLabel="Allocating items"
                heading="Adding Subject Experts"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  clone
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 7}
                >
                  <img
                    alt="A count of the harvested items"
                    src={stepSevenHarvestedItems}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={7}>
                    <img alt="Arrows" src={stepSevenArrows} />
                  </Box>
                )}
                <img
                  alt="A list of a project's subject experts"
                  src={stepSevenSubjectExperts}
                />
              </Box>
            </>
          )}
          {activeStep === 8 && (
            <>
              <OnboardingStepHeader
                body={
                  <>
                    Approvers review and bank/reject the items submitted by the
                    subject experts. At the moment, you can add{" "}
                    <span style={{ textDecoration: "underline" }}>
                      only one
                    </span>{" "}
                    approver per project.
                  </>
                }
                chipLabel="Approver"
                heading="Adding an Approver"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  clone
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 4}
                >
                  <img
                    alt="A list of a project's subject experts"
                    src={stepEightSubjectExperts}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={4}>
                    <img alt="Arrows" src={stepEightArrows} />
                  </Box>
                )}
                <Box
                  clone
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : 4}
                >
                  <img
                    alt="A count of the allocated items"
                    src={stepEightAllocationCount}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={4}>
                    <img alt="Arrow" src={stepEightArrow} />
                  </Box>
                )}
                <img alt="A project's approver" src={stepEightApprover} />
              </Box>
            </>
          )}
          {activeStep === 9 && (
            <>
              <OnboardingStepHeader
                body="We have provided default instructions for subject experts and approvers. These can be changed. This information will be visible on their dashboards."
                chipLabel="Instructions"
                heading="Providing Instructions"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
              >
                <Box
                  clone
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : -5}
                >
                  <img
                    alt="Instructions for the subject experts and approver"
                    src={stepNineInstructions}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={1}>
                    <img alt="Arrow" src={stepNineArrow} />
                  </Box>
                )}
                <img
                  alt="A subject expert task"
                  src={stepNineSubjectExpertTask}
                />
              </Box>
            </>
          )}
          {activeStep === 10 && (
            <>
              <OnboardingStepHeader
                body="You will be able to keep track of subject experts' and approvers' progress."
                chipLabel="In-progress projects"
                heading="Monitoring Progress"
              />
              <Box display="flex" justifyContent="center">
                <img
                  alt="The project dashboard"
                  src={stepTenProjectDashboard}
                />
              </Box>
            </>
          )}
          {activeStep === 11 && (
            <>
              <OnboardingStepHeader
                body="You will have a detailed overview of all banked and rejected items once the project has ended."
                chipLabel="Completed projects"
                heading="Viewing Project Results in Detail"
              />
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSmScreen ? "column" : "row"}
                justifyContent="center"
                marginBottom={4}
              >
                <Box
                  clone
                  marginBottom={isSmScreen ? 4 : 0}
                  marginRight={isSmScreen ? 0 : -5}
                >
                  <img
                    alt="A summary of a project's completed items"
                    src={stepElevenProjectItemsSummary}
                  />
                </Box>
                {isSmScreen && (
                  <Box clone marginBottom={4}>
                    <ArrowDownward
                      fontSize="large"
                      htmlColor={colours.greenDark}
                    />
                  </Box>
                )}
                {!isSmScreen && (
                  <Box clone marginRight={1}>
                    <img alt="Arrow" src={stepElevenArrow} />
                  </Box>
                )}
                <img alt="A banked item" src={stepElevenBankedItem} />
              </Box>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography>
                  You can access this content from your dashboard again at any
                  time
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
      {activeStep > 0 && (
        <OnboardingStepNavigation
          activeStep={activeStep}
          isSmScreen={isSmScreen}
          onClickComplete={handleClickComplete}
          onClickDot={handleClickDot}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
          stepCount={11}
          userRole="admin"
        />
      )}
    </div>
  );
};

export default AdminOnboarding;
