// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Chip, Grid, Typography, withStyles } from "@material-ui/core";

interface Props {
  body: ReactElement | string;
  chipLabel: string;
  heading: string;
  marginBottom?: number;
}

const OnboardingStepHeaderChip = withStyles(() => ({
  root: {
    borderColor: colours.monochromeDark
  }
}))(Chip);

const OnboardingStepHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      marginBottom={props.marginBottom ? props.marginBottom : 7}
      position="relative"
      textAlign="center"
      zIndex={1}
      data-testid="onboarding-step-header"
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box clone marginBottom={3}>
            <OnboardingStepHeaderChip
              label={props.chipLabel}
              variant="outlined"
              data-testid="onboarding-step-header-chip"
            />
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h2">{props.heading}</Typography>
          </Box>
          <Typography component="p" variant="h5">
            {props.body}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnboardingStepHeader;
