export enum MessageBody {
  incorrectNumberOfResponses = "Add missing responses to continue.",
  noItemContent = "We have not detected any content for this item. Add some content to continue.",
  noPrompt = "We have not detected a prompt for this item. Add the prompt to continue.",
  noStimulus = "Add a stimulus if there is one in the original item. Not every question has a stimulus.",
  missingStyling = "Some styling information was missing from the content of this question. Please review and correct.",
  missingCharacters = "One or more special characters could not be preserved. Please review and correct.",
  incorrectTable = "One or more tables may have incorrect contents. Please check.",
  sharedStimulus = "Your edits on the shared stimulus will take effect everywhere that is is being used."
}
