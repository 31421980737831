// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Interfaces
import { ResponseItem } from "@qti-scraper/interfaces";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import {
  alpha,
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  index: number;
  isEditing: boolean;
  onChange: (value: string) => void;
  response: ResponseItem;
}

const SimpleChoice: FC<Props> = (props): ReactElement => {
  const useStyles = makeStyles({
    answerIdentifier: {
      fontWeight: "bold",
      marginRight: 10
    },
    container: {
      backgroundColor: props.response.correct
        ? colours.monochromeLightest
        : "transparent",
      borderTop: `1px solid ${colours.monochromeLight}`,
      marginLeft: "-32px",
      marginRight: "-32px",
      padding: "20px 32px 20px 32px",
      "&:first-child": {
        borderTop: `1px solid ${colours.monochromeLight}`
      }
    },
    correctAnswer: {
      color: colours.greenDark,
      fontSize: "14px",
      fontWeight: 600,
      marginLeft: "auto",
      textDecoration: "underline"
    }
  });
  const classes = useStyles();

  return (
    <Box data-testid="simple-choice">
      {props.isEditing && (
        <Box alignItems="center" display="flex" marginTop={1}>
          <Typography
            className={classes.answerIdentifier}
            data-testid="identifier"
          >
            {props.response.label}
          </Typography>
          <Box clone flex="1" marginTop={0}>
            <Card
              style={{
                border: props.response.content
                  ? "0 none"
                  : `3px solid ${alpha(colours.pdfRed, 0.49)}`
              }}
            >
              <Box clone display="flex">
                <CardContent>
                  <Editor
                    allowImages={true}
                    allowLists={false}
                    allowTables={false}
                    onChange={props.onChange}
                    testId={`response-${props.index}-editor`}
                    value={props.response.content}
                  />
                  {props.response.correct && (
                    <Typography className={classes.correctAnswer}>
                      Correct answer
                    </Typography>
                  )}
                </CardContent>
              </Box>
            </Card>
          </Box>
        </Box>
      )}
      {!props.isEditing && (
        <Box className={classes.container}>
          <Box alignItems="center" display="flex">
            <Typography
              className={classes.answerIdentifier}
              data-testid="identifier"
            >
              {props.response.label}
            </Typography>
            <div
              className="read-only"
              data-testid={`response-${props.index}-content`}
            >
              <MathJax
                math={props.response.content}
                sanitizeOptions={{
                  ADD_ATTR: ["src"],
                  ADD_TAGS: [
                    "b",
                    "br",
                    "i",
                    "img",
                    "p",
                    "span",
                    "sub",
                    "sup",
                    "u"
                  ]
                }}
                wrapperTag="span"
              />
            </div>
            {props.response.correct && (
              <Typography className={classes.correctAnswer}>
                Correct answer
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SimpleChoice;
