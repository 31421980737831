// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  CircularProgress,
  Fade,
  makeStyles,
  Typography
} from "@material-ui/core";

interface Props {
  body: string;
  heading: string;
  shouldFadeIn: boolean;
}

const LoadingDialogue: FC<Props> = (props): ReactElement => {
  const useStyles = makeStyles({
    loadingDialogue: {
      backgroundColor: colours.backgroundGrey,
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2
    },
    loadingDialogueContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      textAlign: "center",
      transform: "translate(-50%, -50%)"
    }
  });
  const classes = useStyles();

  return (
    <Fade
      in={props.shouldFadeIn}
      onEnter={() => document.body.classList.add("dialogue-open")}
      onExit={() => document.body.classList.remove("dialogue-open")}
      timeout={{
        enter: 500,
        exit: 500
      }}
    >
      <div className={classes.loadingDialogue} data-testid="loading-dialogue">
        <div className={classes.loadingDialogueContent}>
          <Box marginBottom={1}>
            <Typography component="h2" variant="h5" data-testid="heading">
              {props.heading}
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <Typography component="p" variant="h4" data-testid="body">
              {props.body}
            </Typography>
          </Box>
          <CircularProgress />
        </div>
      </div>
    </Fade>
  );
};

export default LoadingDialogue;
