// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteractionTask from "pages/approverTask/components/choiceInteractionTask/ChoiceInteractionTask";
import LoadingDialogue from "components/loadingDialogue/LoadingDialogue";
import TaskCompletedDialogue from "components/taskCompletedDialogue/TaskCompletedDialogue";
import TaskHeader from "components/taskHeader/TaskHeader";

// Interfaces
import { Item } from "interfaces";
import { ITask } from "@qti-scraper/interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { useHistory, useParams } from "react-router-dom";

const ApproverTask: FC = (): ReactElement => {
  const {
    bankItem,
    getItem,
    getTask,
    getWholeQuestion,
    patchItem,
    rejectItem,
    updateItem,
    updateItemStatus,
    user
  } = useApi();
  const [activeAction, setActiveAction] = useState(
    null as "isBanking" | "isRejecting" | null
  );
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isBanking, setIsBanking] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isRejecting, setIsRejecting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isTaskCompletedDialogueOpen, setIsTaskCompletedDialogueOpen] =
    useState(false);
  const [item, setItem] = useState({} as Item);
  const [shouldSaveContent, setShouldSaveContent] = useState(false);
  const [shouldSaveMetadata, setShouldSaveMetadata] = useState(false);
  // const [shouldSaveSharedStimuli, setShouldSaveSharedStimuli] = useState(false);
  const [statusToSet, setStatusToSet] = useState("" as Item["status"]);
  const [task, setTask] = useState({} as ITask);
  const [wasBackClicked, setWasBackClicked] = useState(false);
  // const [wholeQuestionImageUrls, setWholeQuestionImageUrls] = useState(
  //   [] as string[]
  // );
  const history = useHistory();
  const { itemKey } = useParams<Record<string, string | undefined>>();
  const { taskKey } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (!Object.keys(item).length) {
      return;
    }

    if (
      item.content.qtiInteractionType === "extendedTextInteraction" ||
      item.content.qtiInteractionType === "textEntryInteraction"
    ) {
      getWholeQuestion<string[]>(item.job, item.questionNumber, {
        imagesOnly: "true"
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).then((response) => {
        // setWholeQuestionImageUrls(response.data || []);
      });
    }
  }, [getWholeQuestion, item]);

  useEffect(() => {
    if (!taskKey) {
      return;
    }

    getTask<ITask>(taskKey).then((response) => {
      setTask(response.data || ({} as ITask));
    }, console.error);
  }, [getTask, taskKey]);

  useEffect(() => {
    if (!Object.keys(task).length || !itemKey) {
      return;
    }

    if (activeAction === "isBanking") {
      setIsBanking({ shouldFadeIn: false, value: true });
    } else if (activeAction === "isRejecting") {
      setIsRejecting({ shouldFadeIn: false, value: true });
    }

    getItem<Item>(itemKey, { includePossibleSharedStimuli: "true" }).then(
      (response) => {
        if (activeAction === "isBanking") {
          setIsBanking({ shouldFadeIn: false, value: false });
        } else if (activeAction === "isRejecting") {
          setIsRejecting({ shouldFadeIn: false, value: false });
        }

        setActiveAction(null);

        setIsAccepted(false);

        setItem({} as Item);

        setWasBackClicked(false);

        setItem(response.data || ({} as Item));
      },
      console.error
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, itemKey, task]);

  const callBankItem = (): void => {
    bankItem(item.key)
      .then(() => {
        setTimeout(() => {
          setActiveAction("isBanking");
          incrementItem();
        }, 2000);
      })
      .catch(() => {
        setIsBanking({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  const handleClickBack = (): void => {
    setWasBackClicked(true);

    switch (item.content.qtiInteractionType) {
      case "choiceInteraction":
        navigateFromChoiceInteraction();
        break;
      case "extendedTextInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      case "textEntryInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickConfirmReject = (body: any): void => {
    setIsRejecting({
      shouldFadeIn: true,
      value: true
    });

    rejectItem(item.key, body)
      .then(() => {
        setTimeout(() => {
          setActiveAction("isRejecting");
          incrementItem();
        }, 2000);
      })
      .catch(() => {
        setIsRejecting({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  const handleClickContinueFromContent = (): void => {
    if (item.status === "rejectedBySubjectExpert") {
      updateItemStatus("content-checked", item.key)
        .then(() => {
          setItem({ ...item, status: "contentChecked" });
          setShouldSaveContent(true);
        })
        .catch(console.error);
    } else if (!isAccepted) {
      setIsAccepted(true);
      setShouldSaveContent(true);
    }
  };

  const handleClickContinueFromMetadata = (): void => {
    setShouldSaveMetadata(true);
  };

  const handleClickTaskStep = (status: Item["status"]): void => {
    setStatusToSet(status);

    switch (item.content.qtiInteractionType) {
      case "choiceInteraction":
        navigateFromChoiceInteraction();
        break;
      case "extendedTextInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      case "textEntryInteraction":
        navigateFromExtendedTextOrTextEntryInteraction();
        break;
      default:
        break;
    }
  };

  const handleSaveContent = (
    content: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ): void => {
    if (!hasUnsavedChanges) {
      if (wasBackClicked) {
        history.push("/examiner/dashboard");

        return;
      }

      setShouldSaveContent(false);

      if (statusToSet) {
        patchItem(item.key, { status: statusToSet });

        setItem({ ...item, status: statusToSet });

        setStatusToSet("" as Item["status"]);
      }

      return;
    }

    updateItem(item.key, {
      content: {
        ...item.content,
        ...content
      },
      metadata: item.metadata,
      skills: item.skills,
      topics: item.topics
    })
      .then(() => {
        setShouldSaveContent(false);

        if (wasBackClicked) {
          history.push("/examiner/dashboard");

          return;
        }

        setShouldSaveContent(false);

        if (statusToSet) {
          patchItem(item.key, { status: statusToSet });

          setStatusToSet("" as Item["status"]);
        }

        getItem<Item>(item.key, {
          includePossibleSharedStimuli: "true"
        }).then((response) => {
          setItem(response.data || ({} as Item));
        }, console.error);
      })
      .catch(console.error);
  };

  const handleSaveMetadata = (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ): void => {
    if (wasBackClicked) {
      if (hasUnsavedChanges) {
        updateItem(item.key, metadata)
          .then(() => {
            history.push("/examiner/dashboard");
          })
          .catch(console.error);

        return;
      }

      history.push("/examiner/dashboard");
    } else if (statusToSet) {
      if (hasUnsavedChanges) {
        updateItem(item.key, metadata)
          .then(() => {
            setShouldSaveMetadata(false);

            patchItem(item.key, { status: statusToSet });

            setItem({
              ...item,
              additionalInformation: metadata.additionalInformation,
              difficulty: metadata.difficulty,
              skills: metadata.skills,
              topics: metadata.topics,
              status: statusToSet
            });

            setStatusToSet("" as Item["status"]);
          })
          .catch(console.error);

        return;
      }

      setShouldSaveMetadata(false);

      patchItem(item.key, { status: statusToSet });

      setItem({ ...item, status: statusToSet });

      setStatusToSet("" as Item["status"]);
    } else {
      setIsBanking({
        shouldFadeIn: true,
        value: true
      });

      if (hasUnsavedChanges) {
        updateItem(item.key, metadata)
          .then(() => {
            if (item.status !== "metadataAdded") {
              updateItemStatus("metadata-added", item.key)
                .then(() => {
                  setShouldSaveMetadata(false);
                  setItem({ ...item, status: "metadataAdded" });
                  callBankItem();
                })
                .catch(console.error);
            } else {
              setShouldSaveMetadata(false);
              callBankItem();
            }
          })
          .catch(console.error);
      } else {
        if (item.status !== "metadataAdded") {
          updateItemStatus("metadata-added", item.key)
            .then(() => {
              setShouldSaveMetadata(false);
              setItem({ ...item, status: "metadataAdded" });
              callBankItem();
            })
            .catch(console.error);
        } else {
          setShouldSaveMetadata(false);
          callBankItem();
        }
      }
    }
  };

  const incrementItem = (): void => {
    if (activeItemIndex === task.pendingItems.length - 1) {
      setIsTaskCompletedDialogueOpen(true);
    } else {
      setActiveItemIndex(activeItemIndex + 1);

      history.push(
        `/approver/tasks/${taskKey}/items/${
          task.pendingItems[activeItemIndex + 1]
        }`
      );
    }
  };

  const navigateFromChoiceInteraction = (): void => {
    if (
      (item.status === "metadataAdded" && !isAccepted) ||
      item.status === "rejectedBySubjectExpert"
    ) {
      setShouldSaveContent(true);
    } else {
      setShouldSaveMetadata(true);
    }
  };

  const navigateFromExtendedTextOrTextEntryInteraction = (): void => {
    switch (item.status) {
      case "initial":
        // setShouldSaveSharedStimuli(true);
        break;
      case "sharedStimuliChecked":
        setShouldSaveContent(true);
        break;
      case "contentChecked":
        console.log("save the mark scheme and reroute to the dashboard");
        break;
      case "markSchemeChecked":
        setShouldSaveMetadata(true);
        break;
      default:
        break;
    }
  };

  return (
    <div data-testid="approver-task-page">
      {isRejecting.value && (
        <LoadingDialogue
          body="Item has been rejected"
          heading="Thank you"
          shouldFadeIn={isRejecting.shouldFadeIn}
        />
      )}
      {isBanking.value && (
        <LoadingDialogue
          body="Item is being banked"
          heading="Thank you"
          shouldFadeIn={isBanking.shouldFadeIn}
        />
      )}
      <TaskCompletedDialogue isOpen={isTaskCompletedDialogueOpen} />
      {Object.keys(item).length > 0 && Object.keys(task).length > 0 && (
        <>
          <TaskHeader
            activeItemIndex={activeItemIndex}
            onClickBack={handleClickBack}
            task={task}
          />
          {item.content.qtiInteractionType === "choiceInteraction" && (
            <ChoiceInteractionTask
              isAccepted={isAccepted}
              isBanking={isBanking.value}
              item={item}
              onClickConfirmReject={handleClickConfirmReject}
              onClickContinueFromContent={handleClickContinueFromContent}
              onClickContinueFromMetadata={handleClickContinueFromMetadata}
              onClickTaskStep={handleClickTaskStep}
              onSaveContent={handleSaveContent}
              onSaveMetadata={handleSaveMetadata}
              projectKey={task.project.key}
              shouldSaveContent={shouldSaveContent}
              shouldSaveMetadata={shouldSaveMetadata}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ApproverTask;
