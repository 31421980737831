// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import {
  createStyles,
  LinearProgress as MUILinearProgress,
  withStyles
} from "@material-ui/core";

const LinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 2
    },
    colorPrimary: {
      backgroundColor: colours.monochromeLight
    },
    bar: {
      borderRadius: 2,
      backgroundColor: colours.corporateBlue
    }
  })
)(MUILinearProgress);

export default LinearProgress;
