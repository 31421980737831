// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";

interface Props {
  isAccepted: boolean;
  isBankDisabled: boolean;
  isSaveAndContinueDisabled: boolean;
  onClickReject: () => void;
  onClickSaveAndContinue: () => void;
  onClickSaveMetadata: () => void;
}

const MetadataAddedChoiceInteractionHeader: FC<Props> = (
  props
): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="metadata-added-choice-interaction-header"
    >
      {props.isAccepted && (
        <Box data-testid="instructions">
          <Box marginBottom={1}>
            <Typography variant="h4">Bank the item</Typography>
          </Box>
          <Typography>
            Check the data added by the subject expert, then bank this item.
            <br />
            You can make changes before banking it.
          </Typography>
        </Box>
      )}
      {!props.isAccepted && (
        <Box data-testid="instructions">
          <Box marginBottom={1}>
            <Typography variant="h4">
              Make sure the{" "}
              <span style={{ textDecoration: "underline" }}>content</span>{" "}
              matches the original item
            </Typography>
          </Box>
          <Typography>
            Click on text areas to make changes if needed.
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" marginLeft="auto">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box marginRight={2}>
            <Button
              color="secondary"
              disableElevation
              onClick={props.onClickReject}
              variant="outlined"
              data-testid="reject-button"
            >
              Reject item
            </Button>
          </Box>
          {props.isAccepted && (
            <Button
              color="primary"
              disableElevation
              disabled={props.isBankDisabled}
              onClick={props.onClickSaveMetadata}
              startIcon={<Save />}
              data-testid="bank-button"
            >
              Bank this item
            </Button>
          )}
          {!props.isAccepted && (
            <Button
              color="primary"
              disableElevation
              disabled={props.isSaveAndContinueDisabled}
              onClick={props.onClickSaveAndContinue}
              data-testid="save-and-continue-button"
            >
              Save and continue
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MetadataAddedChoiceInteractionHeader;
