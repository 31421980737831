// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import ContentCheckedChoiceInteractionHeader from "pages/subjectExpertTask/components/contentCheckedChoiceInteractionHeader/ContentCheckedChoiceInteractionHeader";
import InitialChoiceInteractionHeader from "pages/subjectExpertTask/components/initialChoiceInteractionHeader/InitialChoiceInteractionHeader";
import ChoiceInteractionTaskStepper from "components/choiceInteractionTaskStepper/ChoiceInteractionTaskStepper";
import ItemInfo from "components/itemInfo/ItemInfo";
import Message from "components/message/Message";
import PdfPreview from "components/pdfPreview/PdfPreview";
import RejectContentDialogue from "components/rejectContentDialogue/RejectContentDialogue";

// Interfaces
import {
  ChoiceInteraction as ChoiceInteractionInterface,
  Item,
  Message as MessageInterface
} from "interfaces";
import { ResponseItem } from "@qti-scraper/interfaces";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Grid, Typography } from "@material-ui/core";

interface Props {
  isSubmitting: boolean;
  item: Item;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickConfirmReject: (body: any) => void;
  onClickContinueFromContent: () => void;
  onClickSubmit: () => void;
  onClickTaskStep: (status: Item["status"]) => void;
  onSaveContent: (
    content: {
      prompt: string;
      stimulus: string;
      responses: ResponseItem[];
    },
    hasUnsavedChanges: boolean
  ) => void;
  onSaveMetadata: (
    metadata: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    hasUnsavedChanges: boolean
  ) => void;
  projectKey: string;
  shouldSaveContent: boolean;
  shouldSaveMetadata: boolean;
}

const ChoiceInteractionTask: FC<Props> = (props): ReactElement => {
  const [hasMandatoryMetadata, setHasMandatoryMetadata] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isRejectContentDialogueOpen, setIsRejectContentDialogueOpen] =
    useState(false);
  const [messages, setMessages] = useState([] as MessageInterface[]);

  useEffect(() => {
    setIsEditing(props.item.status === "initial" ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.status]);

  const handleChangeMandatoryMetadata = (value: boolean): void => {
    setHasMandatoryMetadata(value);
  };

  const handleClickReject = (): void => {
    setIsRejectContentDialogueOpen(true);
  };

  return (
    <div data-testid="choice-interaction-task">
      <RejectContentDialogue
        isOpen={isRejectContentDialogueOpen}
        onClickClose={() => setIsRejectContentDialogueOpen(false)}
        onClickConfirmReject={props.onClickConfirmReject}
      />
      <ChoiceInteractionTaskStepper
        isAddTopicsAndSkillsDisabled={
          messages.filter((message) => message.level === "error").length > 0
        }
        item={props.item}
        onClickTaskStep={props.onClickTaskStep}
      />
      <Box alignItems="start" display="flex" marginBottom={4}>
        {props.item.status === "initial" && (
          <InitialChoiceInteractionHeader
            isSaveAndContinueDisabled={
              messages.filter((message) => message.level === "error").length > 0
            }
            onClickReject={handleClickReject}
            onClickSaveAndContinue={props.onClickContinueFromContent}
          />
        )}
        {props.item.status === "contentChecked" && (
          <ContentCheckedChoiceInteractionHeader
            isSubmitDisabled={!hasMandatoryMetadata || props.isSubmitting}
            onClickSubmit={props.onClickSubmit}
          />
        )}
      </Box>
      <Divider />
      {props.item.status === "initial" && (
        <>
          {messages.map((message) => (
            <Message
              body={message.body}
              heading={message.heading}
              key={message.body}
              level={message.level}
            />
          ))}
        </>
      )}
      <Box marginTop={5}>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            {!isEditing && (
              <Box marginBottom={2}>
                <Typography component="h2" variant="h5">
                  Extracted item
                </Typography>
              </Box>
            )}
            <ChoiceInteraction
              content={props.item.content as ChoiceInteractionInterface}
              initialRagDetails={props.item.initialRagDetails}
              isEditing={isEditing}
              itemKey={props.item.key}
              onSaveContent={props.onSaveContent}
              setMessages={setMessages}
              shouldSaveContent={props.shouldSaveContent}
            />
          </Grid>
          <Grid item xs={6}>
            {props.item.status === "initial" && (
              <>
                <Box marginBottom={2}>
                  <Typography component="h2" variant="h5">
                    Original item
                  </Typography>
                </Box>
                <PdfPreview url={props.item.isolatedImage} />
              </>
            )}
            {props.item.status === "contentChecked" && (
              <ItemInfo
                isEditing={true}
                item={props.item}
                onChangeMandatoryMetadata={handleChangeMandatoryMetadata}
                onSaveMetadata={props.onSaveMetadata}
                projectKey={props.projectKey}
                shouldSaveMetadata={props.shouldSaveMetadata}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ChoiceInteractionTask;
