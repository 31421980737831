// Core
import React, { FC, ReactElement, useState } from "react";

// Components
import LinearProgress from "components/linearProgress/LinearProgress";

// Interfaces
import { IProject, ITask } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Typography
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface Props {
  project: IProject;
  tasks: ITask[];
}

const SubjectExpertsCard: FC<Props> = (props): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sharedClasses = sharedStyles();

  const handleClickToggleDetail = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      data-testid="subject-experts-card"
      onClick={handleClickToggleDetail}
      style={{ cursor: "pointer" }}
    >
      <CardContent>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={6} md={3}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Subject experts
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="subject-experts"
            >
              {props.project.subjectExperts.length}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Submitted items
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="submitted-items"
            >
              {props.project.approversItems} / {props.project.items}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box alignItems="center" display="flex">
              <Box marginRight={4} style={{ width: "calc(100% - 60px)" }}>
                <LinearProgress
                  value={Math.round(
                    (props.project.approversItems / props.project.items) * 100
                  )}
                  variant="determinate"
                />
              </Box>
              <Box minWidth={60}>
                {!isExpanded && <KeyboardArrowDown />}
                {isExpanded && <KeyboardArrowUp />}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box marginTop={4} data-testid="detail">
            {props.tasks.map((task, index) => {
              let completedPercentage = Math.round(
                (task.completedItems.length /
                  (task.completedItems.length + task.pendingItems.length)) *
                  100
              );

              if (isNaN(completedPercentage)) {
                completedPercentage = 0;
              }

              return (
                <React.Fragment key={task.key}>
                  <Divider />
                  <Box
                    data-testid="subject-expert"
                    marginTop={3}
                    marginBottom={index === props.tasks.length - 1 ? 0 : 3}
                  >
                    <Grid container alignItems="center" spacing={4}>
                      <Grid item xs={6} md={3}>
                        <Typography
                          component="h4"
                          style={{ wordBreak: "break-word" }}
                          variant="subtitle1"
                          data-testid="email"
                        >
                          {task.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography
                          component="p"
                          variant="h5"
                          data-testid="individual-completed-items"
                        >
                          {task.completedItems.length} /{" "}
                          {task.completedItems.length +
                            task.pendingItems.length}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Box alignItems="center" display="flex">
                          <Box
                            marginRight={4}
                            style={{ width: "calc(100% - 60px)" }}
                          >
                            <LinearProgress
                              value={completedPercentage}
                              variant="determinate"
                            />
                          </Box>
                          <Box display="flex" minWidth={60}>
                            <Box marginRight={1}>
                              <Typography component="p" variant="h5">
                                {completedPercentage}
                              </Typography>
                            </Box>
                            <Typography display="inline">%</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default SubjectExpertsCard;
