// Core
import React, { FC, ReactElement, useState } from "react";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import {
  Box,
  Card,
  CardContent,
  Chip,
  Dialog,
  IconButton,
  Typography
} from "@material-ui/core";
import { Clear, Close, PictureAsPdf, Visibility } from "@material-ui/icons";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

interface Props {
  chipLabel?: string;
  displayName?: string;
  file: File;
  onClickDelete: () => void;
}

const LocalFile: FC<Props> = (props): ReactElement => {
  const [isFileDialogueOpen, setIsFileDialogueOpen] = useState(false);
  const [pageCount, setPageCount] = useState(null as number | null);
  const sharedClasses = sharedStyles();

  const handleClickViewFile = async (): Promise<void> => {
    setIsFileDialogueOpen(true);
  };

  const handleDocumentLoadSuccess = ({
    numPages
  }: {
    numPages: number;
  }): void => {
    setPageCount(numPages);
  };

  return (
    <Box position="relative" height={1}>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setIsFileDialogueOpen(false)}
        open={isFileDialogueOpen}
        data-testid="file-dialogue"
      >
        <Box padding={4}>
          <Box alignItems="center" display="flex" marginBottom={3}>
            <Typography variant="h5">File preview</Typography>
            <Box clone marginLeft="auto">
              <IconButton
                aria-label="close"
                onClick={() => setIsFileDialogueOpen(false)}
                data-testid="close-button"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Document file={props.file} onLoadSuccess={handleDocumentLoadSuccess}>
            {Array.from(new Array(pageCount), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={900}
              />
            ))}
          </Document>
        </Box>
      </Dialog>
      <Card elevation={0} data-testid="local-file">
        {props.chipLabel && (
          <Box position="absolute" top={10} right={10}>
            <Chip
              color="primary"
              label={props.chipLabel}
              data-testid="local-file-chip"
            />
          </Box>
        )}
        <CardContent>
          <Box alignItems="center" display="flex">
            <Box clone marginRight={1}>
              <PictureAsPdf color="secondary" fontSize="large" />
            </Box>
            <Typography
              component="p"
              display="inline"
              variant="subtitle1"
              data-testid="local-file-name"
            >
              {props.displayName ? props.displayName : props.file.name}
            </Typography>
            <Box display="flex" marginLeft="auto">
              <IconButton
                onClick={handleClickViewFile}
                data-testid="view-button"
              >
                <Visibility className={sharedClasses.fadedText} />
              </IconButton>
              <IconButton
                onClick={props.onClickDelete}
                data-testid="delete-button"
              >
                <Clear className={sharedClasses.fadedText} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocalFile;
