// Core
import React, { FC, ReactElement, useEffect } from "react";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";

interface Props {
  children: ReactElement | ReactElement[];
  projectKey: string;
}

const ProjectLayout: FC<Props> = (props: Props): ReactElement => {
  const { getProject, token } = useApi();
  const { fetchProjectSuccess } = useProject();

  useEffect(() => {
    if (!token) {
      return;
    }

    getProject<IProject>(props.projectKey).then((response) => {
      fetchProjectSuccess(response.data || ({} as IProject));
    });
  }, [fetchProjectSuccess, getProject, props.projectKey, token]);

  return <div data-testid="project-layout">{props.children}</div>;
};

export default ProjectLayout;
