// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { IJob } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, makeStyles, Typography } from "@material-ui/core";

interface Props {
  items: number;
  jobs: IJob[];
}

const Summary: FC<Props> = (props): ReactElement => {
  const harvestedItems = props.jobs.reduce(
    (previousValue, currentValue) =>
      previousValue + (currentValue.acceptedItems + currentValue.rejectedItems),
    0
  );
  const acceptedItems = props.jobs.reduce(
    (previousValue, currentValue) => previousValue + currentValue.acceptedItems,
    0
  );
  const duplicateItems = acceptedItems - props.items;
  const duplicateItemsPercentage = (duplicateItems / harvestedItems) * 100;
  const rejectedItems = props.jobs.reduce(
    (previousValue, currentValue) => previousValue + currentValue.rejectedItems,
    0
  );
  const rejectedItemsPercentage = (rejectedItems / harvestedItems) * 100;
  const uniqueItemsPercentage = (props.items / harvestedItems) * 100;
  const useStyles = makeStyles({
    duplicateDot: {
      backgroundColor: colours.corporateBlue,
      opacity: 0.59
    },
    legendDot: {
      borderRadius: "50%",
      display: "inline-block",
      height: "12px",
      width: "12px"
    },
    rejectedDot: {
      backgroundColor: colours.pdfRed
    }
  });
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  return (
    <Box marginY={5} data-testid="summary">
      <Box
        marginBottom={2}
        textAlign="center"
        width={`${uniqueItemsPercentage}%`}
      >
        <Box marginBottom={1}>
          <Typography className={sharedClasses.fadedText} variant="subtitle1">
            Unique items
          </Typography>
        </Box>
        <Typography variant="h5" data-testid="unique-items">
          {props.items}
        </Typography>
      </Box>
      <Box
        borderRadius="4px"
        height="16px"
        marginBottom={3}
        overflow="hidden"
        position="relative"
      >
        <Box
          bgcolor={colours.corporateBlue}
          height={1}
          position="absolute"
          left={0}
          width={`${uniqueItemsPercentage}%`}
        ></Box>
        <Box
          bgcolor={colours.corporateBlue}
          height={1}
          position="absolute"
          left={`${uniqueItemsPercentage}%`}
          style={{ opacity: 0.59 }}
          width={`${duplicateItemsPercentage}%`}
        ></Box>
        <Box
          bgcolor={colours.pdfRed}
          height={1}
          position="absolute"
          left={`${uniqueItemsPercentage + duplicateItemsPercentage}%`}
          width={`${rejectedItemsPercentage}%`}
        ></Box>
      </Box>
      <Box display="flex">
        <Box marginRight={4}>
          <Box
            className={`${classes.legendDot} ${classes.duplicateDot}`}
            marginRight={1}
          ></Box>
          <Typography
            className={sharedClasses.fadedText}
            display="inline"
            variant="subtitle1"
            data-testid="duplicate-items"
          >
            Duplicate items: {duplicateItems}
          </Typography>
        </Box>
        <Box>
          <Box
            className={`${classes.legendDot} ${classes.rejectedDot}`}
            marginRight={1}
          ></Box>
          <Typography
            className={sharedClasses.fadedText}
            display="inline"
            variant="subtitle1"
            data-testid="rejected-items"
          >
            Rejected items: {rejectedItems}
          </Typography>
        </Box>
        <Box marginLeft="auto">
          <Typography
            className={sharedClasses.fadedText}
            display="inline"
            variant="subtitle1"
            data-testid="items"
          >
            Total harvested items: {harvestedItems}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Summary;
