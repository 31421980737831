// Core
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";

// Interfaces
import { ProjectStepperStep } from "interfaces";

// Utils
import { useProject } from "utils/context";
import { getAssignedItemsCount } from "utils/project";

// Vendor
import {
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper
} from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ProjectStepper: FC = (): ReactElement => {
  const { project } = useProject();
  const [isSyllabusCodeDialogueOpen, setIsSyllabusCodeDialogueOpen] =
    useState(false);
  const assignedItemsCount = getAssignedItemsCount(project);
  const history = useHistory();
  const steps = useMemo(
    () =>
      [
        {
          completed: !!project.syllabusCode,
          detail: project.syllabusCode,
          disabled: project.uploadedJobs > 0,
          name: "Syllabus code",
          slug: "syllabus-code"
        },
        {
          completed: project.uploadedJobs > 0,
          detail: `${
            project.uploadedJobs > 0 ? `${project.uploadedJobs} uploaded` : ""
          }`,
          disabled:
            (!project.syllabusCode && project.uploadedJobs === 0) ||
            project.uploadedJobs > 0,
          name: "Upload papers",
          slug: "upload"
        },
        {
          completed: project.items > 0,
          detail: `${project.items > 0 ? `${project.items} items` : ""}`,
          disabled: project.uploadedJobs === 0,
          name: "Item harvesting",
          slug: "uploads"
        },
        {
          completed:
            project.subjectExperts && project.subjectExperts.length > 0,
          detail: `${
            project.subjectExperts && project.subjectExperts.length > 0
              ? `${project.subjectExperts.length} added`
              : ""
          }`,
          disabled: project.items === 0,
          name: "Subject experts",
          slug: "subject-experts"
        },
        {
          completed: project.approvers && project.approvers.length > 0,
          detail: `${
            project.approvers && project.approvers.length
              ? `${project.approvers.length} added`
              : ""
          }`,
          disabled: project.items === 0 || assignedItemsCount !== project.items,
          name: "Approver",
          slug: "approvers"
        },
        {
          completed:
            !!project.approverInstructions &&
            !!project.subjectExpertInstructions,
          disabled:
            project.approvers &&
            project.approvers.length > 0 &&
            project.subjectExperts &&
            project.subjectExperts.length > 0
              ? false
              : true,
          name: "Project details",
          slug: "details"
        },
        {
          completed:
            project.status !== "harvestingFailed" &&
            project.status !== "notStarted",
          disabled:
            project.approvers &&
            project.approvers.length > 0 &&
            project.subjectExperts &&
            project.subjectExperts.length > 0 &&
            !!project.approverInstructions &&
            !!project.subjectExpertInstructions
              ? false
              : true,
          name: "Confirmation",
          slug: "confirm"
        }
      ] as ProjectStepperStep[],
    [assignedItemsCount, project]
  );

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    if (
      history.location.pathname.substr(
        -(project.key.length + "/edit".length)
      ) !== `${project.key}/edit`
    ) {
      return;
    }

    const firstIncompleteStep = steps.find((step) => !step.completed);

    if (firstIncompleteStep) {
      history.push(`/projects/${project.key}/edit/${firstIncompleteStep.slug}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, project, steps]);

  const handleClickChangeSyllabusCode = (): void => {
    setIsSyllabusCodeDialogueOpen(false);

    history.push(`/projects/${project.key}/edit/syllabus-code`);
  };

  const handleClickCloseSyllabusCodeDialogue = (): void => {
    setIsSyllabusCodeDialogueOpen(false);
  };

  const handleClickStep = (step: ProjectStepperStep) => () => {
    if (step.disabled || history.location.pathname.includes(step.slug)) {
      return;
    }

    if (step.slug === "syllabus-code") {
      setIsSyllabusCodeDialogueOpen(true);

      return;
    }

    history.push(`/projects/${project.key}/edit/${step.slug}`);
  };

  return (
    <div data-testid="project-stepper">
      <Dialog
        onClose={handleClickCloseSyllabusCodeDialogue}
        aria-labelledby="syllabus-code-dialogue-heading"
        open={isSyllabusCodeDialogueOpen}
        data-testid="syllabus-code-dialogue"
      >
        <Box padding={4}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              data-testid="syllabus-code-dialogue-heading"
            >
              Do you want to change the syllabus code?
            </Typography>
          </Box>
          <Grid container>
            <Grid item md={10}>
              <Typography>
                You can go back to the previous step and change the syllabus
                code but you will lose the files that you selected in this step.
                <br />
                <br />
                Please confirm this action.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box display="flex" padding={3}>
          <Box marginLeft="auto">
            <Box clone marginRight={2}>
              <Button
                color="primary"
                onClick={handleClickCloseSyllabusCodeDialogue}
                variant="text"
                data-testid="syllabus-code-cancel-button"
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={handleClickChangeSyllabusCode}
              variant="contained"
              data-testid="change-syllabus-code-button"
            >
              Change syllabus code
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Stepper alternativeLabel nonLinear>
        {steps.map((step) => (
          <Step
            active={
              history.location.pathname ===
              `/projects/${project.key}/edit/${step.slug}`
            }
            completed={step.completed}
            data-testid={`${step.name}-step`}
            disabled={step.disabled}
            key={step.name}
            onClick={handleClickStep(step)}
          >
            <StepLabel
              optional={
                project && step.detail ? (
                  <Box fontWeight={700} textAlign="center">
                    {step.detail}
                  </Box>
                ) : undefined
              }
            >
              {step.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default ProjectStepper;
