// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { makeStyles } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles";

export const createThemeOptions = (
  baseThemeOptions: ThemeOptions
): ThemeOptions => {
  return {
    ...baseThemeOptions,
    palette: {
      ...baseThemeOptions.palette
    },
    overrides: {
      ...baseThemeOptions.overrides,
      MuiButton: {
        root: {
          textTransform: "none"
        }
      },
      MuiContainer: {
        root: {
          maxWidth: "1200px"
        }
      },
      MuiGrid: {
        root: {
          padding: 0
        }
      },
      MuiInputAdornment: {
        positionStart: {
          borderLeft: "0 none",
          marginLeft: "8px"
        }
      },
      MuiTableCell: {
        body: {
          root: {
            "&:nth-child(n+2)": {
              textAlign: "center"
            }
          }
        },
        head: {
          fontWeight: 700
        },
        root: {
          "&:nth-child(n+2)": {
            textAlign: "center"
          }
        }
      },
      MuiTooltip: {
        arrow: {
          color: colours.monochromeDarkest
        },
        tooltip: {
          backgroundColor: colours.monochromeDarkest,
          fontSize: "13px",
          fontWeight: 600,
          padding: "10px 16px",
          textAlign: "center"
        }
      }
    }
  };
};

export const sharedStyles = makeStyles({
  bleed: {
    marginLeft: "-1000px",
    marginRight: "-1000px",
    paddingLeft: "1000px",
    paddingRight: "1000px"
  },
  fadedText: {
    opacity: 0.59
  },
  heroHeaderCard: {
    borderTop: "0 none"
  },
  heroHeaderCardContent: {
    paddingLeft: 0,
    paddingRight: 0
  }
});
