// Interfaces
import { ISubjectInfo } from "@qti-scraper/interfaces";

export const getSyllabusAndVariation = (
  filename: string | undefined,
  businessUnit: ISubjectInfo["businessUnit"]
): string => {
  if (!filename) {
    return "";
  }

  const split = filename.split("_");
  const syllabusCode = split[0];
  const lastItemWithoutExtension = split
    .slice(-1)[0]
    .slice(0, split.slice(-1)[0].indexOf("."));

  const component =
    businessUnit === "CI"
      ? `${lastItemWithoutExtension} - ${split[1]}`
      : `${split[1]} - ${lastItemWithoutExtension.substr(
          0,
          lastItemWithoutExtension.search("\\d")
        )} ${lastItemWithoutExtension.substr(
          lastItemWithoutExtension.search("\\d")
        )}`;

  return `${syllabusCode} / ${component}`;
};

export const spaceCamelCaseString = (
  string: string,
  titleise?: boolean
): string => {
  if (!string) {
    return "";
  }

  const match = string.match(/([A-Z]?[^A-Z]*)/g);

  if (!match) {
    return string;
  }

  let stringArray = match.slice(0, -1);

  if (!titleise) {
    stringArray = stringArray.map(
      (item) => `${item.charAt(0).toLowerCase()}${item.slice(1)}`
    );
  }

  const stringWithSpaces = stringArray.join(" ");

  return `${stringWithSpaces.charAt(0).toUpperCase()}${stringWithSpaces.slice(
    1
  )}`;
};
