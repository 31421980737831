// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Box, Typography } from "@material-ui/core";

const Unauthorised: FC = (): ReactElement => {
  return (
    <div data-testid="unauthorised-page">
      <Box>
        <Box marginBottom={1}>
          <Typography component="h2" variant="h4" data-testid="page-heading">
            Unauthorised
          </Typography>
        </Box>
        <Typography data-testid="page-introduction">
          You are not authorised to use this application. Please speak to your
          administrator in order to have your account upgraded and to be given
          correct permissions
        </Typography>
      </Box>
    </div>
  );
};

export default Unauthorised;
