// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ProjectTypeComponent from "./components/projectType/ProjectType";

// Interfaces
import { IProject } from "@qti-scraper/interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

interface Props {
  isOpen: boolean;
  onClickClose: () => void;
  project?: IProject;
}

type ProjectType =
  | "authoring"
  | "harvesting-on-screen"
  | "harvesting-past-papers";

const ProjectSetupDialogue: FC<Props> = (props): ReactElement => {
  const { createProject, getProject, getProjects, updateProject } = useApi();
  const { createProjectSuccess, updateProjectSuccess } = useProject();
  const [activeApiCalls, setActiveApiCalls] = useState([] as string[]);
  const [formFields, setFormFields] = useState({
    projectName: "",
    projectType: "" as ProjectType
  });
  const [isProjectNameDuplicated, setIsProjectNameDuplicated] = useState(false);
  const [isProjectNameMinLength, setIsProjectNameMinLength] = useState(true);
  const [isProjectNameSimilar, setIsProjectNameSimilar] = useState(false);
  const [projects, setProjects] = useState([] as { name: string }[]);
  const history = useHistory();
  const projectTypes = [
    "harvesting-past-papers",
    "harvesting-on-screen",
    "authoring"
  ];

  useEffect(() => {
    if (!props.project || !Object.keys(props.project)) {
      return;
    }

    setFormFields({
      ...formFields,
      projectName: props.project.name
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project]);

  useEffect(() => {
    getProjects<IProject[]>({ select: "name" }).then((response) => {
      setProjects(response.data || []);
    });
  }, [getProjects]);

  useEffect(() => {
    if (!formFields.projectName) {
      return;
    }

    if (props.project) {
      setIsProjectNameDuplicated(
        projects
          .filter((item) => item.name !== (props.project as IProject).name)
          .map((item) => item.name)
          .includes(formFields.projectName)
      );
      setIsProjectNameSimilar(
        projects
          .filter(
            (item) =>
              item.name.toLowerCase() !==
              (props.project as IProject).name.toLowerCase()
          )
          .map((item) => item.name.toLowerCase())
          .includes(formFields.projectName.toLowerCase())
      );
    } else {
      setIsProjectNameDuplicated(
        projects.map((item) => item.name).includes(formFields.projectName)
      );
      setIsProjectNameSimilar(
        projects
          .map((item) => item.name.toLowerCase())
          .includes(formFields.projectName.toLowerCase())
      );
    }

    setIsProjectNameMinLength(formFields.projectName.length > 2);
  }, [formFields.projectName, projects, props.project]);

  const handleChangeProjectName = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      projectName: event.target.value
    });
  };

  const handleClickCancel = (): void => {
    props.onClickClose();
  };

  const handleClickClose = (): void => {
    setFormFields({
      ...formFields,
      projectName: props.project ? props.project.name : "",
      projectType: "" as ProjectType
    });

    props.onClickClose();
  };

  const handleClickCreateProject = (): void => {
    const body = {
      name: formFields.projectName,
      type: formFields.projectType
    };

    setActiveApiCalls([...activeApiCalls, "createProject"]);

    createProject(body)
      .then((createProjectResponse) => {
        const url = createProjectResponse.headers.get("location") as string;
        const projectKey = url.slice(
          url.indexOf("/projects/") + "/projects/".length
        );

        getProject<IProject>(projectKey).then((response) => {
          createProjectSuccess(response.data || ({} as IProject));

          history.push(`/projects/${projectKey}/edit`);
        });
      })
      .catch(() => {
        setActiveApiCalls(
          activeApiCalls.filter((apiCall) => apiCall !== "createProject")
        );

        console.error();
      });
  };

  const handleClickProjectType = (type: ProjectType): void => {
    if (type === formFields.projectType) {
      return;
    }

    setFormFields({
      ...formFields,
      projectType: type
    });
  };

  const handleClickSave = (): void => {
    const body = {
      approverInstructions: (props.project as IProject).approverInstructions,
      name: formFields.projectName,
      subjectExpertInstructions: (props.project as IProject)
        .subjectExpertInstructions,
      targetEndDate: (props.project as IProject).targetEndDate,
      type: (props.project as IProject).type
    };

    setProjects(
      projects.filter((item) => item.name !== (props.project as IProject).name)
    );

    updateProject((props.project as IProject).key, {
      ...props.project,
      ...body
    })
      .then(() => {
        updateProjectSuccess({
          ...(props.project as IProject),
          ...body
        });

        props.onClickClose();
      })
      .catch(console.error);
  };

  return (
    <Dialog
      onClose={handleClickClose}
      aria-labelledby="project-setup-dialogue-heading"
      open={props.isOpen}
      data-testid="project-setup-dialogue"
    >
      <Box padding={4}>
        {!props.project && (
          <Box marginBottom={4}>
            <Box alignItems="center" display="flex" marginBottom={3}>
              <Typography variant="h5">Select the project type</Typography>
              <Box clone marginLeft="auto">
                <IconButton
                  aria-label="close"
                  onClick={handleClickClose}
                  data-testid="close-button"
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            {projectTypes.map((projectType) => (
              <ProjectTypeComponent
                isActive={formFields.projectType === projectType}
                onClickProjectType={handleClickProjectType}
                projectType={projectType as ProjectType}
                key={projectType}
              />
            ))}
            <Divider />
          </Box>
        )}
        <Box marginBottom={props.project ? 2 : 1}>
          <Typography variant="h5">
            {props.project ? "Edit project" : "Project"} name
          </Typography>
        </Box>
        {props.project && (
          <Box marginBottom={2}>
            <Typography>
              Project name should be unique and clear to the Subject Experts and
              the Approver on the project.
            </Typography>
          </Box>
        )}
        <TextField
          helperText={
            !isProjectNameMinLength
              ? "Must be at least three characters long"
              : isProjectNameDuplicated
              ? "This project name already exists"
              : isProjectNameSimilar
              ? "This project name is very similar to an existing one"
              : undefined
          }
          inputProps={{
            "data-testid": "project-name-input"
          }}
          onChange={handleChangeProjectName}
          validationType={
            !isProjectNameMinLength
              ? "error"
              : isProjectNameDuplicated || isProjectNameSimilar
              ? "warning"
              : undefined
          }
          value={formFields.projectName}
        />
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          {props.project && (
            <>
              <Box marginRight={2}>
                <Button
                  color="primary"
                  disableElevation
                  onClick={handleClickCancel}
                  variant="text"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
              </Box>
              <Button
                color="primary"
                disableElevation
                disabled={!formFields.projectName || !isProjectNameMinLength}
                onClick={handleClickSave}
                data-testid="save-button"
              >
                Save changes
              </Button>
            </>
          )}
          {!props.project && (
            <Button
              color="primary"
              disableElevation
              disabled={
                activeApiCalls.includes("createProject") ||
                !formFields.projectName ||
                !isProjectNameMinLength ||
                !formFields.projectType
              }
              onClick={handleClickCreateProject}
              data-testid="create-project-button"
            >
              Create project
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProjectSetupDialogue;
