// Core
import React, { FC, ReactElement } from "react";

interface Props {
  children: ReactElement;
  shouldWrap: boolean;
  wrapper: (children: ReactElement) => ReactElement;
}

const ConditionalWrapper: FC<Props> = (props): ReactElement => {
  return (
    <>{props.shouldWrap ? props.wrapper(props.children) : props.children}</>
  );
};

export default ConditionalWrapper;
