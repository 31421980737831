// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { ITask } from "@qti-scraper/interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

interface Props {
  activeItemIndex: number;
  onClickBack: () => void;
  task: ITask;
}

const TaskHeader: FC<Props> = (props): ReactElement => {
  const isXsScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const sharedClasses = sharedStyles();

  return (
    <div data-testid="task-header">
      <Box clone className={sharedClasses.bleed} marginTop={0} paddingY={3}>
        <Paper elevation={0}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <Button
                color="primary"
                onClick={props.onClickBack}
                data-testid="back-button"
                startIcon={<ChevronLeft />}
                variant="text"
              >
                Save and continue later
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box marginTop={isXsScreen ? 4 : 0} textAlign="center">
                <Typography component="h3" variant="h4" data-testid="progress">
                  {props.activeItemIndex + 1 + props.task.completedItems.length}{" "}
                  /{" "}
                  {props.task.completedItems.length +
                    props.task.pendingItems.length}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box clone className={sharedClasses.bleed} marginTop={0} marginBottom={4}>
        <Divider />
      </Box>
    </div>
  );
};

export default TaskHeader;
