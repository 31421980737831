// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { ISapCdcUser } from "@qti-scraper/interfaces";

// Vendor
import { Avatar, Box, Typography } from "@material-ui/core";

interface Props {
  option: ISapCdcUser;
}

const UserOption: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      padding={2}
      width={1}
      data-testid="user-option"
    >
      <Box marginRight={1}>
        <Avatar>
          <span data-testid="first-initial">
            {props.option.name
              ? props.option.name.slice(0, 1).toUpperCase()
              : ""}
          </span>
          <span data-testid="last-initial">
            {props.option.family_name
              ? props.option.family_name.slice(0, 1).toUpperCase()
              : ""}
          </span>
        </Avatar>
      </Box>
      <Box>
        <Typography variant="subtitle1">
          {props.option.name} {props.option.family_name}
        </Typography>
        <Typography>{props.option.email}</Typography>
      </Box>
    </Box>
  );
};

export default UserOption;
