// Core
import React, { FC, ReactElement, useMemo } from "react";

// Interfaces
import { ChoiceInteractionTaskStepperStep, Item } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Step, StepButton, Stepper } from "@material-ui/core";

interface Props {
  isAddTopicsAndSkillsDisabled: boolean;
  item: Item;
  onClickTaskStep: (status: Item["status"]) => void;
}

const ChoiceInteractionTaskStepper: FC<Props> = (props): ReactElement => {
  const steps = useMemo(
    () =>
      [
        {
          disabled: false,
          name: "Content check",
          status: "initial"
        },
        {
          disabled: props.isAddTopicsAndSkillsDisabled,
          name: "Add topics and skills",
          status: "contentChecked"
        }
      ] as ChoiceInteractionTaskStepperStep[],
    [props.isAddTopicsAndSkillsDisabled]
  );
  const sharedClasses = sharedStyles();

  const handleClickStep = (step: ChoiceInteractionTaskStepperStep) => () => {
    if (step.disabled || props.item.status === step.status) {
      return;
    }

    props.onClickTaskStep(step.status);
  };

  return (
    <Box
      className={sharedClasses.bleed}
      marginBottom={4}
      marginTop={-4}
      style={{ backgroundColor: colours.white }}
      data-testid="choice-interaction-task-stepper"
    >
      <Stepper nonLinear>
        {steps.map((step) => (
          <Step
            active={props.item.status === step.status}
            data-testid={`${step.name}-step`}
            disabled={step.disabled}
            key={step.name}
            onClick={handleClickStep(step)}
          >
            <StepButton>{step.name}</StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ChoiceInteractionTaskStepper;
